"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultThresholdColors = exports.DashboardWidgetType = void 0;
var DashboardWidgetType;
(function (DashboardWidgetType) {
    DashboardWidgetType["Demo"] = "demo";
    DashboardWidgetType["Test"] = "test";
    DashboardWidgetType["Kpi"] = "kpi";
    DashboardWidgetType["Header"] = "header";
})(DashboardWidgetType || (exports.DashboardWidgetType = DashboardWidgetType = {}));
exports.defaultThresholdColors = {
    thresholdColor1: '#ffbaba',
    thresholdColor2: '#4c42b1',
    thresholdColor3: '#c5ffbe',
};
