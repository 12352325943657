"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Country = void 0;
var Country;
(function (Country) {
    Country["Afghanistan"] = "AF";
    Country["AlandIslands"] = "AX";
    Country["Albania"] = "AL";
    Country["Algeria"] = "DZ";
    Country["AmericanSamoa"] = "AS";
    Country["Andorra"] = "AD";
    Country["Angola"] = "AO";
    Country["Anguilla"] = "AI";
    Country["Antarctica"] = "AQ";
    Country["AntiguaAndBarbuda"] = "AG";
    Country["Argentina"] = "AR";
    Country["Armenia"] = "AM";
    Country["Aruba"] = "AW";
    Country["Australia"] = "AU";
    Country["Austria"] = "AT";
    Country["Azerbaijan"] = "AZ";
    Country["Bahamas"] = "BS";
    Country["Bahrain"] = "BH";
    Country["Bangladesh"] = "BD";
    Country["Barbados"] = "BB";
    Country["Belarus"] = "BY";
    Country["Belgium"] = "BE";
    Country["Belize"] = "BZ";
    Country["Benin"] = "BJ";
    Country["Bermuda"] = "BM";
    Country["Bhutan"] = "BT";
    Country["Bolivia"] = "BO";
    Country["BonaireSintEustatiusSaba"] = "BQ";
    Country["BosniaAndHerzegovina"] = "BA";
    Country["Botswana"] = "BW";
    Country["BouvetIsland"] = "BV";
    Country["Brazil"] = "BR";
    Country["BritishIndianOceanTerritory"] = "IO";
    Country["BruneiDarussalam"] = "BN";
    Country["Bulgaria"] = "BG";
    Country["BurkinaFaso"] = "BF";
    Country["Burundi"] = "BI";
    Country["Cambodia"] = "KH";
    Country["Cameroon"] = "CM";
    Country["Canada"] = "CA";
    Country["CapeVerde"] = "CV";
    Country["CaymanIslands"] = "KY";
    Country["CentralAfricanRepublic"] = "CF";
    Country["Chad"] = "TD";
    Country["Chile"] = "CL";
    Country["China"] = "CN";
    Country["ChristmasIsland"] = "CX";
    Country["CocosKeelingIslands"] = "CC";
    Country["Colombia"] = "CO";
    Country["Comoros"] = "KM";
    Country["Congo"] = "CG";
    Country["CongoDemocraticRepublic"] = "CD";
    Country["CookIslands"] = "CK";
    Country["CostaRica"] = "CR";
    Country["CoteDIvoire"] = "CI";
    Country["Croatia"] = "HR";
    Country["Cuba"] = "CU";
    Country["Cura\u00E7ao"] = "CW";
    Country["Cyprus"] = "CY";
    Country["CzechRepublic"] = "CZ";
    Country["Denmark"] = "DK";
    Country["Djibouti"] = "DJ";
    Country["Dominica"] = "DM";
    Country["DominicanRepublic"] = "DO";
    Country["Ecuador"] = "EC";
    Country["Egypt"] = "EG";
    Country["ElSalvador"] = "SV";
    Country["EquatorialGuinea"] = "GQ";
    Country["Eritrea"] = "ER";
    Country["Estonia"] = "EE";
    Country["Ethiopia"] = "ET";
    Country["FalklandIslands"] = "FK";
    Country["FaroeIslands"] = "FO";
    Country["Fiji"] = "FJ";
    Country["Finland"] = "FI";
    Country["France"] = "FR";
    Country["FrenchGuiana"] = "GF";
    Country["FrenchPolynesia"] = "PF";
    Country["FrenchSouthernTerritories"] = "TF";
    Country["Gabon"] = "GA";
    Country["Gambia"] = "GM";
    Country["Georgia"] = "GE";
    Country["Germany"] = "DE";
    Country["Ghana"] = "GH";
    Country["Gibraltar"] = "GI";
    Country["Greece"] = "GR";
    Country["Greenland"] = "GL";
    Country["Grenada"] = "GD";
    Country["Guadeloupe"] = "GP";
    Country["Guam"] = "GU";
    Country["Guatemala"] = "GT";
    Country["Guernsey"] = "GG";
    Country["Guinea"] = "GN";
    Country["GuineaBissau"] = "GW";
    Country["Guyana"] = "GY";
    Country["Haiti"] = "HT";
    Country["HeardIslandMcdonaldIslands"] = "HM";
    Country["HolySeeVaticanCityState"] = "VA";
    Country["Honduras"] = "HN";
    Country["HongKong"] = "HK";
    Country["Hungary"] = "HU";
    Country["Iceland"] = "IS";
    Country["India"] = "IN";
    Country["Indonesia"] = "ID";
    Country["Iran"] = "IR";
    Country["Iraq"] = "IQ";
    Country["Ireland"] = "IE";
    Country["IsleOfMan"] = "IM";
    Country["Israel"] = "IL";
    Country["Italy"] = "IT";
    Country["Jamaica"] = "JM";
    Country["Japan"] = "JP";
    Country["Jersey"] = "JE";
    Country["Jordan"] = "JO";
    Country["Kazakhstan"] = "KZ";
    Country["Kenya"] = "KE";
    Country["Kiribati"] = "KI";
    Country["Korea"] = "KR";
    Country["KoreaDemocraticPeoplesRepublic"] = "KP";
    Country["Kuwait"] = "KW";
    Country["Kyrgyzstan"] = "KG";
    Country["LaoPeoplesDemocraticRepublic"] = "LA";
    Country["Latvia"] = "LV";
    Country["Lebanon"] = "LB";
    Country["Lesotho"] = "LS";
    Country["Liberia"] = "LR";
    Country["LibyanArabJamahiriya"] = "LY";
    Country["Liechtenstein"] = "LI";
    Country["Lithuania"] = "LT";
    Country["Luxembourg"] = "LU";
    Country["Macao"] = "MO";
    Country["Macedonia"] = "MK";
    Country["Madagascar"] = "MG";
    Country["Malawi"] = "MW";
    Country["Malaysia"] = "MY";
    Country["Maldives"] = "MV";
    Country["Mali"] = "ML";
    Country["Malta"] = "MT";
    Country["MarshallIslands"] = "MH";
    Country["Martinique"] = "MQ";
    Country["Mauritania"] = "MR";
    Country["Mauritius"] = "MU";
    Country["Mayotte"] = "YT";
    Country["Mexico"] = "MX";
    Country["Micronesia"] = "FM";
    Country["Moldova"] = "MD";
    Country["Monaco"] = "MC";
    Country["Mongolia"] = "MN";
    Country["Montenegro"] = "ME";
    Country["Montserrat"] = "MS";
    Country["Morocco"] = "MA";
    Country["Mozambique"] = "MZ";
    Country["Myanmar"] = "MM";
    Country["Namibia"] = "NA";
    Country["Nauru"] = "NR";
    Country["Nepal"] = "NP";
    Country["Netherlands"] = "NL";
    Country["NewCaledonia"] = "NC";
    Country["NewZealand"] = "NZ";
    Country["Nicaragua"] = "NI";
    Country["Niger"] = "NE";
    Country["Nigeria"] = "NG";
    Country["Niue"] = "NU";
    Country["NorfolkIsland"] = "NF";
    Country["NorthernMarianaIslands"] = "MP";
    Country["Norway"] = "NO";
    Country["Oman"] = "OM";
    Country["Pakistan"] = "PK";
    Country["Palau"] = "PW";
    Country["PalestinianTerritory"] = "PS";
    Country["Panama"] = "PA";
    Country["PapuaNewGuinea"] = "PG";
    Country["Paraguay"] = "PY";
    Country["Peru"] = "PE";
    Country["Philippines"] = "PH";
    Country["Pitcairn"] = "PN";
    Country["Poland"] = "PL";
    Country["Portugal"] = "PT";
    Country["PuertoRico"] = "PR";
    Country["Qatar"] = "QA";
    Country["Reunion"] = "RE";
    Country["Romania"] = "RO";
    Country["RussianFederation"] = "RU";
    Country["Rwanda"] = "RW";
    Country["SaintBarthelemy"] = "BL";
    Country["SaintHelena"] = "SH";
    Country["SaintKittsAndNevis"] = "KN";
    Country["SaintLucia"] = "LC";
    Country["SaintMartin"] = "MF";
    Country["SaintPierreAndMiquelon"] = "PM";
    Country["SaintVincentAndGrenadines"] = "VC";
    Country["Samoa"] = "WS";
    Country["SanMarino"] = "SM";
    Country["SaoTomeAndPrincipe"] = "ST";
    Country["SaudiArabia"] = "SA";
    Country["Senegal"] = "SN";
    Country["Serbia"] = "RS";
    Country["Seychelles"] = "SC";
    Country["SierraLeone"] = "SL";
    Country["Singapore"] = "SG";
    Country["SintMaarten"] = "SX";
    Country["Slovakia"] = "SK";
    Country["Slovenia"] = "SI";
    Country["SolomonIslands"] = "SB";
    Country["Somalia"] = "SO";
    Country["SouthAfrica"] = "ZA";
    Country["SouthGeorgiaAndSandwichIsl"] = "GS";
    Country["SouthSudan"] = "SS";
    Country["Spain"] = "ES";
    Country["SriLanka"] = "LK";
    Country["Sudan"] = "SD";
    Country["Suriname"] = "SR";
    Country["SvalbardAndJanMayen"] = "SJ";
    Country["Swaziland"] = "SZ";
    Country["Sweden"] = "SE";
    Country["Switzerland"] = "CH";
    Country["SyrianArabRepublic"] = "SY";
    Country["Taiwan"] = "TW";
    Country["Tajikistan"] = "TJ";
    Country["Tanzania"] = "TZ";
    Country["Thailand"] = "TH";
    Country["TimorLeste"] = "TL";
    Country["Togo"] = "TG";
    Country["Tokelau"] = "TK";
    Country["Tonga"] = "TO";
    Country["TrinidadAndTobago"] = "TT";
    Country["Tunisia"] = "TN";
    Country["Turkey"] = "TR";
    Country["Turkmenistan"] = "TM";
    Country["TurksAndCaicosIslands"] = "TC";
    Country["Tuvalu"] = "TV";
    Country["Uganda"] = "UG";
    Country["Ukraine"] = "UA";
    Country["UnitedArabEmirates"] = "AE";
    Country["UnitedKingdom"] = "GB";
    Country["UnitedStates"] = "US";
    Country["UnitedStatesOutlyingIslands"] = "UM";
    Country["Uruguay"] = "UY";
    Country["Uzbekistan"] = "UZ";
    Country["Vanuatu"] = "VU";
    Country["Venezuela"] = "VE";
    Country["Vietnam"] = "VN";
    Country["VirginIslandsBritish"] = "VG";
    Country["VirginIslandsUS"] = "VI";
    Country["WallisAndFutuna"] = "WF";
    Country["WesternSahara"] = "EH";
    Country["Yemen"] = "YE";
    Country["Zambia"] = "ZM";
    Country["Zimbabwe"] = "ZW";
})(Country || (exports.Country = Country = {}));
