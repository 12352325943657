"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.anamneseLicenceProblemChecks = exports.stableHashQuestionnaire = exports.getAnamneseCapabilityStatement = exports.AnamneseAdvancedQuestionTypes = exports.AnamneseCapability = void 0;
const anamnese_1 = require("./anamnese");
const features_1 = require("./features");
var AnamneseCapability;
(function (AnamneseCapability) {
    AnamneseCapability["EDIT_DESIGN_COLOR"] = "EDIT_DESIGN_COLOR";
    AnamneseCapability["EDIT_DESIGN_LOGO"] = "EDIT_DESIGN_LOGO";
    AnamneseCapability["CREATE_CUSTOM_QUESTIONNAIRE"] = "CREATE_CUSTOM_QUESTIONNAIRE";
    AnamneseCapability["CUSTOM_TEXT_FIELDS"] = "CUSTOM_TEXT_FIELDS";
    AnamneseCapability["CUSTOM_QUESTIONS"] = "CUSTOM_QUESTIONS";
    AnamneseCapability["CUSTOM_QUESTIONS_ADVANCED_QUESITON_TYPES"] = "CUSTOM_QUESTIONS_ADVANCED_QUESITON_TYPES";
})(AnamneseCapability || (exports.AnamneseCapability = AnamneseCapability = {}));
exports.AnamneseAdvancedQuestionTypes = [
    anamnese_1.CustomAnamneseQuestionTypes.RATING,
    anamnese_1.CustomAnamneseQuestionTypes.RANGE,
    anamnese_1.CustomAnamneseQuestionTypes.INVOICE_LIST,
];
function getAnamneseCapabilityStatement(features) {
    const featureOrder = [
        features_1.FEATURES.ANAMNESE_WHITE,
        features_1.FEATURES.ANAMNESE_RED,
        features_1.FEATURES.ANAMNESE_BLACK,
        features_1.FEATURES.ANAMNESE_DIAMOND,
    ];
    let highestFeature;
    for (const feature of featureOrder) {
        if (features.includes(feature)) {
            highestFeature = feature;
        }
    }
    if (!highestFeature) {
        return {
            includedQuestionnaires: 0,
            includedTaskFlows: 0,
            capabilities: [],
        };
    }
    const capabilityMapping = {
        [features_1.FEATURES.ANAMNESE_WHITE]: [],
        [features_1.FEATURES.ANAMNESE_RED]: [
            AnamneseCapability.EDIT_DESIGN_COLOR,
            AnamneseCapability.EDIT_DESIGN_LOGO,
            AnamneseCapability.CREATE_CUSTOM_QUESTIONNAIRE,
            AnamneseCapability.CUSTOM_TEXT_FIELDS,
            AnamneseCapability.CUSTOM_QUESTIONS,
        ],
        [features_1.FEATURES.ANAMNESE_BLACK]: [AnamneseCapability.CUSTOM_QUESTIONS_ADVANCED_QUESITON_TYPES],
        [features_1.FEATURES.ANAMNESE_DIAMOND]: [],
    };
    const includedQuestionnairesMapping = {
        [features_1.FEATURES.ANAMNESE_WHITE]: 1,
        [features_1.FEATURES.ANAMNESE_RED]: 2,
        [features_1.FEATURES.ANAMNESE_BLACK]: Infinity,
        [features_1.FEATURES.ANAMNESE_DIAMOND]: Infinity,
    };
    const includedTaskFlowsMapping = {
        [features_1.FEATURES.ANAMNESE_WHITE]: 10,
        [features_1.FEATURES.ANAMNESE_RED]: 100,
        [features_1.FEATURES.ANAMNESE_BLACK]: Infinity,
        [features_1.FEATURES.ANAMNESE_DIAMOND]: Infinity,
    };
    // collect all capabilities upto to the highest feature
    const capabilities = [];
    for (const feature of featureOrder) {
        capabilities.push(...capabilityMapping[feature]);
        if (feature === highestFeature) {
            break;
        }
    }
    let statement = {
        includedQuestionnaires: includedQuestionnairesMapping[highestFeature],
        includedTaskFlows: includedTaskFlowsMapping[highestFeature],
        capabilities,
    };
    const extraQuestionnaires = features.filter(f => f === features_1.FEATURES.ANAMNESE_EXTRA_QUESTIONNAIRE).length;
    statement.includedQuestionnaires += extraQuestionnaires;
    return statement;
}
exports.getAnamneseCapabilityStatement = getAnamneseCapabilityStatement;
// duplicated from base
function simpleHashCode(inputString) {
    let hash = 0;
    if (inputString.length === 0) {
        return hash;
    }
    for (let i = 0; i < inputString.length; i++) {
        let char = inputString.charCodeAt(i);
        // tslint:disable-next-line
        hash = (hash << 5) - hash + char;
        // tslint:disable-next-line
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}
function stableHashQuestionnaire(questionnaire) {
    let hashableSubset = {
        // dont include, hash itself, id, name, title, since they are allowed to be changed
        recallMonths: questionnaire.recallMonths,
        questionnairePages: questionnaire.questionnairePages,
        signaturePage: questionnaire.signaturePage,
        deactivated: questionnaire.deactivated,
        prefillPatientDetails: questionnaire.prefillPatientDetails,
        prefillQuestions: questionnaire.prefillQuestions,
    };
    // deep sort hashableSubset
    let sortedHashableSubset = JSON.parse(JSON.stringify(hashableSubset), (outerKey, value) => {
        if (value && typeof value === 'object') {
            return Object.keys(value)
                .sort()
                .reduce((obj, key) => {
                obj[key] = value[key];
                return obj;
            }, {});
        }
        return value;
    });
    return 'v1' + simpleHashCode(JSON.stringify(sortedHashableSubset));
}
exports.stableHashQuestionnaire = stableHashQuestionnaire;
function featureCheck(cap, problemCheck, collectProblems, statement) {
    if (statement === null || statement === void 0 ? void 0 : statement.capabilities.includes(cap)) {
        return;
    }
    if (problemCheck()) {
        collectProblems.push(cap);
    }
}
// feature checks for questionnaires, required by server and client
exports.anamneseLicenceProblemChecks = {
    questionnaireInGroupProblems(questionnaires, questionnaireIndex, collectProblems, statement) {
        let questionnaire = questionnaires[questionnaireIndex];
        if (!questionnaire) {
            console.warn('questionnaire not found in anamneseSettings', questionnaireIndex, questionnaires);
            return;
        }
        let quesitonnaireMaxCount = (statement === null || statement === void 0 ? void 0 : statement.includedQuestionnaires) || 0;
        let precedingQuestionnaires = questionnaires.slice(0, questionnaireIndex).filter(q => !q.deactivated).length;
        if (precedingQuestionnaires + 1 > quesitonnaireMaxCount) {
            collectProblems.push(features_1.FEATURES.ANAMNESE_EXTRA_QUESTIONNAIRE);
        }
        this.questionnaireProblems(questionnaire, collectProblems, statement);
    },
    questionnaireProblems(questionnaire, collectProblems, statement) {
        featureCheck(AnamneseCapability.CREATE_CUSTOM_QUESTIONNAIRE, () => stableHashQuestionnaire(questionnaire) !== questionnaire.initialGeneratedHash, collectProblems, statement);
        let usedElms = questionnaire.questionnairePages.flatMap(p => p.pageElements);
        usedElms.forEach(elm => this.elementProblems(elm, collectProblems, statement));
    },
    elementProblems(elm, collectProblems, statement) {
        // initialGenerated elements are always valid, eg. the initial text field, and textfields on the signature page
        if (elm.initialGenerated) {
            return collectProblems;
        }
        this.customQuestion(elm, collectProblems, statement);
        this.customQuestionAdvancesType(elm, collectProblems, statement);
        this.customText(elm, collectProblems, statement);
    },
    customQuestion(elm, collectProblems, statement) {
        featureCheck(AnamneseCapability.CUSTOM_QUESTIONS, () => elm.type === anamnese_1.QuestionnaireElementType.QUESTION && !elm.question.isCharlyQuestion, collectProblems, statement);
    },
    customQuestionAdvancesType(elm, collectProblems, statement) {
        featureCheck(AnamneseCapability.CUSTOM_QUESTIONS_ADVANCED_QUESITON_TYPES, () => elm.type === anamnese_1.QuestionnaireElementType.QUESTION &&
            exports.AnamneseAdvancedQuestionTypes.includes(elm.question.valueType), collectProblems, statement);
    },
    customText(elm, collectProblems, statement) {
        featureCheck(AnamneseCapability.CUSTOM_TEXT_FIELDS, () => elm.type === anamnese_1.QuestionnaireElementType.RICH_TEXT, collectProblems, statement);
    },
};
