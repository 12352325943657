"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BewertungSammlungTypValues = exports.DatenschutzSammlungTypValues = exports.ExtraSeitenStichwortSammlungTypValues = exports.TeamTypes = exports.BehandlerType = void 0;
var BehandlerType;
(function (BehandlerType) {
    BehandlerType["ZA"] = "za";
    BehandlerType["PZR"] = "pzr";
    BehandlerType["TECHNIKER"] = "techniker";
})(BehandlerType || (exports.BehandlerType = BehandlerType = {}));
var TeamTypes;
(function (TeamTypes) {
    TeamTypes["ALLE"] = "alle";
    TeamTypes["PRAXIS"] = "praxis";
    TeamTypes["UNUSED"] = "unused";
    TeamTypes["SINGLE"] = "single";
    TeamTypes["MITARBEITER"] = "mitarbeiter";
    TeamTypes["TEAM"] = "team";
    TeamTypes["BUNDLE"] = "bundle";
    TeamTypes["AUTOTEAM"] = "autoteam";
    TeamTypes["INAKTIV"] = "inaktiv";
    TeamTypes["TERMINGRUPPE"] = "termingruppe";
})(TeamTypes || (exports.TeamTypes = TeamTypes = {}));
var ExtraSeitenStichwortSammlungTypValues;
(function (ExtraSeitenStichwortSammlungTypValues) {
    ExtraSeitenStichwortSammlungTypValues["Stichworte"] = "stichworte";
    ExtraSeitenStichwortSammlungTypValues["Extraseiten"] = "extraseiten";
})(ExtraSeitenStichwortSammlungTypValues || (exports.ExtraSeitenStichwortSammlungTypValues = ExtraSeitenStichwortSammlungTypValues = {}));
var DatenschutzSammlungTypValues;
(function (DatenschutzSammlungTypValues) {
    DatenschutzSammlungTypValues["None"] = "none";
    DatenschutzSammlungTypValues["Stichworte"] = "stichworte";
    DatenschutzSammlungTypValues["Extraseiten"] = "extraseiten";
})(DatenschutzSammlungTypValues || (exports.DatenschutzSammlungTypValues = DatenschutzSammlungTypValues = {}));
var BewertungSammlungTypValues;
(function (BewertungSammlungTypValues) {
    BewertungSammlungTypValues["None"] = "none";
    BewertungSammlungTypValues["Klassifizierung"] = "klassifizierung";
    BewertungSammlungTypValues["Stichworte"] = "stichworte";
    BewertungSammlungTypValues["Extraseiten"] = "extraseiten";
})(BewertungSammlungTypValues || (exports.BewertungSammlungTypValues = BewertungSammlungTypValues = {}));
