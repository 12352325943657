"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoomStatusType = void 0;
var RoomStatusType;
(function (RoomStatusType) {
    RoomStatusType["FREE"] = "FREE";
    RoomStatusType["OCCUPIED"] = "OCCUPIED";
    RoomStatusType["LATE"] = "LATE";
    RoomStatusType["INCONSISTENT"] = "INCONSISTENT";
})(RoomStatusType || (exports.RoomStatusType = RoomStatusType = {}));
