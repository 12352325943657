"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FallWertType = exports.FallgruppeGenType = void 0;
var FallgruppeGenType;
(function (FallgruppeGenType) {
    FallgruppeGenType["NORMAL"] = "NORMAL";
    FallgruppeGenType["GROUPBYKASSENKEY"] = "GROUPBYKASSENKEY";
})(FallgruppeGenType || (exports.FallgruppeGenType = FallgruppeGenType = {}));
var FallWertType;
(function (FallWertType) {
    FallWertType["PUNKTE"] = "PUNKTE";
    FallWertType["EURO"] = "EURO";
})(FallWertType || (exports.FallWertType = FallWertType = {}));
