"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uiServerEvents = exports.r4cServerEvents = void 0;
const tablets = {
    path: '/tablets',
    params: [],
    authType: 'apikey',
};
const anamneseResponses = {
    path: '/anamneseResponses',
    params: [],
    authType: 'apikey',
};
const r4cActivePatient = {
    path: '/r4cActivePatient',
    params: ['r4cUuid'],
    authType: 'apikey',
};
exports.r4cServerEvents = {
    tablets,
    r4cActivePatient,
    anamneseResponses,
};
const zimmerBelegung = {
    path: '/zimmerbelegung',
    params: [],
    authType: 'jwt',
};
exports.uiServerEvents = {
    zimmerBelegung,
};
