"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hkpFavFilterKeys = exports.hkpFilterKeys = exports.hkpGroupFilterKeys = void 0;
exports.hkpGroupFilterKeys = [
    'termine:ohneFolgetermine',
    'termine:ohnePassendenFolgeterminezeimpla',
    'termine:ohnePassendenFolgetermine',
    'termine:mitFolgtermine',
    'termine:hkpterminein7',
    'termine:hkpterminein14',
    'termine:hkpterminein30',
    'termine:baldtermine1wo',
    'termine:baldtermine3wo',
    'termine:ohneOffeneTermine',
    'termine:ohneOffeneUndFolgeTermine',
    'termine:mitOffenenTerminen',
    'termine:ohneTerminImGanzenQuartal',
    'termine:ohneTerminImQuartalBisHeute',
    'termine:mitTerminImGanzenQuartal',
    'termine:mitTerminImQuartalBisHeute',
    'abgelaufen:abgelaufen',
    'abgelaufen:baldAbgelaufen',
    'abgelaufen:abgelaufenOderBaldAbgelaufen',
    'abzurechnen:abzurechnen',
    'abzurechnen:baldabzurechnen',
    'leistungenrot:erbracht',
    'leistungenrot:abzurechnen',
    'frist:frist',
    'frist:fristabgelaufen',
    'mitlabel:alle',
    'ohnelabel:alle',
    'parstatus:alle',
    'parstatus:par',
    'parstatus:cpt',
    'parstatus:upt',
    'parstatus:grada',
    'parstatus:gradb',
    'parstatus:gradc',
    'parstatus:hatcpt',
    'parfehler:alle',
    'parfehler:abrechnung',
    'parfehler:terminierungalle',
    'parfehler:terminierungfolge',
    'parfehler:terminierungplanung',
    'parfehler:terminierungbev',
    'parfehler:terminierungupt',
    'parfehler:terminierungupterbringbar',
    'parfehler:terminierungverfallen',
    'parfehler:abrechnung',
    'parfehler:cpt',
    'unterschrift:mit',
    'unterschrift:ohne',
    'unterschrift:genehmigtohne',
];
exports.hkpFilterKeys = [
    'ignorefiltered',
    'additionallyloaded',
    'mitkommentaren',
    'veraltet',
    'ebzstatusmissmatch',
];
exports.hkpFavFilterKeys = [
    'fav:genehmigtOhneTermin',
    'fav:eingabeOderGenehmigtOhneTermin',
    'fav:genehmigtOhnePassendenTermin',
    'fav:eingabeOderGenehmigtOhnePassendenTermin',
    'fav:hkpTerminIn7TagenOhneGenehmigung',
    'fav:paPlaeneMitProblemen',
    'fav:nichtAbgerechnetMitAbgeschlossenerBehandlung',
    'fav:begonnenOhneGenehmigung',
    'fav:fristsachen',
    'fav:faelligeFristsachen',
    'fav:abgelaufen',
    'fav:offenAelterAls1Jahr',
];
