"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EncryptionType = exports.CompressionType = exports.BotExitCodes = exports.RoseStatusType = exports.Z1PoolType = exports.PoolMode = exports.IBotMode = void 0;
var IBotMode;
(function (IBotMode) {
    IBotMode["UNKNOWN"] = "UNKNOWN";
    IBotMode["ROSE_BOT"] = "ROSE_BOT";
    IBotMode["CHARLY_ANALYTICS_BOT"] = "CHARLY_ANALYTICS_BOT";
    IBotMode["CHARLY_ANALYTICS_SERVICE"] = "CHARLY_ANALYTICS_SERVICE";
})(IBotMode || (exports.IBotMode = IBotMode = {}));
var PoolMode;
(function (PoolMode) {
    PoolMode["single"] = "single";
    PoolMode["multi"] = "multi";
})(PoolMode || (exports.PoolMode = PoolMode = {}));
var Z1PoolType;
(function (Z1PoolType) {
    Z1PoolType["admin"] = "admin";
    Z1PoolType["z1"] = "z1";
    Z1PoolType["timer"] = "timer";
})(Z1PoolType || (exports.Z1PoolType = Z1PoolType = {}));
var RoseStatusType;
(function (RoseStatusType) {
    RoseStatusType["DOWN"] = "DOWN";
    RoseStatusType["UP"] = "UP";
    RoseStatusType["DISABLED"] = "DISABLED";
    RoseStatusType["UNKNOWN"] = "UNKNOWN";
})(RoseStatusType || (exports.RoseStatusType = RoseStatusType = {}));
var BotExitCodes;
(function (BotExitCodes) {
    BotExitCodes[BotExitCodes["OK"] = 0] = "OK";
    BotExitCodes[BotExitCodes["UNHANDLED_EXCEPTION"] = 2] = "UNHANDLED_EXCEPTION";
    BotExitCodes[BotExitCodes["UNHANDLED_REJECTION"] = 3] = "UNHANDLED_REJECTION";
    BotExitCodes[BotExitCodes["NOSERIALNUMBER"] = 10] = "NOSERIALNUMBER";
    BotExitCodes[BotExitCodes["NOLICENCE"] = 20] = "NOLICENCE";
    BotExitCodes[BotExitCodes["DELETELICENCE"] = 30] = "DELETELICENCE";
    BotExitCodes[BotExitCodes["LICENCEREQUESTFAIL"] = 40] = "LICENCEREQUESTFAIL";
    BotExitCodes[BotExitCodes["RESTARTFORCLIENTIDCHANGE"] = 50] = "RESTARTFORCLIENTIDCHANGE";
    BotExitCodes[BotExitCodes["DBCONNECTIONUNRECOVERABLE"] = 60] = "DBCONNECTIONUNRECOVERABLE";
    BotExitCodes[BotExitCodes["TEARDOWNUNRECOVERABLE"] = 70] = "TEARDOWNUNRECOVERABLE";
    BotExitCodes[BotExitCodes["TOOMANYFAILEDIMPORTSTARTS"] = 71] = "TOOMANYFAILEDIMPORTSTARTS";
    BotExitCodes[BotExitCodes["WAITEDTTOOLONGFORSETTINGS"] = 72] = "WAITEDTTOOLONGFORSETTINGS";
    BotExitCodes[BotExitCodes["SOCKETUNRECOVERABLE"] = 80] = "SOCKETUNRECOVERABLE";
    BotExitCodes[BotExitCodes["MANUALRESTART"] = 90] = "MANUALRESTART";
    BotExitCodes[BotExitCodes["NOBOTINFO"] = 91] = "NOBOTINFO";
})(BotExitCodes || (exports.BotExitCodes = BotExitCodes = {}));
var CompressionType;
(function (CompressionType) {
    CompressionType["BROTLI"] = "br";
    CompressionType["GZIP"] = "gzip";
    CompressionType["NONE"] = "none";
})(CompressionType || (exports.CompressionType = CompressionType = {}));
var EncryptionType;
(function (EncryptionType) {
    EncryptionType["AES_256_CTR"] = "aes-256-ctr";
    EncryptionType["NONE"] = "none";
})(EncryptionType || (exports.EncryptionType = EncryptionType = {}));
