"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PATIENT_EWE_STATUS = exports.ReducedPatientForTabletTasksKeys = exports.extractCharlyDateFromFileName = exports.prettyCharlyFileName = exports.formatTabletTaskToForUser = exports.TabletTaskHistoryStatus = void 0;
const enums_1 = require("./enums");
var TabletTaskHistoryStatus;
(function (TabletTaskHistoryStatus) {
    TabletTaskHistoryStatus["CANCELED"] = "canceled";
    TabletTaskHistoryStatus["INACTIVE_TIMEOUT"] = "inactive_timeout";
    TabletTaskHistoryStatus["SUCCESS"] = "success";
})(TabletTaskHistoryStatus || (exports.TabletTaskHistoryStatus = TabletTaskHistoryStatus = {}));
function formatTabletTaskToForUser(task) {
    var _a;
    if (task.type === enums_1.TabletTaskType.ANAMNESE_TASK) {
        let questionnaireName = task.infos.questionnaire.title;
        return 'Fragebogen: ' + questionnaireName;
    }
    switch (task.type) {
        case enums_1.TabletTaskType.EWE_TASK:
            return 'EWE';
        case enums_1.TabletTaskType.DOCUMENT_TASK:
            return `Dokument: ${(_a = task.documentSignConfigs) === null || _a === void 0 ? void 0 : _a.map(entry => entry.patientFileName || prettyCharlyFileName(entry.file)).join(', ')}`;
        case enums_1.TabletTaskType.QR_CODE_TASK:
            return 'QR Code';
        case enums_1.TabletTaskType.UPLOAD_TASK:
            return 'Upload';
        default:
            return 'Unbekannt';
    }
}
exports.formatTabletTaskToForUser = formatTabletTaskToForUser;
function prettyCharlyFileName(file) {
    if (file.endsWith('.doku')) {
        file = file.replace(/\.doku$/, '');
    }
    if (extractCharlyDateFromFileName(file)) {
        file = file.substring(8);
        // remove file extension
        if (file.indexOf('.') > 0) {
            file = file.substring(0, file.lastIndexOf('.'));
        }
    }
    return file;
}
exports.prettyCharlyFileName = prettyCharlyFileName;
function extractCharlyDateFromFileName(file) {
    if (file.length > 8) {
        let dateStart = file.substring(0, 8);
        if (!isNaN(+dateStart)) {
            let y = dateStart.substring(0, 4);
            let m = dateStart.substring(4, 4 + 2);
            let d = dateStart.substring(6, 6 + 2);
            return `${d}.${m}.${y}`;
        }
    }
    return '';
}
exports.extractCharlyDateFromFileName = extractCharlyDateFromFileName;
const ReducedPatientForTabletTasksKeysConst = [
    'name',
    'vorname',
    'geburtsdatum',
    'geschlecht',
    'extid',
    'email',
    'ort',
];
exports.ReducedPatientForTabletTasksKeys = ReducedPatientForTabletTasksKeysConst;
var PATIENT_EWE_STATUS;
(function (PATIENT_EWE_STATUS) {
    PATIENT_EWE_STATUS[PATIENT_EWE_STATUS["SIGNING"] = 0] = "SIGNING";
    PATIENT_EWE_STATUS[PATIENT_EWE_STATUS["UPLOADING"] = 1] = "UPLOADING";
    PATIENT_EWE_STATUS[PATIENT_EWE_STATUS["UPLAOD_SUCCESS"] = 2] = "UPLAOD_SUCCESS";
    PATIENT_EWE_STATUS[PATIENT_EWE_STATUS["UPLOAD_FAILED"] = 3] = "UPLOAD_FAILED";
})(PATIENT_EWE_STATUS || (exports.PATIENT_EWE_STATUS = PATIENT_EWE_STATUS = {}));
