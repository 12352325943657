"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryTypes = void 0;
var QueryTypes;
(function (QueryTypes) {
    QueryTypes["SELECT"] = "SELECT";
    QueryTypes["INSERT"] = "INSERT";
    QueryTypes["UPDATE"] = "UPDATE";
    QueryTypes["BULKUPDATE"] = "BULKUPDATE";
    QueryTypes["BULKDELETE"] = "BULKDELETE";
    QueryTypes["DELETE"] = "DELETE";
    QueryTypes["UPSERT"] = "UPSERT";
    QueryTypes["VERSION"] = "VERSION";
    QueryTypes["SHOWTABLES"] = "SHOWTABLES";
    QueryTypes["SHOWINDEXES"] = "SHOWINDEXES";
    QueryTypes["DESCRIBE"] = "DESCRIBE";
    QueryTypes["RAW"] = "RAW";
    QueryTypes["FOREIGNKEYS"] = "FOREIGNKEYS";
})(QueryTypes || (exports.QueryTypes = QueryTypes = {}));
