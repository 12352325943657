"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PAREreignisGruppeTyp = exports.PAREreignisFehlerTyp = exports.PAREreignisFehlerLevel = exports.PAREreignisPhaseTyp = exports.UPTEventType = exports.PAREreignisTyp = exports.TabletTaskType = exports.PVS_PORT = exports.TOKEN_PURPOSE = exports.CharlyChargeFlag = exports.WoAnbietenTyp = exports.ImportTyp = exports.FeiertageTyp = exports.PZRType = exports.BehandlerTyp = exports.ZAType = exports.PAGradType = exports.EGKEingabeArtType = exports.VerrechnungsArtType = exports.Units = exports.KartenStatus = exports.LeistungsZurechnungType = exports.rohDatenTypMap = exports.RoseDBType = exports.PendingFileUploadStatus = exports.RechnungsArtType = exports.EingabeKontrollArtType = exports.RoentgenQuelleType = exports.TerminbuchVerfuegbarkeitStatusType = exports.TerminbuchTerminStatusType = exports.LaborArtType = exports.GozArtType = exports.HKPArtTypeAlle = exports.HKPArtType = exports.HKPSubStatusType = exports.HKPStatusType = exports.HKPAbzurechnenFlag = exports.RecallType = exports.ZahlungsArtType = exports.TerminStatusType = exports.TerminStatusTypeAlt = exports.HKPPARPhaseType = exports.HKPTerminType = exports.PAType = exports.AbrechnungsArt = exports.BerechnungsType = exports.GeschlechtType = exports.UIKassePrivatType = exports.KassePrivatType = exports.BemaGozType = void 0;
var BemaGozType;
(function (BemaGozType) {
    BemaGozType[BemaGozType["UNKNOWN"] = -1] = "UNKNOWN";
    BemaGozType[BemaGozType["GOZ"] = 0] = "GOZ";
    BemaGozType[BemaGozType["BEMA"] = 1] = "BEMA";
    BemaGozType[BemaGozType["VERLANGEN"] = 2] = "VERLANGEN";
})(BemaGozType || (exports.BemaGozType = BemaGozType = {}));
var KassePrivatType;
(function (KassePrivatType) {
    KassePrivatType[KassePrivatType["UNKNOWN"] = 0] = "UNKNOWN";
    KassePrivatType[KassePrivatType["PRIVAT"] = 1] = "PRIVAT";
    KassePrivatType[KassePrivatType["KASSE"] = 2] = "KASSE";
})(KassePrivatType || (exports.KassePrivatType = KassePrivatType = {}));
var UIKassePrivatType;
(function (UIKassePrivatType) {
    UIKassePrivatType["ALLE"] = "ALLE";
    UIKassePrivatType["PRIVAT"] = "PRIVAT";
    UIKassePrivatType["KASSE"] = "KASSE";
})(UIKassePrivatType || (exports.UIKassePrivatType = UIKassePrivatType = {}));
var GeschlechtType;
(function (GeschlechtType) {
    GeschlechtType[GeschlechtType["UNKNOWN"] = 0] = "UNKNOWN";
    GeschlechtType[GeschlechtType["MALE"] = 1] = "MALE";
    GeschlechtType[GeschlechtType["FEMALE"] = 2] = "FEMALE";
    GeschlechtType[GeschlechtType["NEUTRAL"] = 3] = "NEUTRAL";
})(GeschlechtType || (exports.GeschlechtType = GeschlechtType = {}));
var BerechnungsType;
(function (BerechnungsType) {
    BerechnungsType[BerechnungsType["UNBEKANNT"] = 0] = "UNBEKANNT";
    BerechnungsType[BerechnungsType["KASSE"] = 1] = "KASSE";
    BerechnungsType[BerechnungsType["PRIVAT"] = 2] = "PRIVAT";
    BerechnungsType[BerechnungsType["SELBSTZAHLER"] = 3] = "SELBSTZAHLER";
})(BerechnungsType || (exports.BerechnungsType = BerechnungsType = {}));
var AbrechnungsArt;
(function (AbrechnungsArt) {
    AbrechnungsArt[AbrechnungsArt["UNBEKANNT"] = 0] = "UNBEKANNT";
    // GOZ
    AbrechnungsArt[AbrechnungsArt["GOZ_ALLG"] = 100] = "GOZ_ALLG";
    AbrechnungsArt[AbrechnungsArt["GOZ_KONS"] = 101] = "GOZ_KONS";
    AbrechnungsArt[AbrechnungsArt["GOZ_CHIR"] = 102] = "GOZ_CHIR";
    AbrechnungsArt[AbrechnungsArt["GOZ_IMPL"] = 103] = "GOZ_IMPL";
    AbrechnungsArt[AbrechnungsArt["GOZ_PA"] = 104] = "GOZ_PA";
    AbrechnungsArt[AbrechnungsArt["GOZ_FA"] = 105] = "GOZ_FA";
    AbrechnungsArt[AbrechnungsArt["GOZ_ZE"] = 106] = "GOZ_ZE";
    AbrechnungsArt[AbrechnungsArt["GOZ_KFO"] = 107] = "GOZ_KFO";
    AbrechnungsArt[AbrechnungsArt["GOZ_PZR"] = 108] = "GOZ_PZR";
    // BEMA
    AbrechnungsArt[AbrechnungsArt["BEMA_ZE"] = 200] = "BEMA_ZE";
    AbrechnungsArt[AbrechnungsArt["BEMA_PA"] = 201] = "BEMA_PA";
    AbrechnungsArt[AbrechnungsArt["BEMA_KFO"] = 202] = "BEMA_KFO";
    AbrechnungsArt[AbrechnungsArt["BEMA_KONSCHIR"] = 203] = "BEMA_KONSCHIR";
    AbrechnungsArt[AbrechnungsArt["BEMA_KIEFERB"] = 204] = "BEMA_KIEFERB";
    AbrechnungsArt[AbrechnungsArt["BEMA_IP"] = 205] = "BEMA_IP";
    AbrechnungsArt[AbrechnungsArt["BEMA_BESUCH"] = 206] = "BEMA_BESUCH";
})(AbrechnungsArt || (exports.AbrechnungsArt = AbrechnungsArt = {}));
var PAType;
(function (PAType) {
    PAType[PAType["OHNE"] = 0] = "OHNE";
    PAType[PAType["PZR"] = 1] = "PZR";
    PAType[PAType["PAV"] = 2] = "PAV";
    PAType[PAType["PA"] = 3] = "PA";
    PAType[PAType["PAN"] = 4] = "PAN";
    PAType[PAType["UPT"] = 5] = "UPT";
})(PAType || (exports.PAType = PAType = {}));
var HKPTerminType;
(function (HKPTerminType) {
    HKPTerminType[HKPTerminType["OHNE"] = 0] = "OHNE";
    HKPTerminType[HKPTerminType["ZE"] = 1] = "ZE";
    HKPTerminType[HKPTerminType["PA"] = 2] = "PA";
    HKPTerminType[HKPTerminType["IMPLA"] = 3] = "IMPLA";
    HKPTerminType[HKPTerminType["PRIVAT"] = 4] = "PRIVAT";
    HKPTerminType[HKPTerminType["ALLE"] = 5] = "ALLE";
    HKPTerminType[HKPTerminType["KFO"] = 6] = "KFO";
    HKPTerminType[HKPTerminType["KBR"] = 7] = "KBR";
})(HKPTerminType || (exports.HKPTerminType = HKPTerminType = {}));
var HKPPARPhaseType;
(function (HKPPARPhaseType) {
    HKPPARPhaseType[HKPPARPhaseType["UNBEKANNT"] = 0] = "UNBEKANNT";
    HKPPARPhaseType[HKPPARPhaseType["BEANTRAGT"] = 10] = "BEANTRAGT";
    HKPPARPhaseType[HKPPARPhaseType["GENEHMIGT"] = 20] = "GENEHMIGT";
    HKPPARPhaseType[HKPPARPhaseType["PAR_BEGONNEN"] = 30] = "PAR_BEGONNEN";
    HKPPARPhaseType[HKPPARPhaseType["AIT_BEGONNEN"] = 40] = "AIT_BEGONNEN";
    HKPPARPhaseType[HKPPARPhaseType["PAR_ABGESCHLOSSEN"] = 50] = "PAR_ABGESCHLOSSEN";
    HKPPARPhaseType[HKPPARPhaseType["PAR_BEFUNDEVALUATION"] = 60] = "PAR_BEFUNDEVALUATION";
    HKPPARPhaseType[HKPPARPhaseType["PAR_CHIRURGIE"] = 70] = "PAR_CHIRURGIE";
    HKPPARPhaseType[HKPPARPhaseType["PAR_CHIRURGIE_BEFUNDEVALUATION"] = 80] = "PAR_CHIRURGIE_BEFUNDEVALUATION";
    HKPPARPhaseType[HKPPARPhaseType["UPT"] = 90] = "UPT";
    HKPPARPhaseType[HKPPARPhaseType["BEENDET"] = 100] = "BEENDET";
})(HKPPARPhaseType || (exports.HKPPARPhaseType = HKPPARPhaseType = {}));
var TerminStatusTypeAlt;
(function (TerminStatusTypeAlt) {
    TerminStatusTypeAlt[TerminStatusTypeAlt["OK"] = 0] = "OK";
    TerminStatusTypeAlt[TerminStatusTypeAlt["NICHTERSCHIENEN"] = 1] = "NICHTERSCHIENEN";
    TerminStatusTypeAlt[TerminStatusTypeAlt["KURZFRISTIG_ABGESAGT"] = 2] = "KURZFRISTIG_ABGESAGT";
    TerminStatusTypeAlt[TerminStatusTypeAlt["FRISTGERECHT_ABGESAGT"] = 3] = "FRISTGERECHT_ABGESAGT";
    TerminStatusTypeAlt[TerminStatusTypeAlt["VIRTUELL"] = 99] = "VIRTUELL";
})(TerminStatusTypeAlt || (exports.TerminStatusTypeAlt = TerminStatusTypeAlt = {}));
var TerminStatusType;
(function (TerminStatusType) {
    TerminStatusType[TerminStatusType["UNKNOWN"] = -1000] = "UNKNOWN";
    TerminStatusType[TerminStatusType["OFFEN"] = -1] = "OFFEN";
    TerminStatusType[TerminStatusType["OHNE"] = 0] = "OHNE";
    TerminStatusType[TerminStatusType["OK"] = 1] = "OK";
    TerminStatusType[TerminStatusType["NICHTERSCHIENEN"] = 2] = "NICHTERSCHIENEN";
    TerminStatusType[TerminStatusType["KURZFRISTIG_ABGESAGT"] = 3] = "KURZFRISTIG_ABGESAGT";
    TerminStatusType[TerminStatusType["FRISTGERECHT_ABGESAGT"] = 4] = "FRISTGERECHT_ABGESAGT";
    TerminStatusType[TerminStatusType["PRAXIS_HAT_ABGESAGT"] = 5] = "PRAXIS_HAT_ABGESAGT";
    TerminStatusType[TerminStatusType["KONTROLLIERT_BEHANDLER_OK"] = 11] = "KONTROLLIERT_BEHANDLER_OK";
    TerminStatusType[TerminStatusType["KONTROLLIERT_BEHANDLER_NICHTERSCHIENEN"] = 12] = "KONTROLLIERT_BEHANDLER_NICHTERSCHIENEN";
    TerminStatusType[TerminStatusType["KONTROLLIERT_BEHANDLER_KURZFRISTIG_ABGESAGT"] = 13] = "KONTROLLIERT_BEHANDLER_KURZFRISTIG_ABGESAGT";
    TerminStatusType[TerminStatusType["KONTROLLIERT_BEHANDLER_FRISTGERECHT_ABGESAGT"] = 14] = "KONTROLLIERT_BEHANDLER_FRISTGERECHT_ABGESAGT";
    TerminStatusType[TerminStatusType["KONTROLLIERT_BEHANDLER_PRAXIS_HAT_ABGESAGT"] = 15] = "KONTROLLIERT_BEHANDLER_PRAXIS_HAT_ABGESAGT";
    TerminStatusType[TerminStatusType["KONTROLLIERT_ABRECHNUNG_OK"] = 21] = "KONTROLLIERT_ABRECHNUNG_OK";
    TerminStatusType[TerminStatusType["KONTROLLIERT_ABRECHNUNG_NICHTERSCHIENEN"] = 22] = "KONTROLLIERT_ABRECHNUNG_NICHTERSCHIENEN";
    TerminStatusType[TerminStatusType["KONTROLLIERT_ABRECHNUNG_KURZFRISTIG_ABGESAGT"] = 23] = "KONTROLLIERT_ABRECHNUNG_KURZFRISTIG_ABGESAGT";
    TerminStatusType[TerminStatusType["KONTROLLIERT_ABRECHNUNG_FRISTGERECHT_ABGESAGT"] = 24] = "KONTROLLIERT_ABRECHNUNG_FRISTGERECHT_ABGESAGT";
    TerminStatusType[TerminStatusType["KONTROLLIERT_ABRECHNUNG_PRAXIS_HAT_ABGESAGT"] = 25] = "KONTROLLIERT_ABRECHNUNG_PRAXIS_HAT_ABGESAGT";
    TerminStatusType[TerminStatusType["KONTROLLIERT_ALLE_OK"] = 31] = "KONTROLLIERT_ALLE_OK";
    TerminStatusType[TerminStatusType["KONTROLLIERT_ALLE_NICHTERSCHIENEN"] = 32] = "KONTROLLIERT_ALLE_NICHTERSCHIENEN";
    TerminStatusType[TerminStatusType["KONTROLLIERT_ALLE_KURZFRISTIG_ABGESAGT"] = 33] = "KONTROLLIERT_ALLE_KURZFRISTIG_ABGESAGT";
    TerminStatusType[TerminStatusType["KONTROLLIERT_ALLE_FRISTGERECHT_ABGESAGT"] = 34] = "KONTROLLIERT_ALLE_FRISTGERECHT_ABGESAGT";
    TerminStatusType[TerminStatusType["KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT"] = 35] = "KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT";
    TerminStatusType[TerminStatusType["VIRTUELL"] = 999] = "VIRTUELL";
})(TerminStatusType || (exports.TerminStatusType = TerminStatusType = {}));
var ZahlungsArtType;
(function (ZahlungsArtType) {
    ZahlungsArtType[ZahlungsArtType["NONE"] = 0] = "NONE";
    ZahlungsArtType[ZahlungsArtType["BAR"] = 1] = "BAR";
    ZahlungsArtType[ZahlungsArtType["SCHECK"] = 2] = "SCHECK";
    ZahlungsArtType[ZahlungsArtType["EC"] = 4] = "EC";
    ZahlungsArtType[ZahlungsArtType["ERSTATTUNG"] = 8] = "ERSTATTUNG";
    ZahlungsArtType[ZahlungsArtType["BANK1"] = 16] = "BANK1";
    ZahlungsArtType[ZahlungsArtType["BANK2"] = 32] = "BANK2";
})(ZahlungsArtType || (exports.ZahlungsArtType = ZahlungsArtType = {}));
var RecallType;
(function (RecallType) {
    RecallType[RecallType["KEIN_RECALL"] = 0] = "KEIN_RECALL";
    RecallType[RecallType["NAECHSTER_RECALLTERMIN"] = 1] = "NAECHSTER_RECALLTERMIN";
    RecallType[RecallType["RECALLTERMIN_ERINNERT"] = 2] = "RECALLTERMIN_ERINNERT";
    RecallType[RecallType["RECALLTERMIN_VEREINBART"] = 3] = "RECALLTERMIN_VEREINBART";
    RecallType[RecallType["RECALLTERMIN_ERSCHIENEN"] = 4] = "RECALLTERMIN_ERSCHIENEN";
})(RecallType || (exports.RecallType = RecallType = {}));
var HKPAbzurechnenFlag;
(function (HKPAbzurechnenFlag) {
    HKPAbzurechnenFlag[HKPAbzurechnenFlag["NICHT_ABRECHNEN"] = 0] = "NICHT_ABRECHNEN";
    HKPAbzurechnenFlag[HKPAbzurechnenFlag["EINGESETZT"] = 1] = "EINGESETZT";
    HKPAbzurechnenFlag[HKPAbzurechnenFlag["LEISTUNGEN_ERBRACHT"] = 2] = "LEISTUNGEN_ERBRACHT";
    HKPAbzurechnenFlag[HKPAbzurechnenFlag["PAR_ABGESCHLOSSEN"] = 4] = "PAR_ABGESCHLOSSEN";
    HKPAbzurechnenFlag[HKPAbzurechnenFlag["PAR_HAENGT"] = 8] = "PAR_HAENGT";
})(HKPAbzurechnenFlag || (exports.HKPAbzurechnenFlag = HKPAbzurechnenFlag = {}));
var HKPStatusType;
(function (HKPStatusType) {
    HKPStatusType[HKPStatusType["UNKNOWN"] = -1] = "UNKNOWN";
    HKPStatusType[HKPStatusType["EINGABE"] = 0] = "EINGABE";
    HKPStatusType[HKPStatusType["ALTERNATIV"] = 1] = "ALTERNATIV";
    HKPStatusType[HKPStatusType["\u00DCBERARBEITEN"] = 2] = "\u00DCBERARBEITEN";
    HKPStatusType[HKPStatusType["ABGELEHNT"] = 3] = "ABGELEHNT";
    HKPStatusType[HKPStatusType["TROTZABLEHNUNGANGENOMMEN"] = 4] = "TROTZABLEHNUNGANGENOMMEN";
    HKPStatusType[HKPStatusType["GENEHMIGT"] = 5] = "GENEHMIGT";
    HKPStatusType[HKPStatusType["DURCHGEFUEHRT"] = 6] = "DURCHGEFUEHRT";
    HKPStatusType[HKPStatusType["VERWORFEN"] = 7] = "VERWORFEN";
    HKPStatusType[HKPStatusType["SOFORTABRECHNUNG"] = 12] = "SOFORTABRECHNUNG";
})(HKPStatusType || (exports.HKPStatusType = HKPStatusType = {}));
var HKPSubStatusType;
(function (HKPSubStatusType) {
    HKPSubStatusType[HKPSubStatusType["UNKNOWN"] = -1] = "UNKNOWN";
    HKPSubStatusType[HKPSubStatusType["EINGABE_IN_ARBEIT"] = 101] = "EINGABE_IN_ARBEIT";
    HKPSubStatusType[HKPSubStatusType["EINGABE_ZU_PRUEFEN"] = 102] = "EINGABE_ZU_PRUEFEN";
    HKPSubStatusType[HKPSubStatusType["EINGABE_GEPRUEFT"] = 103] = "EINGABE_GEPRUEFT";
    HKPSubStatusType[HKPSubStatusType["EINGABE_BEI_PATIENT"] = 104] = "EINGABE_BEI_PATIENT";
    HKPSubStatusType[HKPSubStatusType["EINGABE_BEI_KASSE"] = 105] = "EINGABE_BEI_KASSE";
    HKPSubStatusType[HKPSubStatusType["EINGABE_BEI_GUTACHTER"] = 106] = "EINGABE_BEI_GUTACHTER";
    HKPSubStatusType[HKPSubStatusType["ABGELEHNT_KASSE"] = 301] = "ABGELEHNT_KASSE";
    HKPSubStatusType[HKPSubStatusType["ABGELEHNT_PATIENT"] = 302] = "ABGELEHNT_PATIENT";
    HKPSubStatusType[HKPSubStatusType["ABGELEHNT_GUTACHTER"] = 303] = "ABGELEHNT_GUTACHTER";
    HKPSubStatusType[HKPSubStatusType["GENEHMIGT_PATIENT_OHNE_TERMIN"] = 501] = "GENEHMIGT_PATIENT_OHNE_TERMIN";
    HKPSubStatusType[HKPSubStatusType["GENEHMIGT_PATIENT_HAT_TERMIN"] = 502] = "GENEHMIGT_PATIENT_HAT_TERMIN";
    HKPSubStatusType[HKPSubStatusType["GENEHMIGT_IN_ARBEIT"] = 503] = "GENEHMIGT_IN_ARBEIT";
    HKPSubStatusType[HKPSubStatusType["GENEHMIGT_EINGEGLIEDERT_ERBRACHT"] = 504] = "GENEHMIGT_EINGEGLIEDERT_ERBRACHT";
    HKPSubStatusType[HKPSubStatusType["VERWORFEN_ALTERNATIVE_BEHANDLUNG_DURCHGEFUEHRT"] = 701] = "VERWORFEN_ALTERNATIVE_BEHANDLUNG_DURCHGEFUEHRT";
    HKPSubStatusType[HKPSubStatusType["VERWORFEN_ZU_TEUER"] = 702] = "VERWORFEN_ZU_TEUER";
    HKPSubStatusType[HKPSubStatusType["VERWORFEN_NICHT_VERSTANDEN"] = 703] = "VERWORFEN_NICHT_VERSTANDEN";
    HKPSubStatusType[HKPSubStatusType["VERWORFEN_PATIENT_WARTET_AUF_ANRUF"] = 704] = "VERWORFEN_PATIENT_WARTET_AUF_ANRUF";
    HKPSubStatusType[HKPSubStatusType["VERWORFEN_FALSCHE_LOESUNG"] = 705] = "VERWORFEN_FALSCHE_LOESUNG";
    HKPSubStatusType[HKPSubStatusType["VERWORFEN_PATIENT_FUEHLT_SICH_BEVORMUNDET"] = 706] = "VERWORFEN_PATIENT_FUEHLT_SICH_BEVORMUNDET";
    HKPSubStatusType[HKPSubStatusType["VERWORFEN_BEHANDLER_UNSYMPATISCH"] = 707] = "VERWORFEN_BEHANDLER_UNSYMPATISCH";
    HKPSubStatusType[HKPSubStatusType["VERWORFEN_UNBEHAGLICHES_PRAXISAMBIENTE"] = 708] = "VERWORFEN_UNBEHAGLICHES_PRAXISAMBIENTE";
    HKPSubStatusType[HKPSubStatusType["VERWORFEN_ZAHNARZTWECHSEL_OHNE_ANGABE"] = 709] = "VERWORFEN_ZAHNARZTWECHSEL_OHNE_ANGABE";
    HKPSubStatusType[HKPSubStatusType["VERWORFEN_KEINE_ANGABE"] = 710] = "VERWORFEN_KEINE_ANGABE";
})(HKPSubStatusType || (exports.HKPSubStatusType = HKPSubStatusType = {}));
var HKPArtType;
(function (HKPArtType) {
    HKPArtType[HKPArtType["UNBEKANNT"] = -1] = "UNBEKANNT";
    HKPArtType[HKPArtType["PRIVAT"] = 0] = "PRIVAT";
    HKPArtType[HKPArtType["PRIVAT_FA"] = 1] = "PRIVAT_FA";
    HKPArtType[HKPArtType["PRIVAT_PA"] = 2] = "PRIVAT_PA";
    HKPArtType[HKPArtType["PRIVAT_IMPL"] = 3] = "PRIVAT_IMPL";
    HKPArtType[HKPArtType["PRIVAT_GTR"] = 4] = "PRIVAT_GTR";
    HKPArtType[HKPArtType["PRIVAT_ZE"] = 5] = "PRIVAT_ZE";
    HKPArtType[HKPArtType["KASSE_ZE_SPEZIAL"] = 6] = "KASSE_ZE_SPEZIAL";
    HKPArtType[HKPArtType["___KVART_PA"] = 7] = "___KVART_PA";
    HKPArtType[HKPArtType["___KVART_KIEFERBRUCH"] = 8] = "___KVART_KIEFERBRUCH";
    HKPArtType[HKPArtType["___KVART_KFO_VERL1"] = 10] = "___KVART_KFO_VERL1";
    HKPArtType[HKPArtType["___KVART_KFO_VERL2"] = 11] = "___KVART_KFO_VERL2";
    HKPArtType[HKPArtType["___KVART_KFO_RETENTION"] = 12] = "___KVART_KFO_RETENTION";
    HKPArtType[HKPArtType["KASSE_KFO2"] = 9] = "KASSE_KFO2";
    HKPArtType[HKPArtType["PRIVAT_KFO"] = 20] = "PRIVAT_KFO";
    HKPArtType[HKPArtType["PRIVAT_KFO2"] = 21] = "PRIVAT_KFO2";
    HKPArtType[HKPArtType["___KVART_ZE98"] = 50] = "___KVART_ZE98";
    HKPArtType[HKPArtType["___KVART_ZE_ALTERNATIV"] = 51] = "___KVART_ZE_ALTERNATIV";
    HKPArtType[HKPArtType["___KVART_ZE_UNFALL"] = 52] = "___KVART_ZE_UNFALL";
    HKPArtType[HKPArtType["KASSE_ZE"] = 53] = "KASSE_ZE";
    HKPArtType[HKPArtType["___KVART_ZE_2005BGS"] = 54] = "___KVART_ZE_2005BGS";
    HKPArtType[HKPArtType["KASSE_KFO"] = 60] = "KASSE_KFO";
    HKPArtType[HKPArtType["KASSE_PA"] = 70] = "KASSE_PA";
    HKPArtType[HKPArtType["KASSE_PA_ERGAENZUNG"] = 71] = "KASSE_PA_ERGAENZUNG";
    HKPArtType[HKPArtType["KASSE_KIEFERBRUCH"] = 80] = "KASSE_KIEFERBRUCH";
    HKPArtType[HKPArtType["KASSE_PAR"] = 85] = "KASSE_PAR";
    HKPArtType[HKPArtType["PASTATUS_P22A"] = 86] = "PASTATUS_P22A";
    HKPArtType[HKPArtType["EBZ_KBR"] = 100] = "EBZ_KBR";
    HKPArtType[HKPArtType["EBZ_KGL"] = 101] = "EBZ_KGL";
    HKPArtType[HKPArtType["EBZ_KFO"] = 102] = "EBZ_KFO";
    HKPArtType[HKPArtType["EBZ_PAR"] = 103] = "EBZ_PAR";
    HKPArtType[HKPArtType["EBZ_PMB"] = 104] = "EBZ_PMB";
    HKPArtType[HKPArtType["EBZ_ZER"] = 105] = "EBZ_ZER";
    HKPArtType[HKPArtType["KASSE_ZE_REPA"] = 200] = "KASSE_ZE_REPA";
    HKPArtType[HKPArtType["KASSE_KFO_NACHTRAG"] = 10000] = "KASSE_KFO_NACHTRAG";
})(HKPArtType || (exports.HKPArtType = HKPArtType = {}));
exports.HKPArtTypeAlle = [
    HKPArtType.PRIVAT,
    HKPArtType.PRIVAT_FA,
    HKPArtType.PRIVAT_PA,
    HKPArtType.PRIVAT_IMPL,
    HKPArtType.PRIVAT_GTR,
    HKPArtType.PRIVAT_ZE,
    HKPArtType.KASSE_ZE_SPEZIAL,
    // HKPArtType.___KVART_PA,
    // HKPArtType.___KVART_KIEFERBRUCH,
    // HKPArtType.___KVART_KFO_VERL1,
    // HKPArtType.___KVART_KFO_VERL2,
    // HKPArtType.___KVART_KFO_RETENTION,
    HKPArtType.KASSE_KFO2,
    HKPArtType.PRIVAT_KFO,
    HKPArtType.PRIVAT_KFO2,
    // HKPArtType.___KVART_ZE98,
    // HKPArtType.___KVART_ZE_ALTERNATIV,
    // HKPArtType.___KVART_ZE_UNFALL,
    HKPArtType.KASSE_ZE,
    // HKPArtType.___KVART_ZE_2005BGS,
    HKPArtType.KASSE_KFO,
    HKPArtType.KASSE_PA,
    HKPArtType.KASSE_PA_ERGAENZUNG,
    HKPArtType.KASSE_KIEFERBRUCH,
    HKPArtType.KASSE_PAR,
    HKPArtType.PASTATUS_P22A,
    HKPArtType.EBZ_KBR,
    HKPArtType.EBZ_KGL,
    HKPArtType.EBZ_KFO,
    HKPArtType.EBZ_PAR,
    HKPArtType.EBZ_PMB,
    HKPArtType.EBZ_ZER,
    HKPArtType.KASSE_KFO_NACHTRAG,
    HKPArtType.KASSE_ZE_REPA,
];
var GozArtType;
(function (GozArtType) {
    GozArtType[GozArtType["ALTEGOZ"] = 1] = "ALTEGOZ";
    GozArtType[GozArtType["AERZTLICHELEISTUNGEN"] = 2] = "AERZTLICHELEISTUNGEN";
    GozArtType[GozArtType["GOZ"] = 3] = "GOZ";
})(GozArtType || (exports.GozArtType = GozArtType = {}));
var LaborArtType;
(function (LaborArtType) {
    LaborArtType[LaborArtType["PRIVAT_BEB"] = 0] = "PRIVAT_BEB";
    LaborArtType[LaborArtType["KASSE_BEL"] = 1] = "KASSE_BEL";
})(LaborArtType || (exports.LaborArtType = LaborArtType = {}));
var TerminbuchTerminStatusType;
(function (TerminbuchTerminStatusType) {
    TerminbuchTerminStatusType[TerminbuchTerminStatusType["KEIN_TERMIN"] = 0] = "KEIN_TERMIN";
    TerminbuchTerminStatusType[TerminbuchTerminStatusType["TERMIN_OHNE_STATUS"] = 1] = "TERMIN_OHNE_STATUS";
    TerminbuchTerminStatusType[TerminbuchTerminStatusType["TERMIN_AUSFALL"] = 2] = "TERMIN_AUSFALL";
    TerminbuchTerminStatusType[TerminbuchTerminStatusType["TERMIN_OK"] = 3] = "TERMIN_OK";
})(TerminbuchTerminStatusType || (exports.TerminbuchTerminStatusType = TerminbuchTerminStatusType = {}));
var TerminbuchVerfuegbarkeitStatusType;
(function (TerminbuchVerfuegbarkeitStatusType) {
    TerminbuchVerfuegbarkeitStatusType[TerminbuchVerfuegbarkeitStatusType["KEINE_DATEN"] = 0] = "KEINE_DATEN";
    TerminbuchVerfuegbarkeitStatusType[TerminbuchVerfuegbarkeitStatusType["ABWESENHEIT"] = 1] = "ABWESENHEIT";
    TerminbuchVerfuegbarkeitStatusType[TerminbuchVerfuegbarkeitStatusType["TERMIN_NICHT_ANBIETEN"] = 2] = "TERMIN_NICHT_ANBIETEN";
    TerminbuchVerfuegbarkeitStatusType[TerminbuchVerfuegbarkeitStatusType["TERMIN_ANBIETEN"] = 3] = "TERMIN_ANBIETEN";
})(TerminbuchVerfuegbarkeitStatusType || (exports.TerminbuchVerfuegbarkeitStatusType = TerminbuchVerfuegbarkeitStatusType = {}));
var RoentgenQuelleType;
(function (RoentgenQuelleType) {
    RoentgenQuelleType["ROENTGENBUCH"] = "R";
    RoentgenQuelleType["LEISTUNG"] = "L";
})(RoentgenQuelleType || (exports.RoentgenQuelleType = RoentgenQuelleType = {}));
var EingabeKontrollArtType;
(function (EingabeKontrollArtType) {
    EingabeKontrollArtType[EingabeKontrollArtType["BEHANDLER"] = 1] = "BEHANDLER";
    EingabeKontrollArtType[EingabeKontrollArtType["ABRECHNUNG"] = 2] = "ABRECHNUNG";
})(EingabeKontrollArtType || (exports.EingabeKontrollArtType = EingabeKontrollArtType = {}));
var RechnungsArtType;
(function (RechnungsArtType) {
    RechnungsArtType[RechnungsArtType["OHNE_KV"] = 0] = "OHNE_KV";
    RechnungsArtType[RechnungsArtType["ZA_KASSE_KV"] = 1] = "ZA_KASSE_KV";
    RechnungsArtType[RechnungsArtType["KFO_KASSE_KV"] = 3] = "KFO_KASSE_KV";
    RechnungsArtType[RechnungsArtType["UNFALL_KASSE_KV"] = 4] = "UNFALL_KASSE_KV";
})(RechnungsArtType || (exports.RechnungsArtType = RechnungsArtType = {}));
var PendingFileUploadStatus;
(function (PendingFileUploadStatus) {
    PendingFileUploadStatus["NEW"] = "new";
    PendingFileUploadStatus["FAILED"] = "failed";
    PendingFileUploadStatus["SUCCEEDED"] = "succeeded";
})(PendingFileUploadStatus || (exports.PendingFileUploadStatus = PendingFileUploadStatus = {}));
// from our data definition to postgres types
var RoseDBType;
(function (RoseDBType) {
    RoseDBType["SERIAL"] = "serial";
    RoseDBType["BIGSERIAL"] = "bigserial";
    RoseDBType["STRING"] = "text";
    RoseDBType["STRINGARRAY"] = "text[]";
    RoseDBType["TIME_TZ"] = "timestamp with time zone";
    RoseDBType["TIME_NO_TZ"] = "timestamp without time zone";
    RoseDBType["DATE"] = "date";
    RoseDBType["DATEARRAY"] = "date[]";
    RoseDBType["BOOLEAN"] = "boolean";
    RoseDBType["NUMBER"] = "double precision";
    RoseDBType["NUMBERARRAY"] = "double precision[]";
    RoseDBType["JSON"] = "jsonb";
    RoseDBType["HOURBITMASKTYPE"] = "bit varying(60)";
    RoseDBType["BLOB"] = "bytea";
    RoseDBType["BLOBARRAY"] = "bytea[]";
    RoseDBType["BIGINT"] = "bigint";
    RoseDBType["INT"] = "int";
})(RoseDBType || (exports.RoseDBType = RoseDBType = {}));
exports.rohDatenTypMap = {
    text: RoseDBType.STRING,
    float: RoseDBType.NUMBER,
    integer: RoseDBType.INT,
    bigint: RoseDBType.BIGINT,
    date: RoseDBType.DATE,
    time: RoseDBType.TIME_NO_TZ,
    timetz: RoseDBType.TIME_TZ,
    bool: RoseDBType.BOOLEAN,
    blob: RoseDBType.STRING,
    json: RoseDBType.JSON,
};
var LeistungsZurechnungType;
(function (LeistungsZurechnungType) {
    LeistungsZurechnungType["GOZ_UNKNOWN"] = "GU";
    LeistungsZurechnungType["GOZ_V"] = "V";
    LeistungsZurechnungType["GOZ_\u00C4"] = "GAE";
    LeistungsZurechnungType["GOZ_L"] = "L";
    LeistungsZurechnungType["GOZ_A"] = "A";
    LeistungsZurechnungType["GOZ_B"] = "B";
    LeistungsZurechnungType["GOZ_C"] = "C";
    LeistungsZurechnungType["GOZ_D"] = "D";
    LeistungsZurechnungType["GOZ_E"] = "E";
    LeistungsZurechnungType["GOZ_F"] = "F";
    LeistungsZurechnungType["GOZ_G"] = "G";
    LeistungsZurechnungType["GOZ_H"] = "H";
    LeistungsZurechnungType["GOZ_J"] = "J";
    LeistungsZurechnungType["GOZ_K"] = "K";
    LeistungsZurechnungType["BEMA_UNKNOWN"] = "BU";
    LeistungsZurechnungType["BEMA_1"] = "1";
    LeistungsZurechnungType["BEMA_2"] = "2";
    LeistungsZurechnungType["BEMA_3"] = "3";
    LeistungsZurechnungType["BEMA_4"] = "4";
    LeistungsZurechnungType["BEMA_5"] = "5";
    LeistungsZurechnungType["BEMA_\u00C4"] = "BAE";
    LeistungsZurechnungType["BEMA_PAUSCHAL"] = "BP";
    LeistungsZurechnungType["BEMA_UNFALLKASSEBG"] = "BUKBG";
})(LeistungsZurechnungType || (exports.LeistungsZurechnungType = LeistungsZurechnungType = {}));
var KartenStatus;
(function (KartenStatus) {
    KartenStatus[KartenStatus["PRIVAT"] = 1] = "PRIVAT";
    KartenStatus[KartenStatus["KASSE_AKTUELL"] = 2] = "KASSE_AKTUELL";
    KartenStatus[KartenStatus["KASSE_ABGELAUFEN"] = 3] = "KASSE_ABGELAUFEN";
    KartenStatus[KartenStatus["KASSE_INDEFINIT"] = 4] = "KASSE_INDEFINIT";
})(KartenStatus || (exports.KartenStatus = KartenStatus = {}));
var Units;
(function (Units) {
    Units["EURO"] = "\u20AC";
    Units["EUROROUNDED"] = "eur_rounded";
    Units["PROZENTE"] = "%";
    Units["PROZENTEROUNDED"] = "%_rounded";
    Units["TERMINE"] = "T";
    Units["PATIENTEN"] = "P";
    Units["IMPLANTATE"] = "I";
    Units["STUNDEN"] = "H";
    Units["STUNDENROUNDED"] = "h";
    Units["DAYS"] = "D";
    Units["DAYSROUNDED"] = "D_rounded";
    Units["MINUTEN"] = "min";
    Units["EURO_PRO_STUNDE"] = "\u20AC/h";
    Units["EURO_PRO_TERMIN"] = "\u20AC/T";
    Units["EURO_PRO_ZIMMER"] = "\u20AC/Z";
    Units["FLOAT"] = "n";
    Units["INTEGER"] = "int";
    Units["ENTFERNUNG"] = "km";
    Units["TEXT"] = "s";
    Units["DATUM"] = "d";
    Units["TIME"] = "t";
    Units["NONE"] = "";
})(Units || (exports.Units = Units = {}));
var VerrechnungsArtType;
(function (VerrechnungsArtType) {
    VerrechnungsArtType[VerrechnungsArtType["NORMAL"] = 0] = "NORMAL";
    VerrechnungsArtType[VerrechnungsArtType["ERSTATTUNG"] = 1] = "ERSTATTUNG";
    VerrechnungsArtType[VerrechnungsArtType["GUTSCHRIFT"] = 2] = "GUTSCHRIFT";
})(VerrechnungsArtType || (exports.VerrechnungsArtType = VerrechnungsArtType = {}));
var EGKEingabeArtType;
(function (EGKEingabeArtType) {
    EGKEingabeArtType[EGKEingabeArtType["UNKNOWN"] = 0] = "UNKNOWN";
    EGKEingabeArtType[EGKEingabeArtType["KARTENLESEGERAET"] = 1] = "KARTENLESEGERAET";
    EGKEingabeArtType[EGKEingabeArtType["MANUELL"] = 2] = "MANUELL";
    EGKEingabeArtType[EGKEingabeArtType["ANDERERVERSICHERUNGSNACHWEIS1"] = 4] = "ANDERERVERSICHERUNGSNACHWEIS1";
    EGKEingabeArtType[EGKEingabeArtType["ANDERERVERSICHERUNGSNACHWEIS2"] = 5] = "ANDERERVERSICHERUNGSNACHWEIS2";
    EGKEingabeArtType[EGKEingabeArtType["ANDERERVERSICHERUNGSNACHWEIS3"] = 7] = "ANDERERVERSICHERUNGSNACHWEIS3";
})(EGKEingabeArtType || (exports.EGKEingabeArtType = EGKEingabeArtType = {}));
var PAGradType;
(function (PAGradType) {
    PAGradType["UNKNOWN"] = "-";
    PAGradType["A"] = "A";
    PAGradType["B"] = "B";
    PAGradType["C"] = "C";
})(PAGradType || (exports.PAGradType = PAGradType = {}));
var ZAType;
(function (ZAType) {
    ZAType["partner"] = "partner";
    ZAType["angestellt"] = "angestellt";
    ZAType["assistent"] = "assistent";
})(ZAType || (exports.ZAType = ZAType = {}));
var BehandlerTyp;
(function (BehandlerTyp) {
    BehandlerTyp["ZA"] = "za";
    BehandlerTyp["PZR"] = "pzr";
})(BehandlerTyp || (exports.BehandlerTyp = BehandlerTyp = {}));
var PZRType;
(function (PZRType) {
    PZRType["zfa"] = "zfa";
    PZRType["zmpzmf"] = "zmpzmf";
    PZRType["dh"] = "dh";
})(PZRType || (exports.PZRType = PZRType = {}));
var FeiertageTyp;
(function (FeiertageTyp) {
    FeiertageTyp["OSTERN_DIFF"] = "OSTERN_DIFF";
    FeiertageTyp["FESTES_DATUM"] = "FESTES_DATUM";
    FeiertageTyp["WOCHENTAG_NACH_FESTEM_DATUM"] = "WOCHENTAG_NACH_FESTEM_DATUM";
    FeiertageTyp["EINMALIG"] = "EINMALIG";
})(FeiertageTyp || (exports.FeiertageTyp = FeiertageTyp = {}));
var ImportTyp;
(function (ImportTyp) {
    ImportTyp["FULL"] = "full";
    ImportTyp["INCREMENTAL"] = "incremental";
})(ImportTyp || (exports.ImportTyp = ImportTyp = {}));
var WoAnbietenTyp;
(function (WoAnbietenTyp) {
    WoAnbietenTyp["GKV_PKV"] = "GKV_PKV";
    WoAnbietenTyp["PKV"] = "PKV";
    WoAnbietenTyp["GKV"] = "GKV";
})(WoAnbietenTyp || (exports.WoAnbietenTyp = WoAnbietenTyp = {}));
var CharlyChargeFlag;
(function (CharlyChargeFlag) {
    CharlyChargeFlag[CharlyChargeFlag["MAT_VERBRAUCHART_UNBEKANNT"] = 0] = "MAT_VERBRAUCHART_UNBEKANNT";
    CharlyChargeFlag[CharlyChargeFlag["MAT_VERBRAUCH_CHARGE"] = 1] = "MAT_VERBRAUCH_CHARGE";
    CharlyChargeFlag[CharlyChargeFlag["MAT_VERBRAUCH_HALTBAR_BIS"] = 2] = "MAT_VERBRAUCH_HALTBAR_BIS";
    CharlyChargeFlag[CharlyChargeFlag["MAT_VERBRAUCH_PORTIONEN"] = 4] = "MAT_VERBRAUCH_PORTIONEN";
    CharlyChargeFlag[CharlyChargeFlag["MAT_LAGER_PRO_EINHEIT"] = 8] = "MAT_LAGER_PRO_EINHEIT";
    CharlyChargeFlag[CharlyChargeFlag["MAT_CHARGE_RG_DRUCK"] = 16] = "MAT_CHARGE_RG_DRUCK";
    CharlyChargeFlag[CharlyChargeFlag["MAT_HALTBARKEIT_RG_DRUCK"] = 32] = "MAT_HALTBARKEIT_RG_DRUCK";
    CharlyChargeFlag[CharlyChargeFlag["MAT_BESTANDTEILE_RG_DRUCK"] = 64] = "MAT_BESTANDTEILE_RG_DRUCK";
    CharlyChargeFlag[CharlyChargeFlag["MAT_BESTANDFUEHRUNG_LAGER"] = 128] = "MAT_BESTANDFUEHRUNG_LAGER";
    CharlyChargeFlag[CharlyChargeFlag["MAT_KONFORMITAET"] = 256] = "MAT_KONFORMITAET";
    CharlyChargeFlag[CharlyChargeFlag["MAT_GOLDBUCH"] = 512] = "MAT_GOLDBUCH";
    CharlyChargeFlag[CharlyChargeFlag["MAT_TAGESPREIS"] = 1024] = "MAT_TAGESPREIS";
    CharlyChargeFlag[CharlyChargeFlag["MAT_HERSTELLER_RG_DRUCK"] = 2048] = "MAT_HERSTELLER_RG_DRUCK";
    CharlyChargeFlag[CharlyChargeFlag["MAT_NICHTBERECHNEN"] = 4096] = "MAT_NICHTBERECHNEN";
    CharlyChargeFlag[CharlyChargeFlag["MAT_TROTZNICHTBERDRUCK"] = 8192] = "MAT_TROTZNICHTBERDRUCK";
    CharlyChargeFlag[CharlyChargeFlag["MAT_STUECKVERBRAUCH"] = 16384] = "MAT_STUECKVERBRAUCH";
    CharlyChargeFlag[CharlyChargeFlag["MAT_PORTIONSVERBRAUCH"] = 32768] = "MAT_PORTIONSVERBRAUCH";
    CharlyChargeFlag[CharlyChargeFlag["MAT_MENGENVERBRAUCH"] = 65536] = "MAT_MENGENVERBRAUCH";
    CharlyChargeFlag[CharlyChargeFlag["MAT_BC_DRUCK_MATEINGANG"] = 131072] = "MAT_BC_DRUCK_MATEINGANG";
})(CharlyChargeFlag || (exports.CharlyChargeFlag = CharlyChargeFlag = {}));
var TOKEN_PURPOSE;
(function (TOKEN_PURPOSE) {
    TOKEN_PURPOSE["NEW_USER"] = "new-user";
    TOKEN_PURPOSE["USER_INVITATION"] = "user-invitation";
    TOKEN_PURPOSE["USER_TOKEN_FOR_ADMIN"] = "user-token-for-admin";
    TOKEN_PURPOSE["LOGIN"] = "login";
    TOKEN_PURPOSE["PAPO_LOGIN"] = "papo-login";
    TOKEN_PURPOSE["RESET_PASSWORD"] = "reset-password";
    TOKEN_PURPOSE["INTERNAL_COMMUNICATION"] = "internal-communication";
})(TOKEN_PURPOSE || (exports.TOKEN_PURPOSE = TOKEN_PURPOSE = {}));
var PVS_PORT;
(function (PVS_PORT) {
    PVS_PORT[PVS_PORT["CHARLY"] = 5432] = "CHARLY";
    PVS_PORT[PVS_PORT["CHARLY_NEW"] = 5433] = "CHARLY_NEW";
    PVS_PORT[PVS_PORT["Z1"] = 1433] = "Z1";
    PVS_PORT[PVS_PORT["EVIDENT"] = 6430] = "EVIDENT";
})(PVS_PORT || (exports.PVS_PORT = PVS_PORT = {}));
var TabletTaskType;
(function (TabletTaskType) {
    TabletTaskType["EWE_TASK"] = "ITabletTaskInfoEwe";
    TabletTaskType["DOCUMENT_TASK"] = "ITabletTaskInfoDocument";
    TabletTaskType["QR_CODE_TASK"] = "ITabletTaskInfoQrCode";
    TabletTaskType["ANAMNESE_TASK"] = "ITabletTaskInfoAnamnese";
    TabletTaskType["UPLOAD_TASK"] = "ITabletTaskInfoUpload";
})(TabletTaskType || (exports.TabletTaskType = TabletTaskType = {}));
var PAREreignisTyp;
(function (PAREreignisTyp) {
    PAREreignisTyp["UNKNOWN"] = "UNKNOWN";
    PAREreignisTyp["TERMIN_OHNE_LEISTUNGEN"] = "TERMIN_OHNE_LEISTUNGEN";
    PAREreignisTyp["TERMIN_MIT_LEISTUNGEN"] = "TERMIN_MIT_LEISTUNGEN";
    PAREreignisTyp["HKPSTATUS"] = "HKPSTATUS";
    PAREreignisTyp["BEVINTERVALSTART"] = "BEVINTERVALSTART";
    PAREreignisTyp["BEVINTERVALENDE"] = "BEVINTERVALENDE";
    PAREreignisTyp["ENDECPTBEHANDLUNGSZEITMARKER"] = "ENDECPTBEHANDLUNGSZEITMARKER";
    PAREreignisTyp["UPTTERMINZEITMARKERSTART"] = "UPTTERMINZEITMARKERSTART";
    PAREreignisTyp["UPTTERMINZEITMARKERENDE"] = "UPTTERMINZEITMARKERENDE";
    PAREreignisTyp["UPTGMARKER"] = "UPTGMARKER";
    PAREreignisTyp["ENDMARKER"] = "ENDMARKER";
    PAREreignisTyp["MOREUPTS"] = "MOREUPTS";
    PAREreignisTyp["MISSINGPA"] = "MISSINGPA";
    PAREreignisTyp["MISSINGBEV"] = "MISSINGBEV";
    PAREreignisTyp["MISSINGUPT"] = "MISSINGUPT";
    PAREreignisTyp["TODAYMARKER"] = "TODAYMARKER";
    PAREreignisTyp["CPTNURANTRAG"] = "CPTNURANTRAG";
    PAREreignisTyp["HKPVERLAENGERTAM"] = "HKPVERLAENGERTAM";
    PAREreignisTyp["HKPVERLAENGERTBIS"] = "HKPVERLAENGERTBIS";
})(PAREreignisTyp || (exports.PAREreignisTyp = PAREreignisTyp = {}));
var UPTEventType;
(function (UPTEventType) {
    UPTEventType["NONE"] = "NONE";
    UPTEventType["PRIVAT"] = "PRIVAT";
    UPTEventType["TERMIN"] = "TERMIN";
    UPTEventType["TERMINNACHENDE"] = "TERMINNACHENDE";
    UPTEventType["LEISTUNGEN"] = "LEISTUNGEN";
})(UPTEventType || (exports.UPTEventType = UPTEventType = {}));
var PAREreignisPhaseTyp;
(function (PAREreignisPhaseTyp) {
    PAREreignisPhaseTyp["PAR"] = "PAR";
    PAREreignisPhaseTyp["CPT"] = "CPT";
    PAREreignisPhaseTyp["UPT"] = "UPT";
})(PAREreignisPhaseTyp || (exports.PAREreignisPhaseTyp = PAREreignisPhaseTyp = {}));
var PAREreignisFehlerLevel;
(function (PAREreignisFehlerLevel) {
    PAREreignisFehlerLevel["NONE"] = "NONE";
    PAREreignisFehlerLevel["WARNUNG"] = "WARNUNG";
    PAREreignisFehlerLevel["FEHLER"] = "FEHLER";
})(PAREreignisFehlerLevel || (exports.PAREreignisFehlerLevel = PAREreignisFehlerLevel = {}));
var PAREreignisFehlerTyp;
(function (PAREreignisFehlerTyp) {
    PAREreignisFehlerTyp["NONE"] = "NONE";
    PAREreignisFehlerTyp["ABRECHNUNG"] = "ABRECHNUNG";
    PAREreignisFehlerTyp["KEIN_GENEHMIGTER_HKP"] = "TERMINIERUNG_ZUKUNFT";
    PAREreignisFehlerTyp["KEIN_ROENTGEN"] = "KEIN_ROENTGEN";
    PAREreignisFehlerTyp["KEINE_UPT_GEPLANT"] = "KEINE_UPT_GEPLANT";
    PAREreignisFehlerTyp["UPT_FRISTVERLETZUNG"] = "UPT_FRISTVERLETZUNG";
    PAREreignisFehlerTyp["UPT_ENTFALLEN"] = "UPT_ENTFALLEN";
    PAREreignisFehlerTyp["KEIN_BEV_TERMIN"] = "KEIN_BEV_TERMIN";
    PAREreignisFehlerTyp["BEV_FRISTVERLETZUNG"] = "BEV_FRISTVERLETZUNG";
    PAREreignisFehlerTyp["KEINE_BEV_GEPLANT"] = "KEINE_BEV_GEPLANT";
    PAREreignisFehlerTyp["KEIN_PA_TERMIN_GEPLANT"] = "KEIN_PA_TERMIN_GEPLANT";
    PAREreignisFehlerTyp["TERMIN_OHNE_PA_KONFIG"] = "TERMIN_OHNE_PA_KONFIG";
    PAREreignisFehlerTyp["CPT_PLANUNG"] = "CPT_PLANUNG";
    PAREreignisFehlerTyp["UPTG_ERBRINGBAR"] = "UPTG_ERBRINGBAR";
})(PAREreignisFehlerTyp || (exports.PAREreignisFehlerTyp = PAREreignisFehlerTyp = {}));
var PAREreignisGruppeTyp;
(function (PAREreignisGruppeTyp) {
    PAREreignisGruppeTyp["UNKNOWN"] = "UNKNOWN";
    PAREreignisGruppeTyp["VORBEHANDLUNG"] = "VORBEHANDLUNG";
    PAREreignisGruppeTyp["HAUPTBEHANDLUNG"] = "HAUPTBEHANDLUNG";
    PAREreignisGruppeTyp["NACHBEHANDLUNG_BEVA"] = "NACHBEHANDLUNG_BEVA";
    PAREreignisGruppeTyp["REEVALUATION_AIT"] = "REEVALUATION_AIT";
    PAREreignisGruppeTyp["CPT"] = "CPT";
    PAREreignisGruppeTyp["REEVALUATION_CPT"] = "REEVALUATION_CPT";
    PAREreignisGruppeTyp["UPT_I"] = "UPT_I";
    PAREreignisGruppeTyp["UPT_II"] = "UPT_II";
    PAREreignisGruppeTyp["UPT_III"] = "UPT_III";
    PAREreignisGruppeTyp["UPT_IV"] = "UPT_IV";
    PAREreignisGruppeTyp["UPT_V"] = "UPT_V";
    PAREreignisGruppeTyp["UPT_VI"] = "UPT_VI";
    PAREreignisGruppeTyp["UPT_VII"] = "UPT_VII";
    PAREreignisGruppeTyp["UPT_VIII"] = "UPT_VIII";
    PAREreignisGruppeTyp["UPT_IX"] = "UPT_IX";
    PAREreignisGruppeTyp["UPT_X"] = "UPT_X";
})(PAREreignisGruppeTyp || (exports.PAREreignisGruppeTyp = PAREreignisGruppeTyp = {}));
