"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeInSeconds = exports.TimeInMilliseconds = void 0;
// times
var TimeInMilliseconds;
(function (TimeInMilliseconds) {
    TimeInMilliseconds[TimeInMilliseconds["seconds_1"] = 1000] = "seconds_1";
    TimeInMilliseconds[TimeInMilliseconds["seconds_2"] = 2000] = "seconds_2";
    TimeInMilliseconds[TimeInMilliseconds["seconds_3"] = 3000] = "seconds_3";
    TimeInMilliseconds[TimeInMilliseconds["seconds_5"] = 5000] = "seconds_5";
    TimeInMilliseconds[TimeInMilliseconds["seconds_10"] = 10000] = "seconds_10";
    TimeInMilliseconds[TimeInMilliseconds["seconds_15"] = 15000] = "seconds_15";
    TimeInMilliseconds[TimeInMilliseconds["seconds_20"] = 20000] = "seconds_20";
    TimeInMilliseconds[TimeInMilliseconds["seconds_30"] = 30000] = "seconds_30";
    TimeInMilliseconds[TimeInMilliseconds["seconds_45"] = 45000] = "seconds_45";
    TimeInMilliseconds[TimeInMilliseconds["seconds_90"] = 90000] = "seconds_90";
    TimeInMilliseconds[TimeInMilliseconds["minutes_1"] = 60000] = "minutes_1";
    TimeInMilliseconds[TimeInMilliseconds["minutes_2"] = 120000] = "minutes_2";
    TimeInMilliseconds[TimeInMilliseconds["minutes_5"] = 300000] = "minutes_5";
    TimeInMilliseconds[TimeInMilliseconds["minutes_10"] = 600000] = "minutes_10";
    TimeInMilliseconds[TimeInMilliseconds["minutes_15"] = 900000] = "minutes_15";
    TimeInMilliseconds[TimeInMilliseconds["minutes_20"] = 1200000] = "minutes_20";
    TimeInMilliseconds[TimeInMilliseconds["minutes_30"] = 1800000] = "minutes_30";
    TimeInMilliseconds[TimeInMilliseconds["minutes_45"] = 2700000] = "minutes_45";
    TimeInMilliseconds[TimeInMilliseconds["hours_1"] = 3600000] = "hours_1";
    TimeInMilliseconds[TimeInMilliseconds["hours_2"] = 7200000] = "hours_2";
    TimeInMilliseconds[TimeInMilliseconds["hours_3"] = 10800000] = "hours_3";
    TimeInMilliseconds[TimeInMilliseconds["hours_6"] = 21600000] = "hours_6";
    TimeInMilliseconds[TimeInMilliseconds["days_1"] = 86400000] = "days_1";
})(TimeInMilliseconds || (exports.TimeInMilliseconds = TimeInMilliseconds = {}));
var TimeInSeconds;
(function (TimeInSeconds) {
    TimeInSeconds[TimeInSeconds["seconds_10"] = 10] = "seconds_10";
    TimeInSeconds[TimeInSeconds["seconds_30"] = 30] = "seconds_30";
    TimeInSeconds[TimeInSeconds["minutes_1"] = 60] = "minutes_1";
    TimeInSeconds[TimeInSeconds["minutes_5"] = 300] = "minutes_5";
    TimeInSeconds[TimeInSeconds["minutes_10"] = 600] = "minutes_10";
    TimeInSeconds[TimeInSeconds["minutes_20"] = 1200] = "minutes_20";
    TimeInSeconds[TimeInSeconds["minutes_30"] = 1800] = "minutes_30";
    TimeInSeconds[TimeInSeconds["hours_1"] = 3600] = "hours_1";
    TimeInSeconds[TimeInSeconds["hours_5"] = 18000] = "hours_5";
    TimeInSeconds[TimeInSeconds["hours_10"] = 36000] = "hours_10";
    TimeInSeconds[TimeInSeconds["day_1"] = 86400] = "day_1";
})(TimeInSeconds || (exports.TimeInSeconds = TimeInSeconds = {}));
