"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewsTags = exports.ALLDBS = exports.AFTER14DBS = exports.PRIOR14DBS = exports.CLIENT_IMPORT_STATE = exports.IMPORT_STEPS = exports.WorkerStates = void 0;
var WorkerStates;
(function (WorkerStates) {
    WorkerStates["IDLE"] = "IDLE";
    WorkerStates["WORKING"] = "WORKING";
})(WorkerStates || (exports.WorkerStates = WorkerStates = {}));
/**
 * SYNC ORDER WITH ImportSteps array AND DERIVEDDATACALCULATOR !!!!!!!!!!
 */
var IMPORT_STEPS;
(function (IMPORT_STEPS) {
    IMPORT_STEPS[IMPORT_STEPS["NOT_RUNNING"] = 0] = "NOT_RUNNING";
    IMPORT_STEPS[IMPORT_STEPS["STARTED"] = 1] = "STARTED";
    IMPORT_STEPS[IMPORT_STEPS["RAW_DATA_RETRIVAL"] = 2] = "RAW_DATA_RETRIVAL";
    IMPORT_STEPS[IMPORT_STEPS["DOCTOSYNC_DATA_RETRIVAL"] = 3] = "DOCTOSYNC_DATA_RETRIVAL";
    IMPORT_STEPS[IMPORT_STEPS["RAW_DATA_INDEXING"] = 4] = "RAW_DATA_INDEXING";
    IMPORT_STEPS[IMPORT_STEPS["RAW_DATA_TRANSFORMATION"] = 5] = "RAW_DATA_TRANSFORMATION";
    IMPORT_STEPS[IMPORT_STEPS["DELETIONS"] = 6] = "DELETIONS";
    IMPORT_STEPS[IMPORT_STEPS["LEISTUNGSBLOECKE"] = 7] = "LEISTUNGSBLOECKE";
    IMPORT_STEPS[IMPORT_STEPS["KONTROLLIERT"] = 8] = "KONTROLLIERT";
    IMPORT_STEPS[IMPORT_STEPS["PSI"] = 9] = "PSI";
    IMPORT_STEPS[IMPORT_STEPS["TERMINE"] = 10] = "TERMINE";
    IMPORT_STEPS[IMPORT_STEPS["HKP"] = 11] = "HKP";
    IMPORT_STEPS[IMPORT_STEPS["PATIENTEN"] = 12] = "PATIENTEN";
    IMPORT_STEPS[IMPORT_STEPS["RECHNUNGEN"] = 13] = "RECHNUNGEN";
    IMPORT_STEPS[IMPORT_STEPS["UMSATZ"] = 14] = "UMSATZ";
    IMPORT_STEPS[IMPORT_STEPS["ABRECHNUNG"] = 15] = "ABRECHNUNG";
    IMPORT_STEPS[IMPORT_STEPS["KZVABRECHNUNG"] = 16] = "KZVABRECHNUNG";
    IMPORT_STEPS[IMPORT_STEPS["PA"] = 17] = "PA";
    IMPORT_STEPS[IMPORT_STEPS["HEUTE"] = 18] = "HEUTE";
    IMPORT_STEPS[IMPORT_STEPS["TERMINBUCH"] = 19] = "TERMINBUCH";
    IMPORT_STEPS[IMPORT_STEPS["BENCHMARK"] = 20] = "BENCHMARK";
    IMPORT_STEPS[IMPORT_STEPS["FINISHED"] = 21] = "FINISHED";
})(IMPORT_STEPS || (exports.IMPORT_STEPS = IMPORT_STEPS = {}));
var CLIENT_IMPORT_STATE;
(function (CLIENT_IMPORT_STATE) {
    CLIENT_IMPORT_STATE["SELECTED"] = "SELECTED";
    CLIENT_IMPORT_STATE["ASSIGNING"] = "ASSIGNING";
    CLIENT_IMPORT_STATE["RAWDATA"] = "RAWDATA";
    CLIENT_IMPORT_STATE["DERIVEDDATA"] = "DERIVEDDATA";
    CLIENT_IMPORT_STATE["FINISHING"] = "FINISHING";
})(CLIENT_IMPORT_STATE || (exports.CLIENT_IMPORT_STATE = CLIENT_IMPORT_STATE = {}));
exports.PRIOR14DBS = ['9', '10', '11', '12', '13'];
exports.AFTER14DBS = ['14', '15', '16'];
exports.ALLDBS = [...exports.PRIOR14DBS, ...exports.AFTER14DBS];
var NewsTags;
(function (NewsTags) {
    NewsTags["BLOG"] = "blog";
    NewsTags["METRICS"] = "metrics";
    NewsTags["R4C"] = "r4c";
})(NewsTags || (exports.NewsTags = NewsTags = {}));
