"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CharlyAnalyticsLicenceStatus = exports.MwStQuelleType = void 0;
var MwStQuelleType;
(function (MwStQuelleType) {
    MwStQuelleType["H"] = "H";
    MwStQuelleType["EL"] = "EL";
    MwStQuelleType["ELM"] = "ELM";
    MwStQuelleType["M"] = "M";
    MwStQuelleType["FL"] = "FL";
})(MwStQuelleType || (exports.MwStQuelleType = MwStQuelleType = {}));
var CharlyAnalyticsLicenceStatus;
(function (CharlyAnalyticsLicenceStatus) {
    CharlyAnalyticsLicenceStatus["OK"] = "ok";
    CharlyAnalyticsLicenceStatus["NOK"] = "nok";
    CharlyAnalyticsLicenceStatus["DELETE"] = "delete";
})(CharlyAnalyticsLicenceStatus || (exports.CharlyAnalyticsLicenceStatus = CharlyAnalyticsLicenceStatus = {}));
