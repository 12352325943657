"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.R4C_NEW_FOR_CLIENT = exports.R4C_AVAILABLE_FOR_CLIENT = exports.FILE_UPLOAD_RESPONSE = exports.TABLET_CONNECTION_CHANGED_EVENT = exports.CharlyPraxisDatenRequestMessage = exports.AnamneseInfosRequestMessage = exports.CharlyPatientinfoRequestMessage = exports.AblageReferencedFileRequestMessage = exports.StartR4cTaskMessage = exports.QueryCharlySqlMessage = exports.ProxyCharlyRequestsMessage = exports.AblageFileRequestMessage = exports.ClientDataRequestMessage = exports.SaveAnamneseInfosMessage = exports.RestoreCharlyTemplateMessage = exports.OverrideCharlyTemplateMessage = exports.DocumentSettingsRequestMessage = exports.FileListingRequestMessage = exports.UpdateDocumentLicenceInfo = exports.CharlyCreateInfoEntryMessage = exports.CharlyFileUploadMessage = exports.TabletsUpdateMessage = exports.PendingUploadStatusMessage = exports.SendPraxisDataMessage = exports.UpdateNachrichtenR4CMessage = exports.UpdateInitDataR4CMessage = exports.ReloadR4CMessage = exports.PatientUpdateMessage = exports.TabletCancelTaskMessage = exports.TabletTaskMessage = exports.TabletNotRegisteredMessage = exports.TabletSetPraxisInfoMessage = exports.TabletRenameMessage = exports.TabletInitMessage = exports.CharlyFileUploadResponseMessage = exports.R4CInitMessage = exports.BaseSocketMessage = exports.SOCKETMESSAGES = void 0;
// Socket Messages from Clients (r4c & tablet) to r4c-Server
var SOCKETMESSAGES;
(function (SOCKETMESSAGES) {
    SOCKETMESSAGES["CANCEL_TASK"] = "CANCEL_TASK";
    SOCKETMESSAGES["CHARLY_CREATE_INFOENTRY"] = "CHARLY_CREATE_INFOENTRY";
    SOCKETMESSAGES["CHARLY_FILE_UPLOAD_RESPONSE"] = "CHARLY_FILE_UPLOAD_RESPONSE";
    SOCKETMESSAGES["CHARLYFILE_UPLOAD"] = "CHARLYFILE_UPLOAD";
    SOCKETMESSAGES["TABLET_TASK"] = "TABLET_TASK";
    SOCKETMESSAGES["DOCUMENT_TASK"] = "DOCUMENT_TASK";
    SOCKETMESSAGES["INIT_TABLET"] = "INIT_TABLET";
    SOCKETMESSAGES["INIT_R4C"] = "INIT_R4C";
    SOCKETMESSAGES["PATIENT_UPDATE"] = "PATIENT_UPDATE";
    SOCKETMESSAGES["PENDING_UPLOADSTATUS_UPDATE"] = "PENDING_UPLOADSTATUS_UPDATE";
    SOCKETMESSAGES["RELOAD_R4C"] = "RELOAD_R4C";
    SOCKETMESSAGES["REQUEST_CLIENT_DATA"] = "REQUEST_CLIENT_DATA";
    SOCKETMESSAGES["REQUEST_FILE_LISTING"] = "REQUEST_FILE_LISTING";
    SOCKETMESSAGES["REQUEST_DOCUMENT_SETTINGS"] = "REQUEST_DOCUMENT_SETTINGS";
    SOCKETMESSAGES["SAVE_ANAMNESE_INFOS"] = "SAVE_ANAMNESE_INFOS";
    SOCKETMESSAGES["OVERRIDE_CHARLY_TEMPLATE"] = "OVERRIDE_CHARLY_TEMPLATE";
    SOCKETMESSAGES["RESTORE_CHARLY_TEMPLATE"] = "RESTORE_CHARLY_TEMPLATE";
    SOCKETMESSAGES["REQUEST_ABLAGE_FILE"] = "REQUEST_ABLAGE_FILE";
    SOCKETMESSAGES["REQUEST_ABLAGE_REFERECED_FILES"] = "REQUEST_ABLAGE_REFERECED_FILES";
    SOCKETMESSAGES["REQUEST_PATIENTINFO"] = "REQUEST_PATIENTINFO";
    SOCKETMESSAGES["REQUEST_ANAMNESE_INFOS"] = "REQUEST_ANAMNESE_INFOS";
    SOCKETMESSAGES["REQUEST_PRAXISDATA"] = "REQUEST_PRAXISDATA";
    SOCKETMESSAGES["SEND_PRAXISDATA"] = "SEND_PRAXISDATA";
    SOCKETMESSAGES["TABLET_NOT_REGISTERED"] = "TABLET_NOT_REGISTERED";
    SOCKETMESSAGES["TABLET_RENAME"] = "TABLET_RENAME";
    SOCKETMESSAGES["TABLET_SET_PRAXIS_INFO"] = "TABLET_SET_PRAXIS_INFO";
    SOCKETMESSAGES["TABLETS_UPDATE"] = "TABLETS_UPDATE";
    SOCKETMESSAGES["UPDATE_INITDATA_R4C"] = "UPDATE_INITDATA_R4C";
    SOCKETMESSAGES["UPDATE_NACHRICHTEN_R4C"] = "UPDATE_NACHRICHTEN_R4C";
    SOCKETMESSAGES["UPDATE_DOCUMENT_LICENCE_INFO"] = "UPDATE_DOCUMENT_LICENCE_INFO";
    SOCKETMESSAGES["PROXY_CHARLY_REQUESTS"] = "PROXY_CHARLY_REQUESTS";
    SOCKETMESSAGES["QUERY_CHARLY_SQL"] = "QUERY_CHARLY_SQL";
    SOCKETMESSAGES["START_R4C_TASK"] = "START_R4C_TASK";
})(SOCKETMESSAGES || (exports.SOCKETMESSAGES = SOCKETMESSAGES = {}));
class BaseSocketMessage {
    constructor(type) {
        this.type = type;
    }
}
exports.BaseSocketMessage = BaseSocketMessage;
// R4C --> Server Messages
class R4CInitMessage extends BaseSocketMessage {
    constructor(cid, ctxid, computer, user, uuid, versions, userAgent, started, isAdminHost = false) {
        super(SOCKETMESSAGES.INIT_R4C);
        this.cid = cid;
        this.ctxid = ctxid;
        this.computer = computer;
        this.user = user;
        this.uuid = uuid;
        this.versions = versions;
        this.userAgent = userAgent;
        this.started = started;
        this.isAdminHost = isAdminHost;
    }
}
exports.R4CInitMessage = R4CInitMessage;
class CharlyFileUploadResponseMessage extends BaseSocketMessage {
    constructor(id, success, error, charlyFilePath) {
        super(SOCKETMESSAGES.CHARLY_FILE_UPLOAD_RESPONSE);
        this.id = id;
        this.success = success;
        this.error = error;
        this.charlyFilePath = charlyFilePath;
    }
}
exports.CharlyFileUploadResponseMessage = CharlyFileUploadResponseMessage;
// Tablet --> Server Messages
class TabletInitMessage extends BaseSocketMessage {
    constructor(name, token, taskId, versions, userAgent) {
        super(SOCKETMESSAGES.INIT_TABLET);
        this.name = name;
        this.token = token;
        this.taskId = taskId;
        this.versions = versions;
        this.userAgent = userAgent;
    }
}
exports.TabletInitMessage = TabletInitMessage;
// Socket Messages from Server to Tablet
class TabletRenameMessage extends BaseSocketMessage {
    constructor(name) {
        super(SOCKETMESSAGES.TABLET_RENAME);
        this.name = name;
    }
}
exports.TabletRenameMessage = TabletRenameMessage;
class TabletSetPraxisInfoMessage extends BaseSocketMessage {
    constructor(brand, availableRzs, betaFlagEnabled) {
        super(SOCKETMESSAGES.TABLET_SET_PRAXIS_INFO);
        this.brand = brand;
        this.availableRzs = availableRzs;
        this.betaFlagEnabled = betaFlagEnabled;
    }
}
exports.TabletSetPraxisInfoMessage = TabletSetPraxisInfoMessage;
class TabletNotRegisteredMessage extends BaseSocketMessage {
    constructor() {
        super(SOCKETMESSAGES.TABLET_NOT_REGISTERED);
    }
}
exports.TabletNotRegisteredMessage = TabletNotRegisteredMessage;
class TabletTaskMessage extends BaseSocketMessage {
    constructor(statusMeta) {
        super(SOCKETMESSAGES.TABLET_TASK);
        this.statusMeta = statusMeta;
    }
}
exports.TabletTaskMessage = TabletTaskMessage;
class TabletCancelTaskMessage extends BaseSocketMessage {
    constructor() {
        super(SOCKETMESSAGES.CANCEL_TASK);
    }
}
exports.TabletCancelTaskMessage = TabletCancelTaskMessage;
// SocketMessage from Server to R4C
class PatientUpdateMessage extends BaseSocketMessage {
    constructor(patid) {
        super(SOCKETMESSAGES.PATIENT_UPDATE);
        this.patid = patid;
        this.patientData = null;
    }
}
exports.PatientUpdateMessage = PatientUpdateMessage;
class ReloadR4CMessage extends BaseSocketMessage {
    constructor() {
        super(SOCKETMESSAGES.RELOAD_R4C);
        this.message = 'initR4c'; // for compat
    }
}
exports.ReloadR4CMessage = ReloadR4CMessage;
class UpdateInitDataR4CMessage extends BaseSocketMessage {
    constructor(initData) {
        super(SOCKETMESSAGES.UPDATE_INITDATA_R4C);
        this.initData = initData;
    }
}
exports.UpdateInitDataR4CMessage = UpdateInitDataR4CMessage;
class UpdateNachrichtenR4CMessage extends BaseSocketMessage {
    constructor(nachrichten) {
        super(SOCKETMESSAGES.UPDATE_NACHRICHTEN_R4C);
        this.nachrichten = nachrichten;
    }
}
exports.UpdateNachrichtenR4CMessage = UpdateNachrichtenR4CMessage;
class SendPraxisDataMessage extends BaseSocketMessage {
    constructor() {
        super(SOCKETMESSAGES.SEND_PRAXISDATA);
    }
}
exports.SendPraxisDataMessage = SendPraxisDataMessage;
class PendingUploadStatusMessage extends BaseSocketMessage {
    constructor(uploadTasks) {
        super(SOCKETMESSAGES.PENDING_UPLOADSTATUS_UPDATE);
        this.uploadTasks = uploadTasks;
    }
}
exports.PendingUploadStatusMessage = PendingUploadStatusMessage;
class TabletsUpdateMessage extends BaseSocketMessage {
    constructor(tablets) {
        super(SOCKETMESSAGES.TABLETS_UPDATE);
        this.tablets = tablets;
    }
}
exports.TabletsUpdateMessage = TabletsUpdateMessage;
class CharlyFileUploadMessage extends BaseSocketMessage {
    constructor(uploadTask) {
        super(SOCKETMESSAGES.CHARLYFILE_UPLOAD);
        this.uploadTask = uploadTask;
    }
}
exports.CharlyFileUploadMessage = CharlyFileUploadMessage;
class CharlyCreateInfoEntryMessage extends BaseSocketMessage {
    constructor(patid, filename, info, date) {
        super(SOCKETMESSAGES.CHARLY_CREATE_INFOENTRY);
        this.patid = patid;
        this.filename = filename;
        this.info = info;
        this.date = date;
    }
}
exports.CharlyCreateInfoEntryMessage = CharlyCreateInfoEntryMessage;
class UpdateDocumentLicenceInfo extends BaseSocketMessage {
    constructor(licenceInfo) {
        super(SOCKETMESSAGES.UPDATE_DOCUMENT_LICENCE_INFO);
        this.licenceInfo = licenceInfo;
    }
}
exports.UpdateDocumentLicenceInfo = UpdateDocumentLicenceInfo;
class FileListingRequestMessage extends BaseSocketMessage {
    constructor(patid) {
        super(SOCKETMESSAGES.REQUEST_FILE_LISTING);
        this.patid = patid;
    }
}
exports.FileListingRequestMessage = FileListingRequestMessage;
class DocumentSettingsRequestMessage extends BaseSocketMessage {
    constructor(file) {
        super(SOCKETMESSAGES.REQUEST_DOCUMENT_SETTINGS);
        this.file = file;
    }
}
exports.DocumentSettingsRequestMessage = DocumentSettingsRequestMessage;
class OverrideCharlyTemplateMessage extends BaseSocketMessage {
    constructor(group, file, rtfText) {
        super(SOCKETMESSAGES.OVERRIDE_CHARLY_TEMPLATE);
        this.group = group;
        this.file = file;
        this.rtfText = rtfText;
    }
}
exports.OverrideCharlyTemplateMessage = OverrideCharlyTemplateMessage;
class RestoreCharlyTemplateMessage extends BaseSocketMessage {
    constructor(group, file) {
        super(SOCKETMESSAGES.RESTORE_CHARLY_TEMPLATE);
        this.group = group;
        this.file = file;
    }
}
exports.RestoreCharlyTemplateMessage = RestoreCharlyTemplateMessage;
class SaveAnamneseInfosMessage extends BaseSocketMessage {
    constructor(details, patid) {
        super(SOCKETMESSAGES.SAVE_ANAMNESE_INFOS);
        this.details = details;
        this.patid = patid;
    }
}
exports.SaveAnamneseInfosMessage = SaveAnamneseInfosMessage;
class ClientDataRequestMessage extends BaseSocketMessage {
    constructor(cid) {
        super(SOCKETMESSAGES.REQUEST_CLIENT_DATA);
        this.cid = cid;
    }
}
exports.ClientDataRequestMessage = ClientDataRequestMessage;
class AblageFileRequestMessage extends BaseSocketMessage {
    constructor(cid, patId, ablage, file) {
        super(SOCKETMESSAGES.REQUEST_ABLAGE_FILE);
        this.cid = cid;
        this.patId = patId;
        this.ablage = ablage;
        this.file = file;
    }
}
exports.AblageFileRequestMessage = AblageFileRequestMessage;
class ProxyCharlyRequestsMessage extends BaseSocketMessage {
    constructor(requests) {
        super(SOCKETMESSAGES.PROXY_CHARLY_REQUESTS);
        this.requests = requests;
    }
}
exports.ProxyCharlyRequestsMessage = ProxyCharlyRequestsMessage;
class QueryCharlySqlMessage extends BaseSocketMessage {
    constructor(request) {
        super(SOCKETMESSAGES.QUERY_CHARLY_SQL);
        this.request = request;
    }
}
exports.QueryCharlySqlMessage = QueryCharlySqlMessage;
class StartR4cTaskMessage extends BaseSocketMessage {
    constructor(request) {
        super(SOCKETMESSAGES.START_R4C_TASK);
        this.request = request;
    }
}
exports.StartR4cTaskMessage = StartR4cTaskMessage;
class AblageReferencedFileRequestMessage extends BaseSocketMessage {
    constructor(referencedFiles, cid, patId, ablage, sourceFile) {
        super(SOCKETMESSAGES.REQUEST_ABLAGE_REFERECED_FILES);
        this.referencedFiles = referencedFiles;
        this.cid = cid;
        this.patId = patId;
        this.ablage = ablage;
        this.sourceFile = sourceFile;
    }
}
exports.AblageReferencedFileRequestMessage = AblageReferencedFileRequestMessage;
class CharlyPatientinfoRequestMessage extends BaseSocketMessage {
    constructor(patid) {
        super(SOCKETMESSAGES.REQUEST_PATIENTINFO);
        this.patid = patid;
    }
}
exports.CharlyPatientinfoRequestMessage = CharlyPatientinfoRequestMessage;
class AnamneseInfosRequestMessage extends BaseSocketMessage {
    constructor(patid) {
        super(SOCKETMESSAGES.REQUEST_ANAMNESE_INFOS);
        this.patid = patid;
    }
}
exports.AnamneseInfosRequestMessage = AnamneseInfosRequestMessage;
class CharlyPraxisDatenRequestMessage extends BaseSocketMessage {
    constructor() {
        super(SOCKETMESSAGES.REQUEST_PRAXISDATA);
    }
}
exports.CharlyPraxisDatenRequestMessage = CharlyPraxisDatenRequestMessage;
/**
 * this event fires when an tablet connects or disconnectes; passes the cid
 */
exports.TABLET_CONNECTION_CHANGED_EVENT = 'tabletConnectionStatusChanged';
/**
 * this event fires when a r4c responds to a file upload request; passes the response message
 */
exports.FILE_UPLOAD_RESPONSE = 'fileUploadResponse';
/**
 * this event fires when a r4c connects for a client for whom there was previously no r4c connected; passes the cid
 */
exports.R4C_AVAILABLE_FOR_CLIENT = 'r4cAvailableForClient';
/**
 * this event fires when a r4c connects for a client
 */
exports.R4C_NEW_FOR_CLIENT = 'r4cNewForClient';
