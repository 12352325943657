"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STRIPE_KUNDENNAME_KEY = exports.STRIPE_KUNDENID_KEY = exports.STRIPE_CHARLY_ANALYTICS_NAME_KEY = exports.STRIPE_CHARLY_ANALYTICS_SERIALNR_KEY = exports.STRIPE_CHARLY_ANALYTICS_LIZENZNR_KEY = exports.STRIPE_CHARLY_ANALYTICS_KUNDENID_KEY = exports.demoUserEmail = exports.ERRORBLOCKERID = exports.CACHEBLOCKERID = exports.PARAMS = exports.QUERYSTRINGS = exports.HEADERVALUES = exports.HEADERS = void 0;
var HEADERS;
(function (HEADERS) {
    HEADERS["ROSEVERSION"] = "rose-version";
    HEADERS["ROSECLIENTIP"] = "rose-client-ip";
    HEADERS["ROSECLIENTID"] = "rose-client-id";
    HEADERS["ROSETRACEID"] = "rose-trace-id";
    HEADERS["ROSETASKID"] = "rose-task-id";
    HEADERS["ROSER4CUUID"] = "rose-r4c-uuid";
    HEADERS["ROSEREQUESTID"] = "rose-request-id";
    HEADERS["ROSEPREFFEREDR4CINSTANCEUUID"] = "rose-preffered-r4c-instance-uuid";
    HEADERS["ROSECACHECONTROL"] = "rose-cache-control";
})(HEADERS || (exports.HEADERS = HEADERS = {}));
var HEADERVALUES;
(function (HEADERVALUES) {
    HEADERVALUES["NOCACHE"] = "no-cache";
})(HEADERVALUES || (exports.HEADERVALUES = HEADERVALUES = {}));
var QUERYSTRINGS;
(function (QUERYSTRINGS) {
    QUERYSTRINGS["NONOTIFY"] = "nonotify";
})(QUERYSTRINGS || (exports.QUERYSTRINGS = QUERYSTRINGS = {}));
var PARAMS;
(function (PARAMS) {
    PARAMS["PATEXTRAPAGEFIELDS"] = "patextrapagefields";
    PARAMS["STICHWORTE"] = "stichworte";
    PARAMS["STERNWERTE"] = "sternwerte";
    PARAMS["SCHNITTSTELLEN"] = "schnittstellen";
    PARAMS["PVSVERSION"] = "pvsversion";
    PARAMS["DBVERSION"] = "dbversion";
    PARAMS["ARBEITSPLAETZE"] = "arbeitsplaetze";
    PARAMS["PRAXISNAME"] = "praxisname";
    PARAMS["LIZENZ"] = "lizenz";
    PARAMS["LABORBEL"] = "laborbel";
    PARAMS["KZV"] = "kzv";
    PARAMS["MULTIRECALL"] = "multirecall";
})(PARAMS || (exports.PARAMS = PARAMS = {}));
exports.CACHEBLOCKERID = 'CACHEBLOCKER';
exports.ERRORBLOCKERID = 'ERRORBLOCKER';
exports.demoUserEmail = 'demo@rose.dental';
exports.STRIPE_CHARLY_ANALYTICS_KUNDENID_KEY = 'Kunden-ID';
exports.STRIPE_CHARLY_ANALYTICS_LIZENZNR_KEY = 'Lizenz-Nr.';
exports.STRIPE_CHARLY_ANALYTICS_SERIALNR_KEY = 'Serien-Nr.';
exports.STRIPE_CHARLY_ANALYTICS_NAME_KEY = 'Name';
exports.STRIPE_KUNDENID_KEY = 'cid';
exports.STRIPE_KUNDENNAME_KEY = 'praxisname';
