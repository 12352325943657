"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BenchmarkMonatsType = exports.BenchmarkBenchmarkType = exports.BenchmarkEntwicklungType = exports.BenchmarkAnalysisType = exports.BenchmarkParts = exports.emptyBenchmark = exports.BenchmarkConfigUnitType = exports.BenchmarkConfigProLevelType = exports.IBenchmarkConfigTrendType = exports.getTrendInfo = void 0;
function getTrendInfo(change, trendType) {
    return {
        trendPositive: (change > 0 && trendType === IBenchmarkConfigTrendType.biggerisbetter) ||
            (change < 0 && trendType === IBenchmarkConfigTrendType.smallerisbetter),
        trendNegative: (change < 0 && trendType === IBenchmarkConfigTrendType.biggerisbetter) ||
            (change > 0 && trendType === IBenchmarkConfigTrendType.smallerisbetter),
    };
}
exports.getTrendInfo = getTrendInfo;
var IBenchmarkConfigTrendType;
(function (IBenchmarkConfigTrendType) {
    IBenchmarkConfigTrendType["smallerisbetter"] = "smallerisbetter";
    IBenchmarkConfigTrendType["biggerisbetter"] = "biggerisbetter";
    IBenchmarkConfigTrendType["none"] = "none";
    IBenchmarkConfigTrendType["nonebutabsolut"] = "nonebutabsolut";
})(IBenchmarkConfigTrendType || (exports.IBenchmarkConfigTrendType = IBenchmarkConfigTrendType = {}));
var BenchmarkConfigProLevelType;
(function (BenchmarkConfigProLevelType) {
    BenchmarkConfigProLevelType[BenchmarkConfigProLevelType["NEWBIE"] = 0] = "NEWBIE";
    BenchmarkConfigProLevelType[BenchmarkConfigProLevelType["APPRENTICE"] = 10] = "APPRENTICE";
    BenchmarkConfigProLevelType[BenchmarkConfigProLevelType["MASTER"] = 20] = "MASTER";
})(BenchmarkConfigProLevelType || (exports.BenchmarkConfigProLevelType = BenchmarkConfigProLevelType = {}));
var BenchmarkConfigUnitType;
(function (BenchmarkConfigUnitType) {
    BenchmarkConfigUnitType["NONE"] = "";
    BenchmarkConfigUnitType["PATIENTEN"] = "PATIENTEN";
    BenchmarkConfigUnitType["TERMINE"] = "TERMINE";
    BenchmarkConfigUnitType["EURO"] = "EURO";
    BenchmarkConfigUnitType["PERCENT"] = "PERCENT";
    BenchmarkConfigUnitType["HKP"] = "HKP";
    BenchmarkConfigUnitType["HOURSMINUTES"] = "HOURSMINUTES";
    BenchmarkConfigUnitType["HOURS"] = "HOURS";
    BenchmarkConfigUnitType["DAYS"] = "DAYS";
    BenchmarkConfigUnitType["MONTHS"] = "MONTHS";
    BenchmarkConfigUnitType["YEARS"] = "YEARS";
    BenchmarkConfigUnitType["MINUTES"] = "MINUTES";
    BenchmarkConfigUnitType["FUELLUNGEN"] = "FUELLUNGEN";
    BenchmarkConfigUnitType["FUELLUNGENPROPATIENT"] = "FUELLUNGENPROPATIENT";
    BenchmarkConfigUnitType["IMPLANTATE"] = "IMPLANTATE";
    BenchmarkConfigUnitType["FAKTOR"] = "FAKTOR";
})(BenchmarkConfigUnitType || (exports.BenchmarkConfigUnitType = BenchmarkConfigUnitType = {}));
function emptyBenchmark() {
    let emptyHkpDetail = {
        gesamt: { ids: [], count: 0, betrag: 0 },
        eingabe: { ids: [], count: 0, betrag: 0 },
        erstellt: { ids: [], count: 0, betrag: 0 },
        abgelehnt: { ids: [], count: 0, betrag: 0 },
        genehmigt: { ids: [], count: 0, betrag: 0 },
        verworfen: { ids: [], count: 0, betrag: 0 },
        durchgefuehrt: { ids: [], count: 0, betrag: 0 },
    };
    return {
        createdAt: new Date(),
        tag: new Date(),
        monat: 0,
        jahr: 0,
        behandler: '',
        behandlerIds: [],
        team: '',
        anzahlbehandler: 0,
        // terminzeiten
        tzAvgBehandlung: 0,
        tzAvgBehandlungszimmer: 0,
        tzAvgTermin: 0,
        tzAvgWartezimmer: 0,
        tzMedianBehandlung: 0,
        tzMedianBehandlungszimmer: 0,
        tzMedianTermin: 0,
        tzMedianWartezimmer: 0,
        tzAvgDiffPlanRealStart: 0,
        tzMedianDiffPlanRealStart: 0,
        // umsatz
        honorar: 0,
        honorarbema: 0,
        honorarerstattung: 0,
        honorargoz: 0,
        honorargozkasse: 0,
        honorargozprivat: 0,
        material: 0,
        eigenlaborleistungen: 0,
        eigenlabormaterial: 0,
        fremdlabor: 0,
        // leistungen
        leistungenGOZA: 0,
        leistungenGOZB: 0,
        leistungenGOZC: 0,
        leistungenGOZD: 0,
        leistungenGOZE: 0,
        leistungenGOZF: 0,
        leistungenGOZG: 0,
        leistungenGOZH: 0,
        leistungenGOZJ: 0,
        leistungenGOZK: 0,
        leistungenGOZL: 0,
        leistungenGOZGAE: 0,
        leistungenBEMAGesamt: 0,
        leistungenGOZGesamt: 0,
        leistungenGesamt: 0,
        // patienten
        alter: 0,
        alterNull: 0,
        altersPyramide: {},
        patienten: 0,
        patientenMann: 0,
        patientenFrau: 0,
        patientenDivers: 0,
        patientenGeschlechtNull: 0,
        patientenKind: 0,
        patientenJugendlich: 0,
        patientenErwachsen: 0,
        patientenNeu: 0,
        patientenPrivat: 0,
        patientenKasse: 0,
        patientenKassePrivatNull: 0,
        patientenPa: 0,
        patientenPaLetzte24Monate: 0,
        patientenPaJemals: 0,
        patientenPzrErwachsen: 0,
        patientenPzrKinder: 0,
        patientenPzrQuoteErwachsen: 0,
        patientenPzrQuoteKinder: 0,
        patientenMitRecall: 0,
        patientenMitPsiBefund: 0,
        patientenMitPsiBefundKinder: 0,
        patientenMitPsiAbrechnung: 0,
        patientenMitPsiAbrechnungKinder: 0,
        patientenMitSchlechtemPsiBefund: 0,
        patientenCompliance: 0,
        alterNullIds: [],
        patientenIds: [],
        patientenMannIds: [],
        patientenFrauIds: [],
        patientenDiversIds: [],
        patientenGeschlechtNullIds: [],
        patientenKindIds: [],
        patientenJugendlichIds: [],
        patientenErwachsenIds: [],
        patientenNeuIds: [],
        patientenPrivatIds: [],
        patientenKasseIds: [],
        patientenKassePrivatNullIds: [],
        patientenPaIds: [],
        patientenPaLetzte24MonateIds: [],
        patientenPaJemalsIds: [],
        patientenPzrErwachsenIds: [],
        patientenPzrKinderIds: [],
        patientenPzrQuoteErwachsenIds: [],
        patientenPzrQuoteKinderIds: [],
        patientenMitRecallIds: [],
        patientenOhneTerminEntlassenIds: [],
        patientenMitPsiBefundIds: [],
        patientenMitPsiBefundKinderIds: [],
        patientenMitSchlechtemPsiBefundIds: [],
        patientenMitPsiAbrechnungIds: [],
        patientenMitPsiAbrechnungKinderIds: [],
        // behandlungs zeiten: behandler
        bzB1: 0,
        bzB2a: 0,
        bzB2b: 0,
        bzB2c: 0,
        bzB2d: 0,
        bzB3a: 0,
        bzB3b: 0,
        bzB3c: 0,
        bzB3d: 0,
        bzB3e: 0,
        bzB3f: 0,
        bzB4a: 0,
        bzB4b: 0,
        bzB4c: 0,
        bzB4d: 0,
        // behandlungs zeiten: terminbehandler
        bzTB6a: 0,
        bzTB6b: 0,
        bzTB6c: 0,
        bzTB6d: 0,
        bzTB6e: 0,
        // termine
        termine: 0,
        termineNichtErschienen: 0,
        termineWahrgenommen: 0,
        termineAbgesagt: 0,
        termineNichtErschienenUndAbgesagt: 0,
        termineOK: 0,
        termineOhneStatus: 0,
        termineStatusFehler: 0,
        termineKontrolliertBehandler: 0,
        termineKontrolliertAbrechnung: 0,
        termineKontrolliertAlle: 0,
        // hkp
        hkp: emptyHkpDetail,
        hkpPrivat: emptyHkpDetail,
        hkpPrivatAllgemein: emptyHkpDetail,
        hkpPrivatKBrFAL: emptyHkpDetail,
        hkpPrivatPA: emptyHkpDetail,
        hkpPrivatImpla: emptyHkpDetail,
        hkpPrivatZE: emptyHkpDetail,
        hkpPrivatKFO: emptyHkpDetail,
        hkpKasse: emptyHkpDetail,
        hkpKasseZE: emptyHkpDetail,
        hkpKasseKFO: emptyHkpDetail,
        hkpKassePA: emptyHkpDetail,
        hkpKasseKBrFAL: emptyHkpDetail,
        hkpTageBisGenehmigt: 0,
        hkpTageBisDurchgefuehrt: 0,
        hkpTageErstelltBisDurchgefuehrt: 0,
        tbAngebotGesamt: 0,
        tbAngebotGesamtStattgefunden: 0,
    };
}
exports.emptyBenchmark = emptyBenchmark;
var BenchmarkParts;
(function (BenchmarkParts) {
    BenchmarkParts["PATIENTEN"] = "patientenTab";
    BenchmarkParts["TERMINE"] = "termineTab";
    BenchmarkParts["UMSATZ"] = "umsatzTab";
    BenchmarkParts["HKPS"] = "hkpTab";
    BenchmarkParts["BEHANDLUNGEN"] = "behandlungenTab";
    BenchmarkParts["DETAILS"] = "details";
})(BenchmarkParts || (exports.BenchmarkParts = BenchmarkParts = {}));
var BenchmarkAnalysisType;
(function (BenchmarkAnalysisType) {
    BenchmarkAnalysisType["ENTWICKLUNG"] = "entwicklung";
    BenchmarkAnalysisType["BENCHMARK"] = "benchmark";
})(BenchmarkAnalysisType || (exports.BenchmarkAnalysisType = BenchmarkAnalysisType = {}));
var BenchmarkEntwicklungType;
(function (BenchmarkEntwicklungType) {
    BenchmarkEntwicklungType["MM"] = "mm";
    BenchmarkEntwicklungType["MJ"] = "mj";
})(BenchmarkEntwicklungType || (exports.BenchmarkEntwicklungType = BenchmarkEntwicklungType = {}));
var BenchmarkBenchmarkType;
(function (BenchmarkBenchmarkType) {
    BenchmarkBenchmarkType["PRAXIS"] = "praxis";
    BenchmarkBenchmarkType["EXTERN"] = "extern";
})(BenchmarkBenchmarkType || (exports.BenchmarkBenchmarkType = BenchmarkBenchmarkType = {}));
var BenchmarkMonatsType;
(function (BenchmarkMonatsType) {
    BenchmarkMonatsType["YEARPARTLY"] = "y";
    BenchmarkMonatsType["YEAR"] = "Y";
    BenchmarkMonatsType["QUARTER"] = "q";
    BenchmarkMonatsType["MONTH"] = "m";
})(BenchmarkMonatsType || (exports.BenchmarkMonatsType = BenchmarkMonatsType = {}));
