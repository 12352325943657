"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALLPACKAGES = exports.PACKAGES = exports.compareFeatures = exports.ShortFeaturesMap = exports.FEATURE_TO_PRODUCT = exports.ROSEPRODUCTS = exports.FEATURE_TO_APP = exports.R4CAPP = exports.ALLFEATURES = exports.R4CFEATURES = exports.ANAMNESEFEATURES = exports.DOCUMENTSFEATURES = exports.FACTORINGFEATURES = exports.FEATURES = void 0;
var FEATURES;
(function (FEATURES) {
    FEATURES["NONE"] = "none";
    FEATURES["METRICS"] = "metrics";
    FEATURES["BETA"] = "beta";
    FEATURES["MULTICLIENT"] = "multiclient";
    FEATURES["PRAXISREPORT"] = "praxisreport";
    FEATURES["DEBUG"] = "debug";
    FEATURES["RECHNUNGEN_ERTRAGCOLUMS"] = "rechnungenertragcolums";
    FEATURES["DEPRECATED"] = "deprecated";
    // r4c
    FEATURES["FACTORING"] = "factoring";
    FEATURES["FACTORINGEWE"] = "factoringewe";
    FEATURES["DOCUMENTS_SMALL"] = "documents_small";
    FEATURES["DOCUMENTS_BIG"] = "documents_big";
    FEATURES["DOCUMENTS_FLAT"] = "documents_flat";
    FEATURES["ANAMNESE_WHITE"] = "anamnese_white";
    FEATURES["ANAMNESE_RED"] = "anamnese_red";
    FEATURES["ANAMNESE_BLACK"] = "anamnese_black";
    FEATURES["ANAMNESE_DIAMOND"] = "anamnese_diamond";
    FEATURES["ANAMNESE_EXTRA_QUESTIONNAIRE"] = "anamnese_extra_questionnaire";
    // SPECIALS
    FEATURES["EXTRAFACTORINGSCHNITTSTELLE"] = "extrafactoringschnittstelle";
    FEATURES["DATAWAREHOUSE25"] = "datawarehouse25";
    FEATURES["R2CSV"] = "r2csv";
    FEATURES["FACTORINGPREFETCHER"] = "factoringprefetcher";
    FEATURES["DOCTOSYNC"] = "doctosync";
    FEATURES["DSO"] = "dso";
})(FEATURES || (exports.FEATURES = FEATURES = {}));
exports.FACTORINGFEATURES = [FEATURES.FACTORING, FEATURES.FACTORINGEWE, FEATURES.FACTORINGPREFETCHER];
exports.DOCUMENTSFEATURES = [FEATURES.DOCUMENTS_SMALL, FEATURES.DOCUMENTS_BIG, FEATURES.DOCUMENTS_FLAT];
exports.ANAMNESEFEATURES = [
    FEATURES.ANAMNESE_WHITE,
    FEATURES.ANAMNESE_RED,
    FEATURES.ANAMNESE_BLACK,
    FEATURES.ANAMNESE_DIAMOND,
];
exports.R4CFEATURES = [FEATURES.FACTORING, FEATURES.FACTORINGEWE, ...exports.DOCUMENTSFEATURES, ...exports.ANAMNESEFEATURES];
exports.ALLFEATURES = [
    FEATURES.METRICS,
    FEATURES.BETA,
    FEATURES.PRAXISREPORT,
    FEATURES.DEBUG,
    FEATURES.RECHNUNGEN_ERTRAGCOLUMS,
    FEATURES.MULTICLIENT,
    FEATURES.FACTORINGPREFETCHER,
    FEATURES.DATAWAREHOUSE25,
    FEATURES.DOCTOSYNC,
    FEATURES.DSO,
    ...exports.R4CFEATURES,
];
const sortedFeatures = [...exports.R4CFEATURES, FEATURES.METRICS];
var R4CAPP;
(function (R4CAPP) {
    R4CAPP["FACTORING"] = "factoring";
    R4CAPP["DOCUMENTS"] = "documents";
    R4CAPP["ANAMNESE"] = "anamnese";
    R4CAPP["UNKNOWN"] = "unknown";
})(R4CAPP || (exports.R4CAPP = R4CAPP = {}));
exports.FEATURE_TO_APP = {
    [FEATURES.FACTORING]: R4CAPP.FACTORING,
    [FEATURES.FACTORINGEWE]: R4CAPP.FACTORING,
    [FEATURES.DOCUMENTS_SMALL]: R4CAPP.DOCUMENTS,
    [FEATURES.DOCUMENTS_BIG]: R4CAPP.DOCUMENTS,
    [FEATURES.DOCUMENTS_FLAT]: R4CAPP.DOCUMENTS,
    [FEATURES.ANAMNESE_WHITE]: R4CAPP.ANAMNESE,
    [FEATURES.ANAMNESE_RED]: R4CAPP.ANAMNESE,
    [FEATURES.ANAMNESE_BLACK]: R4CAPP.ANAMNESE,
    [FEATURES.ANAMNESE_DIAMOND]: R4CAPP.ANAMNESE,
    [FEATURES.METRICS]: FEATURES.METRICS,
    [FEATURES.DATAWAREHOUSE25]: FEATURES.METRICS,
    [FEATURES.FACTORINGPREFETCHER]: FEATURES.METRICS,
    [FEATURES.DOCTOSYNC]: FEATURES.METRICS,
    [FEATURES.NONE]: R4CAPP.UNKNOWN,
};
var ROSEPRODUCTS;
(function (ROSEPRODUCTS) {
    ROSEPRODUCTS["R4C"] = "r4c";
    ROSEPRODUCTS["METRICS"] = "metrics";
    ROSEPRODUCTS["UNKNOWN"] = "unknown";
})(ROSEPRODUCTS || (exports.ROSEPRODUCTS = ROSEPRODUCTS = {}));
exports.FEATURE_TO_PRODUCT = {
    [FEATURES.FACTORING]: ROSEPRODUCTS.R4C,
    [FEATURES.FACTORINGEWE]: ROSEPRODUCTS.R4C,
    [FEATURES.DOCUMENTS_SMALL]: ROSEPRODUCTS.R4C,
    [FEATURES.DOCUMENTS_BIG]: ROSEPRODUCTS.R4C,
    [FEATURES.DOCUMENTS_FLAT]: ROSEPRODUCTS.R4C,
    [FEATURES.ANAMNESE_WHITE]: ROSEPRODUCTS.R4C,
    [FEATURES.ANAMNESE_RED]: ROSEPRODUCTS.R4C,
    [FEATURES.ANAMNESE_BLACK]: ROSEPRODUCTS.R4C,
    [FEATURES.ANAMNESE_DIAMOND]: ROSEPRODUCTS.R4C,
    [FEATURES.METRICS]: ROSEPRODUCTS.METRICS,
    [FEATURES.DATAWAREHOUSE25]: ROSEPRODUCTS.METRICS,
    [FEATURES.FACTORINGPREFETCHER]: ROSEPRODUCTS.METRICS,
    [FEATURES.DOCTOSYNC]: ROSEPRODUCTS.METRICS,
    [FEATURES.NONE]: ROSEPRODUCTS.UNKNOWN,
};
exports.ShortFeaturesMap = {
    [FEATURES.METRICS]: { key: FEATURES.METRICS, long: 'metrics', short: 'm', color: '#319ee1', textColor: 'white' },
    [FEATURES.FACTORING]: {
        key: FEATURES.FACTORING,
        long: 'factoring basic',
        short: 'f',
        color: '#ffdc00',
        textColor: 'black',
    },
    [FEATURES.FACTORINGEWE]: {
        key: FEATURES.FACTORINGEWE,
        long: 'factoring ewe',
        short: 'F',
        color: '#DE9F64',
        textColor: 'black',
    },
    [FEATURES.FACTORINGPREFETCHER]: {
        key: FEATURES.FACTORINGPREFETCHER,
        long: 'factoring prefetcher',
        short: 'fp',
        color: '#DE9F64',
        textColor: 'black',
    },
    [FEATURES.DOCUMENTS_SMALL]: {
        key: FEATURES.DOCUMENTS_SMALL,
        long: 'documents small',
        short: 'd',
        color: '#e48385',
        textColor: 'white',
    },
    [FEATURES.DOCUMENTS_BIG]: {
        key: FEATURES.DOCUMENTS_BIG,
        long: 'documents big',
        short: 'D',
        color: '#e48385',
        textColor: 'white',
    },
    [FEATURES.DOCUMENTS_FLAT]: {
        key: FEATURES.DOCUMENTS_FLAT,
        long: 'docuemtns flat',
        short: 'D*',
        color: '#e48385',
        textColor: 'white',
    },
    [FEATURES.ANAMNESE_WHITE]: {
        key: FEATURES.ANAMNESE_WHITE,
        long: 'ana white',
        short: 'a',
        color: '#A864DE',
        textColor: 'white',
    },
    [FEATURES.ANAMNESE_RED]: {
        key: FEATURES.ANAMNESE_RED,
        long: 'ana red',
        short: 'a*',
        color: '#A864DE',
        textColor: 'white',
    },
    [FEATURES.ANAMNESE_BLACK]: {
        key: FEATURES.ANAMNESE_BLACK,
        long: 'ana black',
        short: 'A',
        color: '#A864DE',
        textColor: 'white',
    },
    [FEATURES.ANAMNESE_DIAMOND]: {
        key: FEATURES.ANAMNESE_DIAMOND,
        long: 'ana diamond',
        short: 'A*',
        color: '#A864DE',
        textColor: 'white',
    },
    [FEATURES.DOCTOSYNC]: {
        key: FEATURES.DOCTOSYNC,
        long: 'doctosync',
        short: 'L',
        color: '#428bca',
        textColor: 'black',
    },
    [FEATURES.DATAWAREHOUSE25]: {
        key: FEATURES.DATAWAREHOUSE25,
        long: 'data-ware-house',
        short: 'dwh',
        color: '#111',
        textColor: 'white',
    },
    [FEATURES.DSO]: { key: FEATURES.DSO, long: 'dental service org', short: 'dso', color: '#aaa', textColor: 'white' },
    [FEATURES.BETA]: { key: FEATURES.BETA, long: 'beta', short: 'beta', color: '#f24738', textColor: 'white' },
};
/**
 * @returns a positive number if feature b is an upgrade of feature a
 */
function compareFeatures(a, b) {
    if (!b) {
        return -1;
    }
    if (!a) {
        return 1;
    }
    return sortedFeatures.indexOf(b) - sortedFeatures.indexOf(a);
}
exports.compareFeatures = compareFeatures;
var PACKAGES;
(function (PACKAGES) {
    PACKAGES["NONE"] = "none";
    // old style
    PACKAGES["DEMO"] = "demo";
    PACKAGES["BETA"] = "BETA";
    PACKAGES["BLACK"] = "Black";
    PACKAGES["RED"] = "Red";
    PACKAGES["WHITE"] = "White";
    PACKAGES["DIAMOND"] = "Diamond";
    // new STYLE
    PACKAGES["CHARLYANALYTICS"] = "CHARLYANALYTICS";
    PACKAGES["ROSEMETRICS"] = "ROSEMETRICS";
})(PACKAGES || (exports.PACKAGES = PACKAGES = {}));
exports.ALLPACKAGES = [
    PACKAGES.DEMO,
    PACKAGES.BETA,
    PACKAGES.BLACK,
    PACKAGES.RED,
    PACKAGES.WHITE,
    PACKAGES.DIAMOND,
    PACKAGES.CHARLYANALYTICS,
    PACKAGES.ROSEMETRICS,
];
