"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FreshdeskLevel = exports.FreshdeskEingang = exports.FreshdeskTicketPriority = exports.FreshdeskTicketStatus = exports.JobTitles = void 0;
// change also in admin/ContactPositionCell.vue
var JobTitles;
(function (JobTitles) {
    JobTitles["IT"] = "IT";
    JobTitles["Praxismanager"] = "Praxismanager";
    JobTitles["Arzt"] = "Arzt";
    JobTitles["Inhaber"] = "Inhaber";
    JobTitles["Sammelkontakt"] = "Sammelkontakt";
    JobTitles["Verwaltung"] = "Verwaltung";
    JobTitles["Empfang"] = "Empfang";
})(JobTitles || (exports.JobTitles = JobTitles = {}));
var FreshdeskTicketStatus;
(function (FreshdeskTicketStatus) {
    FreshdeskTicketStatus[FreshdeskTicketStatus["Open"] = 2] = "Open";
    FreshdeskTicketStatus[FreshdeskTicketStatus["Pending"] = 3] = "Pending";
    FreshdeskTicketStatus[FreshdeskTicketStatus["Resolved"] = 4] = "Resolved";
    FreshdeskTicketStatus[FreshdeskTicketStatus["Closed"] = 5] = "Closed";
    FreshdeskTicketStatus[FreshdeskTicketStatus["WaitingOnCustomer"] = 6] = "WaitingOnCustomer";
    FreshdeskTicketStatus[FreshdeskTicketStatus["WaitingOnThirdParty"] = 7] = "WaitingOnThirdParty";
    FreshdeskTicketStatus[FreshdeskTicketStatus["CallBack"] = 8] = "CallBack";
    FreshdeskTicketStatus[FreshdeskTicketStatus["InternalClarification"] = 9] = "InternalClarification";
})(FreshdeskTicketStatus || (exports.FreshdeskTicketStatus = FreshdeskTicketStatus = {}));
var FreshdeskTicketPriority;
(function (FreshdeskTicketPriority) {
    FreshdeskTicketPriority[FreshdeskTicketPriority["Low"] = 1] = "Low";
    FreshdeskTicketPriority[FreshdeskTicketPriority["Medium"] = 2] = "Medium";
    FreshdeskTicketPriority[FreshdeskTicketPriority["High"] = 3] = "High";
    FreshdeskTicketPriority[FreshdeskTicketPriority["Urgent"] = 4] = "Urgent";
})(FreshdeskTicketPriority || (exports.FreshdeskTicketPriority = FreshdeskTicketPriority = {}));
var FreshdeskEingang;
(function (FreshdeskEingang) {
    FreshdeskEingang["Callcenter"] = "Callcenter";
    FreshdeskEingang["TelefonRose"] = "Telefon rose";
    FreshdeskEingang["Email"] = "Email";
    FreshdeskEingang["FeedbackWidget"] = "Feedback Widget";
    FreshdeskEingang["API"] = "API";
    FreshdeskEingang["Schulung"] = "Schulung";
    FreshdeskEingang["Demo"] = "Demo";
    FreshdeskEingang["Uebergabe"] = "\u00DCbergabe";
})(FreshdeskEingang || (exports.FreshdeskEingang = FreshdeskEingang = {}));
var FreshdeskLevel;
(function (FreshdeskLevel) {
    FreshdeskLevel["FirstLevel"] = "First Level";
    FreshdeskLevel["SecondLevel"] = "Second Level";
})(FreshdeskLevel || (exports.FreshdeskLevel = FreshdeskLevel = {}));
