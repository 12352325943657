"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoctolibAppointmentStatus = void 0;
var DoctolibAppointmentStatus;
(function (DoctolibAppointmentStatus) {
    DoctolibAppointmentStatus["CONFIRMED"] = "confirmed";
    DoctolibAppointmentStatus["WAITING"] = "waiting";
    DoctolibAppointmentStatus["IN_PROGRESS"] = "in_progress";
    DoctolibAppointmentStatus["DONE"] = "done";
    DoctolibAppointmentStatus["DELETED"] = "deleted";
    DoctolibAppointmentStatus["NO_SHOW_BUT_OK"] = "no_show_but_ok";
    DoctolibAppointmentStatus["NO_SHOW"] = "no_show";
    DoctolibAppointmentStatus["SUSPENDED"] = "suspended";
})(DoctolibAppointmentStatus || (exports.DoctolibAppointmentStatus = DoctolibAppointmentStatus = {}));
