"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RechnungSearchDateFilter = exports.RechnungSearchRZFilter = exports.RechnungSearchStatusFilter = void 0;
var RechnungSearchStatusFilter;
(function (RechnungSearchStatusFilter) {
    RechnungSearchStatusFilter["Alle"] = "Alle";
    RechnungSearchStatusFilter["Bezahlt"] = "Bezahlt";
    RechnungSearchStatusFilter["Offen"] = "Offen";
    RechnungSearchStatusFilter["Mahnung"] = "Mahnung";
    RechnungSearchStatusFilter["Mahnung1"] = "Mahnung1";
    RechnungSearchStatusFilter["Mahnung2"] = "Mahnung2";
    RechnungSearchStatusFilter["Mahnung3"] = "Mahnung3";
    RechnungSearchStatusFilter["Mahnung4"] = "Mahnung4";
})(RechnungSearchStatusFilter || (exports.RechnungSearchStatusFilter = RechnungSearchStatusFilter = {}));
var RechnungSearchRZFilter;
(function (RechnungSearchRZFilter) {
    RechnungSearchRZFilter["Alle"] = "Alle";
    RechnungSearchRZFilter["RZ"] = "RZ";
    RechnungSearchRZFilter["KeinRZ"] = "KeinRZ";
})(RechnungSearchRZFilter || (exports.RechnungSearchRZFilter = RechnungSearchRZFilter = {}));
var RechnungSearchDateFilter;
(function (RechnungSearchDateFilter) {
    RechnungSearchDateFilter["Alle"] = "Alle";
    RechnungSearchDateFilter["Erstellt"] = "Erstellt";
    RechnungSearchDateFilter["Faellig"] = "Faellig";
    RechnungSearchDateFilter["Bezahlt"] = "Bezahlt";
})(RechnungSearchDateFilter || (exports.RechnungSearchDateFilter = RechnungSearchDateFilter = {}));
