"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.heuteAlarmeKeys = exports.HeuteRechenzentrumUIInfoResultType = exports.isRZStatusAlarm = exports.IHeuteRechenzentrumInfoType = exports.HeuteStatusType = void 0;
var HeuteStatusType;
(function (HeuteStatusType) {
    HeuteStatusType[HeuteStatusType["NONE"] = 0] = "NONE";
    HeuteStatusType[HeuteStatusType["V"] = 1] = "V";
    HeuteStatusType[HeuteStatusType["W"] = 2] = "W";
    HeuteStatusType[HeuteStatusType["Z"] = 3] = "Z";
    HeuteStatusType[HeuteStatusType["B"] = 4] = "B";
    HeuteStatusType[HeuteStatusType["OK"] = 5] = "OK";
    HeuteStatusType[HeuteStatusType["OK_KONTROLLIERT_BEHANDLER"] = 6] = "OK_KONTROLLIERT_BEHANDLER";
    HeuteStatusType[HeuteStatusType["OK_KONTROLLIERT_ABRECHNUNG"] = 7] = "OK_KONTROLLIERT_ABRECHNUNG";
    HeuteStatusType[HeuteStatusType["OK_KONTROLLIERT_ALLE"] = 8] = "OK_KONTROLLIERT_ALLE";
    HeuteStatusType[HeuteStatusType["NICHTERSCHIENEN"] = 9] = "NICHTERSCHIENEN";
    HeuteStatusType[HeuteStatusType["KURZFRISTIG_ABGESAGT"] = 10] = "KURZFRISTIG_ABGESAGT";
    HeuteStatusType[HeuteStatusType["FRISTGERECHT_ABGESAGT"] = 11] = "FRISTGERECHT_ABGESAGT";
})(HeuteStatusType || (exports.HeuteStatusType = HeuteStatusType = {}));
var IHeuteRechenzentrumInfoType;
(function (IHeuteRechenzentrumInfoType) {
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["MINDERJAEHRIG"] = 0] = "MINDERJAEHRIG";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["EWE_FEHLT"] = 1] = "EWE_FEHLT";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["EWE_ABGELAUFEN"] = 2] = "EWE_ABGELAUFEN";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["EWE_UNGUELTIG"] = 3] = "EWE_UNGUELTIG";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["EWE_MITGEGEBEN"] = 4] = "EWE_MITGEGEBEN";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["EWE_ABGELEHNT"] = 5] = "EWE_ABGELEHNT";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["EWE_KEIN_RZ"] = 6] = "EWE_KEIN_RZ";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["AVA_FEHLT"] = 7] = "AVA_FEHLT";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["AVA_UNGUELTIG"] = 8] = "AVA_UNGUELTIG";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["AVA_ABGELEHNT"] = 9] = "AVA_ABGELEHNT";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["AVA_ABGELAUFEN"] = 10] = "AVA_ABGELAUFEN";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["AVA_VERBRAUCHT"] = 11] = "AVA_VERBRAUCHT";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["AVA_OFFEN"] = 12] = "AVA_OFFEN";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["AVA_GUELTIG"] = 13] = "AVA_GUELTIG";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["AVA_GUELTIG_KEIN_AUSFALLSCHUTZ"] = 14] = "AVA_GUELTIG_KEIN_AUSFALLSCHUTZ";
    IHeuteRechenzentrumInfoType[IHeuteRechenzentrumInfoType["AVA_GUELTIG_50_PROZENT_AUSFALLSCHUTZ"] = 15] = "AVA_GUELTIG_50_PROZENT_AUSFALLSCHUTZ";
})(IHeuteRechenzentrumInfoType || (exports.IHeuteRechenzentrumInfoType = IHeuteRechenzentrumInfoType = {}));
function isRZStatusAlarm(status, settings) {
    var _a;
    switch (status) {
        case IHeuteRechenzentrumInfoType.EWE_FEHLT:
        case IHeuteRechenzentrumInfoType.EWE_MITGEGEBEN:
        case IHeuteRechenzentrumInfoType.EWE_ABGELAUFEN:
        case IHeuteRechenzentrumInfoType.AVA_ABGELAUFEN:
        case IHeuteRechenzentrumInfoType.AVA_VERBRAUCHT:
        case IHeuteRechenzentrumInfoType.EWE_UNGUELTIG:
            return true;
        case IHeuteRechenzentrumInfoType.AVA_FEHLT:
            return ((_a = settings.alarme.rechenzentrum) === null || _a === void 0 ? void 0 : _a.conditions) === 'AVA_FEHLT_ALARM';
        case IHeuteRechenzentrumInfoType.AVA_UNGUELTIG:
        case IHeuteRechenzentrumInfoType.MINDERJAEHRIG:
        case IHeuteRechenzentrumInfoType.EWE_ABGELEHNT:
        case IHeuteRechenzentrumInfoType.EWE_KEIN_RZ:
        case IHeuteRechenzentrumInfoType.AVA_ABGELEHNT:
        case IHeuteRechenzentrumInfoType.AVA_GUELTIG:
            return false;
    }
    return false;
}
exports.isRZStatusAlarm = isRZStatusAlarm;
var HeuteRechenzentrumUIInfoResultType;
(function (HeuteRechenzentrumUIInfoResultType) {
    HeuteRechenzentrumUIInfoResultType[HeuteRechenzentrumUIInfoResultType["NOHIGHLIGHT"] = 0] = "NOHIGHLIGHT";
    HeuteRechenzentrumUIInfoResultType[HeuteRechenzentrumUIInfoResultType["OK"] = 1] = "OK";
    HeuteRechenzentrumUIInfoResultType[HeuteRechenzentrumUIInfoResultType["WARN"] = 2] = "WARN";
    HeuteRechenzentrumUIInfoResultType[HeuteRechenzentrumUIInfoResultType["MOREWARN"] = 3] = "MOREWARN";
    HeuteRechenzentrumUIInfoResultType[HeuteRechenzentrumUIInfoResultType["NOK"] = 4] = "NOK";
})(HeuteRechenzentrumUIInfoResultType || (exports.HeuteRechenzentrumUIInfoResultType = HeuteRechenzentrumUIInfoResultType = {}));
exports.heuteAlarmeKeys = [
    'alter',
    'assistenz',
    'behandlerLeistungen',
    'email',
    'folgeTermine',
    'geschlecht',
    'heuteStatus',
    'hkp',
    'karte',
    'kontrolliert',
    'leistungen',
    'letzteAnamnese',
    'letzteNullEins',
    'letztePA',
    'letztePSI',
    'letztePZR',
    'naechstePZR',
    'nichtAbgerechneteLeistungen',
    'offeneRechnungen',
    'offeneTermine',
    'paRoentgen',
    'psiBefund',
    'naechsterpzrrecall',
    'rechenzentrum',
    'roentgen',
    'telefon',
    'versicherung',
    'vorschlaege',
];
