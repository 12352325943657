"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientenUmsaetzeMwstFilter = exports.PatientenUmsaetzeBezahltFilter = exports.PatientenUmsaetzeGesamtoffenFilter = exports.PrivatFilterTypeUI = void 0;
var PrivatFilterTypeUI;
(function (PrivatFilterTypeUI) {
    PrivatFilterTypeUI["Alle"] = "Alle";
    PrivatFilterTypeUI["Kasse"] = "Kasse";
    PrivatFilterTypeUI["Privat"] = "Privat";
})(PrivatFilterTypeUI || (exports.PrivatFilterTypeUI = PrivatFilterTypeUI = {}));
var PatientenUmsaetzeGesamtoffenFilter;
(function (PatientenUmsaetzeGesamtoffenFilter) {
    PatientenUmsaetzeGesamtoffenFilter["Alle"] = "Alle";
    PatientenUmsaetzeGesamtoffenFilter["Offen"] = "Offen";
    PatientenUmsaetzeGesamtoffenFilter["Abgerechnet"] = "Abgerechnet";
})(PatientenUmsaetzeGesamtoffenFilter || (exports.PatientenUmsaetzeGesamtoffenFilter = PatientenUmsaetzeGesamtoffenFilter = {}));
var PatientenUmsaetzeBezahltFilter;
(function (PatientenUmsaetzeBezahltFilter) {
    PatientenUmsaetzeBezahltFilter["Alle"] = "Alle";
    PatientenUmsaetzeBezahltFilter["NichtBezahlt"] = "NichtBezahlt";
    PatientenUmsaetzeBezahltFilter["Bezahlt"] = "Bezahlt";
    PatientenUmsaetzeBezahltFilter["Nachlass"] = "Nachlass";
})(PatientenUmsaetzeBezahltFilter || (exports.PatientenUmsaetzeBezahltFilter = PatientenUmsaetzeBezahltFilter = {}));
var PatientenUmsaetzeMwstFilter;
(function (PatientenUmsaetzeMwstFilter) {
    PatientenUmsaetzeMwstFilter["Alle"] = "Alle";
    PatientenUmsaetzeMwstFilter["MitMwSt"] = "MitMwSt";
    PatientenUmsaetzeMwstFilter["OhneMwSt"] = "OhneMwSt";
})(PatientenUmsaetzeMwstFilter || (exports.PatientenUmsaetzeMwstFilter = PatientenUmsaetzeMwstFilter = {}));
