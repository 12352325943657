"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mfaTypeToString = exports.IMfaType = exports.UserPrivacyLevel = exports.ADMIN_LEVEL = void 0;
var ADMIN_LEVEL;
(function (ADMIN_LEVEL) {
    ADMIN_LEVEL[ADMIN_LEVEL["NONE"] = 0] = "NONE";
    ADMIN_LEVEL[ADMIN_LEVEL["PRAXISMANAGER"] = 80] = "PRAXISMANAGER";
    ADMIN_LEVEL[ADMIN_LEVEL["CLIENT_ADMIN"] = 100] = "CLIENT_ADMIN";
    ADMIN_LEVEL[ADMIN_LEVEL["SOLUTIO_TEAM"] = 10000] = "SOLUTIO_TEAM";
    ADMIN_LEVEL[ADMIN_LEVEL["ROSE_TEAM"] = 100000] = "ROSE_TEAM";
})(ADMIN_LEVEL || (exports.ADMIN_LEVEL = ADMIN_LEVEL = {}));
var UserPrivacyLevel;
(function (UserPrivacyLevel) {
    UserPrivacyLevel["NONE"] = "NONE";
    UserPrivacyLevel["FAKENAMES"] = "FAKENAMES";
    UserPrivacyLevel["BLURNAMES"] = "BLURNAMES";
    UserPrivacyLevel["BLURNAMESBUTNOTBEHANDLER"] = "BLURNAMESBUTNOTBEHANDLER";
})(UserPrivacyLevel || (exports.UserPrivacyLevel = UserPrivacyLevel = {}));
var IMfaType;
(function (IMfaType) {
    IMfaType["MAIL"] = "MAIL";
    IMfaType["AUTHENTICATOR"] = "AUTHENTICATOR";
})(IMfaType || (exports.IMfaType = IMfaType = {}));
function mfaTypeToString(type) {
    switch (type) {
        case IMfaType.MAIL:
            return 'E-Mail';
        case IMfaType.AUTHENTICATOR:
            return 'Authenticator';
    }
}
exports.mfaTypeToString = mfaTypeToString;
