"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CPTPlanStatus = exports.PAREreignisGruppeZeitTyp = exports.PAREreignisGruppenCPT = exports.PAREreignisGruppenUPT = exports.PAREreignisGruppenNichtUPT = exports.gruppenNamen = exports.UPTIntervalMap = void 0;
const enums_1 = require("./enums");
exports.UPTIntervalMap = [
    enums_1.PAREreignisGruppeTyp.UPT_I,
    enums_1.PAREreignisGruppeTyp.UPT_II,
    enums_1.PAREreignisGruppeTyp.UPT_III,
    enums_1.PAREreignisGruppeTyp.UPT_IV,
    enums_1.PAREreignisGruppeTyp.UPT_V,
    enums_1.PAREreignisGruppeTyp.UPT_VI,
    enums_1.PAREreignisGruppeTyp.UPT_VII,
    enums_1.PAREreignisGruppeTyp.UPT_VIII,
    enums_1.PAREreignisGruppeTyp.UPT_IX,
    enums_1.PAREreignisGruppeTyp.UPT_X,
];
exports.gruppenNamen = {
    [enums_1.PAREreignisGruppeTyp.VORBEHANDLUNG]: 'Vorbehandlung',
    [enums_1.PAREreignisGruppeTyp.HAUPTBEHANDLUNG]: 'Hauptbehandlung',
    [enums_1.PAREreignisGruppeTyp.NACHBEHANDLUNG_BEVA]: 'Nachbehandlung / BEVa',
    [enums_1.PAREreignisGruppeTyp.REEVALUATION_AIT]: 'Reevaluation',
    [enums_1.PAREreignisGruppeTyp.CPT]: 'Chirurgische-Therapie',
    [enums_1.PAREreignisGruppeTyp.REEVALUATION_CPT]: 'CPT-Nachbehandlung / BEVb',
};
exports.PAREreignisGruppenNichtUPT = [
    enums_1.PAREreignisGruppeTyp.VORBEHANDLUNG,
    enums_1.PAREreignisGruppeTyp.HAUPTBEHANDLUNG,
    enums_1.PAREreignisGruppeTyp.REEVALUATION_AIT,
    enums_1.PAREreignisGruppeTyp.NACHBEHANDLUNG_BEVA,
    enums_1.PAREreignisGruppeTyp.CPT,
    enums_1.PAREreignisGruppeTyp.REEVALUATION_CPT,
];
exports.PAREreignisGruppenUPT = [
    enums_1.PAREreignisGruppeTyp.UPT_I,
    enums_1.PAREreignisGruppeTyp.UPT_II,
    enums_1.PAREreignisGruppeTyp.UPT_III,
    enums_1.PAREreignisGruppeTyp.UPT_IV,
    enums_1.PAREreignisGruppeTyp.UPT_V,
    enums_1.PAREreignisGruppeTyp.UPT_VI,
    enums_1.PAREreignisGruppeTyp.UPT_VII,
    enums_1.PAREreignisGruppeTyp.UPT_VIII,
    enums_1.PAREreignisGruppeTyp.UPT_IX,
    enums_1.PAREreignisGruppeTyp.UPT_X,
];
exports.PAREreignisGruppenCPT = [enums_1.PAREreignisGruppeTyp.CPT, enums_1.PAREreignisGruppeTyp.REEVALUATION_CPT];
var PAREreignisGruppeZeitTyp;
(function (PAREreignisGruppeZeitTyp) {
    PAREreignisGruppeZeitTyp["VERGANGEN"] = "VERGANGEN";
    PAREreignisGruppeZeitTyp["AKTUELL"] = "AKTUELL";
    PAREreignisGruppeZeitTyp["ZUKUNFT"] = "ZUKUNFT";
})(PAREreignisGruppeZeitTyp || (exports.PAREreignisGruppeZeitTyp = PAREreignisGruppeZeitTyp = {}));
var CPTPlanStatus;
(function (CPTPlanStatus) {
    CPTPlanStatus["KEINE_CPT_GEPLANT"] = "KEINE_CPT_GEPLANT";
    CPTPlanStatus["NUR_ANTRAG"] = "NUR_ANTRAG";
    CPTPlanStatus["KAPUTT_WEGEN_UPT_LEISTUNGEN"] = "KAPUTT_WEGEN_UPT_LEISTUNGEN";
    CPTPlanStatus["CPT_GEPLANT"] = "CPT_GEPLANT";
    CPTPlanStatus["CPT_DURCHGEFUEHRT"] = "CPT_DURCHGEFUEHRT";
})(CPTPlanStatus || (exports.CPTPlanStatus = CPTPlanStatus = {}));
