"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.patientSymbolsMap = void 0;
exports.patientSymbolsMap = {
    a: { kuerzel: '🍏', name: 'Apfel', farbe: '' },
    b: { kuerzel: '🌳', name: 'Baum', farbe: '' },
    c: { kuerzel: '🍊', name: 'Orange', farbe: '' },
    d: { kuerzel: '☀️', name: 'Sonne', farbe: '' },
    e: { kuerzel: '🥕', name: 'Karotte', farbe: '' },
    f: { kuerzel: '🍌', name: 'Banane', farbe: '' },
    g: { kuerzel: '🍐', name: 'Birne', farbe: '' },
    h: { kuerzel: '🧅', name: 'Zwiebel', farbe: '' },
    i: { kuerzel: '🍋', name: 'Zitrone', farbe: '' },
};
