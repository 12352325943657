"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.examplePraxisInfos = exports.exampleCharlyPatient = exports.exampleCharlyPatientDetails = exports.createExampleQuestionnaireInterpolationMap = exports.createQuestionnaireInterpolationMap = exports.invoiceListSumForItems = exports.invoiceListSummaryCollectInvoiceItems = exports.QUESTIONNAIRE_INTERPOLATION_STRINGS = exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_PRAXIS = exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_OTHER = exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_TIME = exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_VERSICHERTER = exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_PATIENT = exports.getTranslatedStringRaw = exports.questionnaireElementIsFullWidth = exports.fullSpanQuestionnaireElementTypes = exports.charlyGenderValueSet = exports.formatAnamneseResponseSource = exports.AnamneseResponseSource = exports.AnamneseResponseMetaDataKeys = exports.questionTypeIcon = exports.questionTypeDisplayString = exports.getPrefillElementsFromQuestionnaire = exports.isPracticeQuestion = exports.questionairePrefillQuestionTypes = exports.CustomAnamneseQuestionTypes = exports.filterQuestionnaireElementsForQuestions = exports.filterQuestionnaireElementsForType = exports.QuestionnaireElementType = void 0;
const factoring_1 = require("./factoring");
var QuestionnaireElementType;
(function (QuestionnaireElementType) {
    QuestionnaireElementType["PATIENT_INFO_FORM"] = "PATIENT_INFO_FORM";
    QuestionnaireElementType["QUESTION"] = "QUESTION";
    QuestionnaireElementType["RICH_TEXT"] = "RICH_TEXT";
    QuestionnaireElementType["DOCUMENT_PREVIEW"] = "DOCUMENT_PREVIEW";
    QuestionnaireElementType["DOCUMENT_SIGNATURE"] = "DOCUMENT_SIGNATURE";
})(QuestionnaireElementType || (exports.QuestionnaireElementType = QuestionnaireElementType = {}));
function filterQuestionnaireElementsForType(elements, ...types) {
    return elements.filter(e => types.includes(e.type));
}
exports.filterQuestionnaireElementsForType = filterQuestionnaireElementsForType;
function filterQuestionnaireElementsForQuestions(elements) {
    return filterQuestionnaireElementsForType(elements, QuestionnaireElementType.QUESTION);
}
exports.filterQuestionnaireElementsForQuestions = filterQuestionnaireElementsForQuestions;
var CustomAnamneseQuestionTypes;
(function (CustomAnamneseQuestionTypes) {
    CustomAnamneseQuestionTypes["TEXT"] = "TEXT";
    CustomAnamneseQuestionTypes["NUMBER"] = "NUMBER";
    CustomAnamneseQuestionTypes["SELECT"] = "SELECT";
    CustomAnamneseQuestionTypes["BOOLEAN"] = "BOOLEAN";
    CustomAnamneseQuestionTypes["DATE"] = "DATE";
    CustomAnamneseQuestionTypes["RANGE"] = "RANGE";
    CustomAnamneseQuestionTypes["RATING"] = "RATING";
    CustomAnamneseQuestionTypes["INVOICE_LIST"] = "INVOICE_LIST";
    CustomAnamneseQuestionTypes["INVOICE_LIST_SUMMARY"] = "INVOICE_LIST_SUMMARY";
    CustomAnamneseQuestionTypes["EXISTING_SIGNATURE"] = "EXISTING_SIGNATURE";
})(CustomAnamneseQuestionTypes || (exports.CustomAnamneseQuestionTypes = CustomAnamneseQuestionTypes = {}));
exports.questionairePrefillQuestionTypes = [
    CustomAnamneseQuestionTypes.INVOICE_LIST,
    CustomAnamneseQuestionTypes.EXISTING_SIGNATURE,
];
const isPracticeQuestion = (question) => !!question.isPracticeQuestion;
exports.isPracticeQuestion = isPracticeQuestion;
const getPrefillElementsFromQuestionnaire = (questionnaire) => [...questionnaire.questionnairePages, questionnaire.signaturePage]
    .map((page, pageIndex) => ({
    pageIndex,
    prefillElements: page.pageElements
        .map((element, elementIndex) => ({
        elementIndex,
        element,
    }))
        .filter(({ element }) => element.type === QuestionnaireElementType.QUESTION && (0, exports.isPracticeQuestion)(element.question)),
}))
    .filter(page => page.prefillElements.length > 0);
exports.getPrefillElementsFromQuestionnaire = getPrefillElementsFromQuestionnaire;
exports.questionTypeDisplayString = {
    [CustomAnamneseQuestionTypes.BOOLEAN]: 'Ja/Nein',
    [CustomAnamneseQuestionTypes.SELECT]: 'Auswahl',
    [CustomAnamneseQuestionTypes.TEXT]: 'Text',
    [CustomAnamneseQuestionTypes.NUMBER]: 'Zahl',
    [CustomAnamneseQuestionTypes.DATE]: 'Datum',
    [CustomAnamneseQuestionTypes.RATING]: 'Bewertung',
    [CustomAnamneseQuestionTypes.RANGE]: 'Schieber',
    [CustomAnamneseQuestionTypes.INVOICE_LIST]: 'Leistungen',
    [CustomAnamneseQuestionTypes.INVOICE_LIST_SUMMARY]: 'Leistungen Zusammenfassung',
    [CustomAnamneseQuestionTypes.EXISTING_SIGNATURE]: 'Arzt Unterschrift',
    // Ideen:
    // [CustomAnamneseQuestionTypes.TIME]: 'Zeit',
    // [CustomAnamneseQuestionTypes.DATETIME]: 'Datum und Zeit',
};
exports.questionTypeIcon = {
    [CustomAnamneseQuestionTypes.BOOLEAN]: 'far fa-toggle-on',
    [CustomAnamneseQuestionTypes.RATING]: 'far fa-star',
    [CustomAnamneseQuestionTypes.SELECT]: 'far fa-square-check',
    [CustomAnamneseQuestionTypes.NUMBER]: 'far fa-input-numeric',
    [CustomAnamneseQuestionTypes.TEXT]: 'far fa-input-text',
    [CustomAnamneseQuestionTypes.RANGE]: 'far fa-slider',
    [CustomAnamneseQuestionTypes.DATE]: 'far fa-calendar-days',
    [CustomAnamneseQuestionTypes.INVOICE_LIST]: 'far fa-list-ol',
    [CustomAnamneseQuestionTypes.INVOICE_LIST_SUMMARY]: 'far fa-list-ol',
    [CustomAnamneseQuestionTypes.EXISTING_SIGNATURE]: 'far fa-signature',
};
// RESPONSE
exports.AnamneseResponseMetaDataKeys = [
    'id',
    'cid',
    'patid',
    'languageKey',
    'questionnaireId',
    'source',
    'taskId',
    'charlyError',
    'responseTimestamp',
];
var AnamneseResponseSource;
(function (AnamneseResponseSource) {
    AnamneseResponseSource["TABLET"] = "TABLET";
    AnamneseResponseSource["PATIENT_MAIL_SHARED"] = "PATIENT_MAIL_SHARED";
    AnamneseResponseSource["PATIENT_PUBLIC"] = "PATIENT_PUBLIC";
    AnamneseResponseSource["PATIENT_TAKEOVER"] = "PATIENT_TAKEOVER";
    AnamneseResponseSource["PATIENT_TAKEOVER_DEMO"] = "PATIENT_TAKEOVER_DEMO";
    AnamneseResponseSource["MANUAL"] = "MANUAL";
})(AnamneseResponseSource || (exports.AnamneseResponseSource = AnamneseResponseSource = {}));
function formatAnamneseResponseSource(source) {
    switch (source) {
        case AnamneseResponseSource.TABLET:
            return 'Praxis Tablet';
        case AnamneseResponseSource.PATIENT_MAIL_SHARED:
            return 'Patient per Mailfreigabe';
        case AnamneseResponseSource.PATIENT_TAKEOVER:
            return 'Patient per QR-Code';
        case AnamneseResponseSource.PATIENT_PUBLIC:
            return 'Patient per Link';
        default:
            return source;
    }
}
exports.formatAnamneseResponseSource = formatAnamneseResponseSource;
exports.charlyGenderValueSet = [
    { key: 'MALE', value: 'männlich' },
    { key: 'FEMALE', value: 'weiblich' },
    { key: 'DIVERS', value: 'divers' },
    { key: 'UNKNOWN', value: 'unbekannt' },
];
exports.fullSpanQuestionnaireElementTypes = [
    QuestionnaireElementType.PATIENT_INFO_FORM,
    QuestionnaireElementType.RICH_TEXT,
    QuestionnaireElementType.DOCUMENT_PREVIEW,
    QuestionnaireElementType.DOCUMENT_SIGNATURE,
];
function questionnaireElementIsFullWidth(pageElement) {
    return (exports.fullSpanQuestionnaireElementTypes.includes(pageElement.type) ||
        (pageElement.type === QuestionnaireElementType.QUESTION && pageElement.question.fullWidth));
}
exports.questionnaireElementIsFullWidth = questionnaireElementIsFullWidth;
const getTranslatedStringRaw = (obj, key, languageKey) => {
    var _a, _b;
    let translationObjectKey = key + '_t';
    return (_b = (_a = obj[translationObjectKey]) === null || _a === void 0 ? void 0 : _a[languageKey]) !== null && _b !== void 0 ? _b : obj[key];
};
exports.getTranslatedStringRaw = getTranslatedStringRaw;
exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_PATIENT = [
    { key: 'pat_full_name', label: 'Pat. Name Gesamt' },
    { key: 'pat_vorname', label: 'Pat. Vorname' },
    { key: 'pat_nachname', label: 'Pat. Nachname' },
    { key: 'pat_title', label: 'Pat. Titel' },
    { key: 'pat_vorsatzwort', label: 'Pat. Vorsatzwort' },
    { key: 'pat_anrede', label: 'Pat. Anrede' },
    { key: 'pat_street', label: 'Pat. Straße' },
    { key: 'pat_zip', label: 'Pat. PLZ' },
    { key: 'pat_city', label: 'Pat. Stadt' },
    { key: 'pat_dob', label: 'Pat. Geb. Datum' },
    { key: 'pat_gender', label: 'Pat. Geschlecht' },
    { key: 'pat_gender_r', label: 'Pat. nachgestelltes r bei männlich' },
];
exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_VERSICHERTER = [
    { key: 'vers_full_name', label: 'Versich. Name Gesamt' },
    { key: 'vers_vorname', label: 'Versich. Vorname' },
    { key: 'vers_nachname', label: 'Versich. Nachname' },
    { key: 'vers_title', label: 'Versich. Titel' },
    { key: 'vers_vorsatzwort', label: 'Versich. Vorsatzwort' },
    { key: 'vers_anrede', label: 'Versich. Anrede' },
    { key: 'vers_street', label: 'Versich. Straße' },
    { key: 'vers_zip', label: 'Versich. PLZ' },
    { key: 'vers_city', label: 'Versich. Stadt' },
    { key: 'vers_dob', label: 'Versich. Geb. Datum' },
    { key: 'vers_gender', label: 'Versich. Geschlecht' },
    { key: 'vers_gender_r', label: 'Versich. nachgestelltes r bei männlich' },
];
exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_TIME = [
    { key: 'datum', label: 'Datum' },
    { key: 'datum_2', label: 'Datum (21. Mai 2020)' },
    { key: 'datum_3', label: 'Datum (Donnerstag, 21. Mai 2020)' },
    { key: 'datum_4', label: 'Datum (Do, 21.05.2020)' },
    { key: 'time', label: 'Uhrzeit' },
];
exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_OTHER = [
    { key: 'charly_user', label: 'angemeldeter Benutzer' },
];
exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_PRAXIS = [
    { key: 'praxis_name', label: 'Praxis Name' },
    { key: 'praxis_stempel', label: 'Praxis Stempel' },
    { key: 'praxis_adresse', label: 'Praxis Adresse' },
    { key: 'praxis_tel', label: 'Praxis Telefon' },
    { key: 'praxis_mail', label: 'Praxis Mail' },
];
exports.QUESTIONNAIRE_INTERPOLATION_STRINGS = [
    ...exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_PATIENT,
    ...exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_VERSICHERTER,
    ...exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_TIME,
    ...exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_OTHER,
    ...exports.QUESTIONNAIRE_INTERPOLATION_STRINGS_PRAXIS,
    // { key: 'not-found-test', label: 'Unbekannt Test' },
];
// needs to run in client and server therefore no moment oder dayjs
function formatDate(dateStr) {
    const d = dateStr ? new Date(dateStr) : new Date();
    const year = d.getFullYear();
    const date = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    return `${date}.${month}.${year}`;
}
function invoiceListSummaryCollectInvoiceItems({ questionnaireQuestions, showTotalItems, summaryElement, getTranslatedString, getAnswerForQuestion, }) {
    let invoiceListQuestions = [];
    for (let question of questionnaireQuestions) {
        if (question === summaryElement) {
            break;
        }
        if (question.valueType === CustomAnamneseQuestionTypes.INVOICE_LIST) {
            invoiceListQuestions.push(question);
        }
        if (question.valueType === CustomAnamneseQuestionTypes.INVOICE_LIST_SUMMARY && !showTotalItems) {
            invoiceListQuestions = [];
        }
    }
    let invoiceLists = invoiceListQuestions.map(invoiceListQuestion => {
        let items = getAnswerForQuestion(invoiceListQuestion.questionID);
        return {
            name: getTranslatedString(invoiceListQuestion, 'questionLong'),
            sum: invoiceListSumForItems(items),
            items,
        };
    });
    let totalSum = invoiceListSumForItems(invoiceLists.map(l => l.items).flat());
    return {
        invoiceLists,
        totalSum,
    };
}
exports.invoiceListSummaryCollectInvoiceItems = invoiceListSummaryCollectInvoiceItems;
function invoiceListSumForItems(items) {
    let sum = items.reduce((subSum, item) => subSum + Number(item.quantity) * Number(item.price), 0);
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(sum);
}
exports.invoiceListSumForItems = invoiceListSumForItems;
function createQuestionnaireInterpolationMap({ patient, charlyUser, praxisInfos, }) {
    let emptyPatient = {
        vorname: '',
        name: '',
        titel: '',
        namenszusatz: '',
        strasse: '',
        plz: '',
        ort: '',
        geburtsdatum: '',
        geschlecht: '',
    };
    let usePatient = patient || emptyPatient;
    let versicherter = (patient === null || patient === void 0 ? void 0 : patient.rechnungsempfaenger) || usePatient;
    let map = {
        // Patient
        pat_vorname: usePatient.vorname,
        pat_nachname: usePatient.name,
        pat_title: usePatient.titel || '',
        pat_vorsatzwort: usePatient.namenszusatz || '',
        pat_anrede: usePatient.geschlecht === 'M' ? 'Herr' : usePatient.geschlecht === 'W' ? 'Frau' : '',
        pat_full_name: [usePatient.titel, usePatient.namenszusatz, usePatient.name + ',', usePatient.vorname]
            .filter(d => !!d)
            .join(' '),
        pat_street: usePatient.strasse,
        pat_zip: usePatient.plz,
        pat_city: usePatient.ort,
        pat_dob: formatDate(usePatient.geburtsdatum),
        pat_gender: factoring_1.FactoringGeschlechtToText[usePatient.geschlecht] || '-',
        pat_gender_r: usePatient.geschlecht === 'M' ? 'r' : '',
        // Versicherter
        vers_vorname: versicherter.vorname,
        vers_nachname: versicherter.name,
        vers_title: versicherter.titel || '',
        vers_vorsatzwort: versicherter.namenszusatz || '',
        vers_anrede: versicherter.geschlecht === 'M' ? 'Herr' : versicherter.geschlecht === 'W' ? 'Frau' : '',
        vers_full_name: [versicherter.titel, versicherter.namenszusatz, versicherter.name + ',', versicherter.vorname]
            .filter(d => !!d)
            .join(' '),
        vers_street: versicherter.strasse,
        vers_zip: versicherter.plz,
        vers_city: versicherter.ort,
        vers_dob: formatDate(versicherter.geburtsdatum),
        vers_gender: factoring_1.FactoringGeschlechtToText[versicherter.geschlecht] || '-',
        vers_gender_r: versicherter.geschlecht === 'M' ? 'r' : '',
        // other
        charly_user: charlyUser || '-',
        // Dates
        datum: formatDate(),
        datum_2: new Date().toLocaleDateString('de-DE', { day: '2-digit', month: 'long', year: 'numeric' }),
        datum_3: new Date().toLocaleDateString('de-DE', {
            weekday: 'long',
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        }),
        datum_4: new Date().toLocaleDateString('de-DE', {
            weekday: 'short',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }),
        time: new Date().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }),
        // praxis
        praxis_name: praxisInfos.praxis,
        praxis_stempel: `${praxisInfos.stempelname1} ${praxisInfos.stempelname2}`,
        praxis_adresse: `${praxisInfos.strasse} ${praxisInfos.plz} ${praxisInfos.ort}`,
        praxis_tel: praxisInfos.telefon,
        praxis_mail: praxisInfos.email,
    };
    return map;
}
exports.createQuestionnaireInterpolationMap = createQuestionnaireInterpolationMap;
const createExampleQuestionnaireInterpolationMap = () => createQuestionnaireInterpolationMap({
    patient: exports.exampleCharlyPatient,
    charlyUser: 'charly-user',
    praxisInfos: exports.examplePraxisInfos,
});
exports.createExampleQuestionnaireInterpolationMap = createExampleQuestionnaireInterpolationMap;
exports.exampleCharlyPatientDetails = {
    zip: '41189',
    city: 'Musterstadt',
    title: 'Arzt',
    gender: 'UNKNOWN',
    street: 'Musterstraße 1',
    country: 'Deutschland',
    peerage: 'Graf',
    birthday: '2016-01-25',
    lastName: 'Musterfrau',
    firstName: 'Max',
    patientId: 7001,
    birthplace: 'Bonn',
    contacts: [
        {
            id: 77131,
            type: 'EMAIL',
            state: 'PRIVATE',
            value: 'musterman@email.de',
        },
        {
            id: 77132,
            type: 'PHONE',
            state: 'PRIVATE',
            value: '0049-0000000',
        },
    ],
};
exports.exampleCharlyPatient = {
    extid: '7001',
    vorname: 'Max',
    name: 'Mustermann',
    namenszusatz: 'Graf',
    titel: 'Dr.',
    strasse: 'Musterstraße 999',
    plz: '41189',
    ort: 'Musterstadt',
    geburtsdatum: '2016-01-21',
    email: 'musterman@mail.de',
    geschlecht: 'U',
    patientseit: '2050-01-01',
    beruf: 'Müllfrau XXX',
    arbeitgeber: 'Müllwerke Stadtwerke',
    versichertbei: 'AOK Rheinland/Hamburg',
    inland: true,
    land: 'D',
    rechnungsempfaenger: {
        extid: '91158',
        vorname: 'Erika',
        name: 'Mustermann',
        strasse: 'Musterstraße 3',
        plz: '41189',
        ort: 'Musterstadt',
        geburtsdatum: '2000-01-25',
        email: '',
        geschlecht: 'M',
        land: 'D',
    },
    hkps: [],
    erbrachteLeistungen: 0,
    rgan: 1,
    rganid: '91158',
    rginland: true,
    ref_re_simple: '7001$46a85f4a915fec87c7478de0e22025df',
    fieldhashes: {
        patient: {
            name: '7001$8c08e6a4d60aba3ae3ad42b85d798f85',
            vorname: '7001$6ca5baf72585b8aca2b4b0dc144551af',
            geburtsdatum: '7001$a6229d045e54e74dcb44b38448ffd8b7',
            strasse: '7001$89a67b0dd63657a8e809a1602abb2b98',
            plz: '7001$c2b574794f632f76ad0a916f01306288',
            ort: '7001$5f65ad92c670ca847828e007ccf6dc32',
        },
        re: {
            name: '7001$c78ffea6df9eaec0524413a16f962333',
            vorname: '7001$79593c7f944ba0bd5e7edce23f10a04f',
            geburtsdatum: '7001$25917b86beacc0eb40301e74ab0b50e4',
            strasse: '7001$068353397893b4bcd3c71b5b16a78e5e',
            plz: '7001$c2b574794f632f76ad0a916f01306288',
            ort: '7001$5f65ad92c670ca847828e007ccf6dc32',
        },
    },
    stammbehandler: '1',
    zuletztAbgerechnetGoz: new Date(),
    zuletztNichtAbgerechnetGoz: new Date(),
};
exports.examplePraxisInfos = {
    aktivepatienten: 19055,
    anzahlpatienten: 34685,
    appmode: 'DEMO',
    behandler: 43,
    email: 'info@demo-praxis.de',
    bank1: 'Apotheker- und Ärztebank',
    bic1: '',
    fax: '',
    iban1: '',
    kzvidnr: '',
    kzvname: '',
    kzvnummer: '',
    license: '',
    pvsversion: '',
    maxid: '',
    ort: 'Musterort',
    plz: '41189',
    praxis: 'Demo Praxis',
    praxis1: 'Demo Praxis 1',
    praxis2: 'Demo Praxis 2',
    stempelname1: 'Demo Praxis Stempel 1',
    stempelname2: 'Demo Praxis Stempel 2',
    stempelort: 'Musterort',
    stempelplz: '41189',
    stempelstrasse: 'Musterstraße 123',
    strasse: 'Musterstraße 123',
    telefon: '0012 3456789',
    terminbehandler: 68,
    rz: [
        {
            id: '6',
            nummer: '3',
            name: 'Health AG',
            kundennr: '2448',
            umsatz: 0,
        },
    ],
    termineprojahr: [{ jahr: 2020, anzahl: 12 }],
    umsatzprojahr: [{ jahr: 2020, anzahl: 26 }],
    stempel: [
        {
            id: '1',
            bank: 'Apotheker- und Ärztebank',
            bic: '',
            iban: '',
            kzvnummer: '',
            fax: '',
            stempelname1: 'Demo Praxis Stempel 1',
            stempelname2: 'Demo Praxis Stempel 2',
            ort: 'Musterort',
            plz: '41189',
            strasse: 'Musterstraße 123',
            behandler: ['1'],
        },
    ],
};
