/* unplugin-vue-components disabled */import __unplugin_components_2 from '~icons/far/plus';
import __unplugin_components_1 from '/builds/rose-metrics/rose-metrics/vui/src/components/Loader.vue';
import __unplugin_components_0 from '/builds/rose-metrics/rose-metrics/vui/src/components/BetaHint.vue';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap view-top-padding mainview-padding"},[(!_vm.isDev)?_c(__unplugin_components_0,{staticClass:"beta-hint mt-4 mb-2"}):_vm._e(),(_vm.loading)?_c('div',{staticClass:"loader"},[_c(__unplugin_components_1)],1):(_vm.error)?_c('div',{staticClass:"error-info"},[_c('div',{staticClass:"error",on:{"click":function($event){return _vm.reload()}}},[_c('div',{staticClass:"error-label"},[_vm._v("Es ist ein Fehler aufgetreten:")]),_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.error))]),_c(VIcon,{staticClass:"reload-icon",attrs:{"dense":"","color":"white"}},[_vm._v("fa-rotate")])],1)]):[_c(VTabs,{staticClass:"dashboardSelect",attrs:{"color":"primary"},model:{value:(_vm.activeDashboardIndex),callback:function ($$v) {_vm.activeDashboardIndex=$$v},expression:"activeDashboardIndex"}},[_vm._l((_vm.dashboards),function(dashboard){return _c(VTab,[_c('span',[_vm._v(_vm._s(dashboard.name))])])}),_c(VBtn,{staticClass:"ml-4 align-self-center",attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.showNewDashboardDialog = true}}},[_c(__unplugin_components_2,{staticClass:"mr-2"}),_c('span',[_vm._v("Neues Dashboard anlegen")])],1)],2),(_vm.activeDashboard)?[_c('EditDashboard',{attrs:{"dashboard":_vm.activeDashboard},on:{"layout-updated":_vm.layoutUpdatedEvent,"edit-details":function($event){_vm.editDetailsDashboard = _vm.activeDashboard}}})]:_vm._e(),(_vm.editDetailsDashboard)?_c('EditDashboardDetails',{attrs:{"dashboard":_vm.editDetailsDashboard},on:{"save":_vm.saveDashboardDetails,"delete":_vm.deleteDashboard,"close":function($event){_vm.editDetailsDashboard = null}}}):_vm._e(),(_vm.showNewDashboardDialog)?_c('EditDashboardDetails',{attrs:{"createNewMode":true},on:{"save":_vm.saveNewDashboardDetails,"close":function($event){_vm.showNewDashboardDialog = false}}}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }