"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationsSearchVersicherungFilter = exports.LocationsSearchDateFilter = void 0;
var LocationsSearchDateFilter;
(function (LocationsSearchDateFilter) {
    LocationsSearchDateFilter["Alle"] = "Alle";
    LocationsSearchDateFilter["LetzterTermin"] = "LetzterTermin";
    LocationsSearchDateFilter["Neupatienten"] = "Neupatienten";
})(LocationsSearchDateFilter || (exports.LocationsSearchDateFilter = LocationsSearchDateFilter = {}));
var LocationsSearchVersicherungFilter;
(function (LocationsSearchVersicherungFilter) {
    LocationsSearchVersicherungFilter["Alle"] = "Alle";
    LocationsSearchVersicherungFilter["Privat"] = "Privat";
    LocationsSearchVersicherungFilter["Kasse"] = "Kasse";
})(LocationsSearchVersicherungFilter || (exports.LocationsSearchVersicherungFilter = LocationsSearchVersicherungFilter = {}));
