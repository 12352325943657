"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AblageType = void 0;
var AblageType;
(function (AblageType) {
    AblageType["Ablage_material_praxis"] = "Ablage_material_praxis";
    AblageType["AU"] = "AU";
    AblageType["Bestellungen"] = "Bestellungen";
    /**
     * "Eingang" is a special case, it is a shared folder for all patients
     */
    AblageType["Eingang"] = "Eingang";
    AblageType["Fremdlabor"] = "Fremdlabor";
    AblageType["Fremdrechnungen"] = "Fremdrechnungen";
    AblageType["Krankenkassen"] = "Krankenkassen";
    AblageType["KV"] = "KV";
    AblageType["KVBriefe"] = "KVBriefe";
    AblageType["PatEingang"] = "PatEingang";
    AblageType["Patient"] = "Patient";
    AblageType["Pdf"] = "Pdf";
    AblageType["PraxisAdressen"] = "PraxisAdressen";
    AblageType["PrivatAdressen"] = "PrivatAdressen";
    AblageType["Rechenzentren"] = "Rechenzentren";
    AblageType["Rechnungen"] = "Rechnungen";
    AblageType["TeamviewerEinverstaendis"] = "TeamviewerEinverstaendis";
})(AblageType || (exports.AblageType = AblageType = {}));
