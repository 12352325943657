"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ANFRAGEQUELLE_TYPE = exports.RECHNUNGSEMPFAENGER_TYPE = exports.FACTORING_ANFRAGE_STATUS = exports.FactoringGeschlechtToText = exports.R4CRegisterState = exports.STATS_AVA_RESULT = exports.STATS_EWE_RESULT = exports.EWE_ABLAUF_TYPE = exports.CREDENTIALS_TYPE = exports.ANFRAGE_TRIGGER_TYPE = exports.EWE_ERGEBNIS_TYPE = exports.rzkeys = exports.EWE_FORMULARE_SPEZIAL = void 0;
// ========================== magic variables
var EWE_FORMULARE_SPEZIAL;
(function (EWE_FORMULARE_SPEZIAL) {
    EWE_FORMULARE_SPEZIAL["MANUELLES_EWE_FORMULAR"] = "MANUELLES_EWE_FORMULAR";
    EWE_FORMULARE_SPEZIAL["KEINRZANBIETEN_EWE_FORMULAR"] = "KEINRZANBIETEN_EWE_FORMULAR";
    EWE_FORMULARE_SPEZIAL["ABLEHNUNGS_EWE_FORMULAR"] = "ABLEHNUNGS_EWE_FORMULAR";
    EWE_FORMULARE_SPEZIAL["MITGEGEBEN_EWE_FORMULAR"] = "MITGEGEBEN_EWE_FORMULAR";
    EWE_FORMULARE_SPEZIAL["EWE_WIDERRUF"] = "EWE_WIDERRUF";
})(EWE_FORMULARE_SPEZIAL || (exports.EWE_FORMULARE_SPEZIAL = EWE_FORMULARE_SPEZIAL = {}));
// ========================== enums
var rzkeys;
(function (rzkeys) {
    rzkeys["test"] = "test";
    rzkeys["demo"] = "demo";
    rzkeys["bfs"] = "bfs";
    rzkeys["za"] = "za";
    rzkeys["dzr"] = "dzr";
    rzkeys["abz"] = "abz";
    rzkeys["health"] = "health";
    rzkeys["pvsdental"] = "pvsdental";
    rzkeys["pvsreiss"] = "pvsreiss";
    rzkeys["arc"] = "arc";
    rzkeys["teamfaktor"] = "teamfaktor";
    rzkeys["carecapital"] = "carecapital";
    rzkeys["mediserv"] = "mediserv";
    rzkeys["zab"] = "zab";
    rzkeys["nelly"] = "nelly";
    rzkeys["fabius"] = "fabius";
})(rzkeys || (exports.rzkeys = rzkeys = {}));
var EWE_ERGEBNIS_TYPE;
(function (EWE_ERGEBNIS_TYPE) {
    EWE_ERGEBNIS_TYPE[EWE_ERGEBNIS_TYPE["ANGENOMMEN"] = 0] = "ANGENOMMEN";
    EWE_ERGEBNIS_TYPE[EWE_ERGEBNIS_TYPE["ABGELEHNT_NICHT_MEHR_NACHFRAGEN"] = 1] = "ABGELEHNT_NICHT_MEHR_NACHFRAGEN";
    EWE_ERGEBNIS_TYPE[EWE_ERGEBNIS_TYPE["KEIN_RZ_ANBIETEN"] = 2] = "KEIN_RZ_ANBIETEN";
    EWE_ERGEBNIS_TYPE[EWE_ERGEBNIS_TYPE["EWE_MITGEGEBEN"] = 3] = "EWE_MITGEGEBEN";
    EWE_ERGEBNIS_TYPE[EWE_ERGEBNIS_TYPE["WIDERRUFEN"] = 4] = "WIDERRUFEN";
})(EWE_ERGEBNIS_TYPE || (exports.EWE_ERGEBNIS_TYPE = EWE_ERGEBNIS_TYPE = {}));
var ANFRAGE_TRIGGER_TYPE;
(function (ANFRAGE_TRIGGER_TYPE) {
    ANFRAGE_TRIGGER_TYPE[ANFRAGE_TRIGGER_TYPE["UNKNOWN"] = -1] = "UNKNOWN";
    ANFRAGE_TRIGGER_TYPE[ANFRAGE_TRIGGER_TYPE["AUTOMATISCH"] = 0] = "AUTOMATISCH";
    ANFRAGE_TRIGGER_TYPE[ANFRAGE_TRIGGER_TYPE["MANUELL"] = 1] = "MANUELL";
    ANFRAGE_TRIGGER_TYPE[ANFRAGE_TRIGGER_TYPE["CACHE_PLACEHOLDER"] = 2] = "CACHE_PLACEHOLDER";
})(ANFRAGE_TRIGGER_TYPE || (exports.ANFRAGE_TRIGGER_TYPE = ANFRAGE_TRIGGER_TYPE = {}));
var CREDENTIALS_TYPE;
(function (CREDENTIALS_TYPE) {
    CREDENTIALS_TYPE[CREDENTIALS_TYPE["ROSETOKEN"] = 0] = "ROSETOKEN";
    CREDENTIALS_TYPE[CREDENTIALS_TYPE["CLIENT_WITH_CERT"] = 1] = "CLIENT_WITH_CERT";
})(CREDENTIALS_TYPE || (exports.CREDENTIALS_TYPE = CREDENTIALS_TYPE = {}));
var EWE_ABLAUF_TYPE;
(function (EWE_ABLAUF_TYPE) {
    EWE_ABLAUF_TYPE[EWE_ABLAUF_TYPE["WIRD_16"] = 0] = "WIRD_16";
    EWE_ABLAUF_TYPE[EWE_ABLAUF_TYPE["WIRD_18"] = 1] = "WIRD_18";
    // WIDERRUFEN = 2,
    EWE_ABLAUF_TYPE[EWE_ABLAUF_TYPE["RZ_WECHSEL"] = 3] = "RZ_WECHSEL";
    EWE_ABLAUF_TYPE[EWE_ABLAUF_TYPE["FIRMIERUNGSWECHSEL"] = 4] = "FIRMIERUNGSWECHSEL";
    EWE_ABLAUF_TYPE[EWE_ABLAUF_TYPE["LOESCHUNG"] = 5] = "LOESCHUNG";
    EWE_ABLAUF_TYPE[EWE_ABLAUF_TYPE["GUELTIGKEITSERWEITERT"] = 6] = "GUELTIGKEITSERWEITERT";
    EWE_ABLAUF_TYPE[EWE_ABLAUF_TYPE["MANUELL_INVALIDIERT"] = 7] = "MANUELL_INVALIDIERT";
})(EWE_ABLAUF_TYPE || (exports.EWE_ABLAUF_TYPE = EWE_ABLAUF_TYPE = {}));
var STATS_EWE_RESULT;
(function (STATS_EWE_RESULT) {
    STATS_EWE_RESULT["KEINE"] = "KEINE";
    STATS_EWE_RESULT["GUELTIG"] = "GUELTIGE";
    STATS_EWE_RESULT["UNGUELTIG"] = "UNGUELTIGE";
})(STATS_EWE_RESULT || (exports.STATS_EWE_RESULT = STATS_EWE_RESULT = {}));
var STATS_AVA_RESULT;
(function (STATS_AVA_RESULT) {
    STATS_AVA_RESULT["KEINE"] = "KEINE";
    STATS_AVA_RESULT["GUELTIG"] = "GUELTIGE";
    STATS_AVA_RESULT["ABGELAUFEN"] = "ABGELAUFENE";
    STATS_AVA_RESULT["AUFGEBRAUCHT"] = "AUFGEBRAUCHT";
    STATS_AVA_RESULT["ABGELEHNT"] = "ABGELEHNT";
})(STATS_AVA_RESULT || (exports.STATS_AVA_RESULT = STATS_AVA_RESULT = {}));
var R4CRegisterState;
(function (R4CRegisterState) {
    R4CRegisterState["TOKENINVALID"] = "TOKENINVALID";
    R4CRegisterState["NOTREGISTERED"] = "NOTREGISTERED";
    R4CRegisterState["ONBOARDING"] = "ONBOARDING";
    R4CRegisterState["REGISTERED"] = "REGISTERED";
})(R4CRegisterState || (exports.R4CRegisterState = R4CRegisterState = {}));
exports.FactoringGeschlechtToText = {
    M: 'Männlich',
    W: 'Weiblich',
    U: 'Unbekannt',
};
var FACTORING_ANFRAGE_STATUS;
(function (FACTORING_ANFRAGE_STATUS) {
    FACTORING_ANFRAGE_STATUS["OFFEN"] = "offen";
    FACTORING_ANFRAGE_STATUS["GUELTIG_MIT_AUSFALLSCHUTZ"] = "gueltig_as";
    FACTORING_ANFRAGE_STATUS["GUELTIG_50_PROZENT_AUSFALLSCHUTZ"] = "gueltig_50_as";
    FACTORING_ANFRAGE_STATUS["GUELTIG_KEIN_AUSFALLSCHUTZ"] = "gueltig_kein_as";
    FACTORING_ANFRAGE_STATUS["RUECKSPRACHE"] = "ruecksprache";
    FACTORING_ANFRAGE_STATUS["VERBRAUCHT"] = "verbraucht";
    FACTORING_ANFRAGE_STATUS["ERSETZT"] = "ersetzt";
    FACTORING_ANFRAGE_STATUS["UNGUELTIG"] = "ungueltig";
    FACTORING_ANFRAGE_STATUS["ABGELAUFEN"] = "abgelaufen";
    FACTORING_ANFRAGE_STATUS["UNBEKANNT"] = "-";
})(FACTORING_ANFRAGE_STATUS || (exports.FACTORING_ANFRAGE_STATUS = FACTORING_ANFRAGE_STATUS = {}));
var RECHNUNGSEMPFAENGER_TYPE;
(function (RECHNUNGSEMPFAENGER_TYPE) {
    RECHNUNGSEMPFAENGER_TYPE[RECHNUNGSEMPFAENGER_TYPE["VERSICHERTER"] = 1] = "VERSICHERTER";
    RECHNUNGSEMPFAENGER_TYPE[RECHNUNGSEMPFAENGER_TYPE["PATIENT"] = 2] = "PATIENT";
    RECHNUNGSEMPFAENGER_TYPE[RECHNUNGSEMPFAENGER_TYPE["RECHNUNGSEMPFAENGER"] = 3] = "RECHNUNGSEMPFAENGER";
    RECHNUNGSEMPFAENGER_TYPE[RECHNUNGSEMPFAENGER_TYPE["UNFALLVERSICHERUNG"] = 4] = "UNFALLVERSICHERUNG";
})(RECHNUNGSEMPFAENGER_TYPE || (exports.RECHNUNGSEMPFAENGER_TYPE = RECHNUNGSEMPFAENGER_TYPE = {}));
var ANFRAGEQUELLE_TYPE;
(function (ANFRAGEQUELLE_TYPE) {
    ANFRAGEQUELLE_TYPE["NONE"] = "NONE";
    ANFRAGEQUELLE_TYPE["CACHE"] = "CACHE";
    ANFRAGEQUELLE_TYPE["RZ"] = "RZ";
    ANFRAGEQUELLE_TYPE["HISTORY"] = "HISTORY";
})(ANFRAGEQUELLE_TYPE || (exports.ANFRAGEQUELLE_TYPE = ANFRAGEQUELLE_TYPE = {}));
