"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateRecallType = exports.statusIsKontrolliertAlle = exports.statusIsKontrolliertAbrechnung = exports.statusIsKontrolliertBehandler = exports.statusIsNotKontrolliert = exports.statusIsFristgerechtAbgesagtPraxis = exports.statusIsFristgerechtAbgesagt = exports.statusIsKurzfristigAbgesagt = exports.statusIsNichtErschienen = exports.statusIsOk = exports.statusIsVirtuell = exports.statusIsOffen = void 0;
const enums_1 = require("./enums");
// some utils
function statusIsOffen(status) {
    return status === enums_1.TerminStatusType.OFFEN;
}
exports.statusIsOffen = statusIsOffen;
function statusIsVirtuell(status) {
    return status === enums_1.TerminStatusType.VIRTUELL;
}
exports.statusIsVirtuell = statusIsVirtuell;
function statusIsOk(status) {
    return status > 0 && (status + '').endsWith('1');
}
exports.statusIsOk = statusIsOk;
function statusIsNichtErschienen(status) {
    return (status + '').endsWith('2');
}
exports.statusIsNichtErschienen = statusIsNichtErschienen;
function statusIsKurzfristigAbgesagt(status) {
    return (status + '').endsWith('3');
}
exports.statusIsKurzfristigAbgesagt = statusIsKurzfristigAbgesagt;
function statusIsFristgerechtAbgesagt(status) {
    return (status + '').endsWith('4');
}
exports.statusIsFristgerechtAbgesagt = statusIsFristgerechtAbgesagt;
function statusIsFristgerechtAbgesagtPraxis(status) {
    return (status + '').endsWith('5');
}
exports.statusIsFristgerechtAbgesagtPraxis = statusIsFristgerechtAbgesagtPraxis;
function statusIsNotKontrolliert(status) {
    return status < enums_1.TerminStatusType.KONTROLLIERT_BEHANDLER_OK;
}
exports.statusIsNotKontrolliert = statusIsNotKontrolliert;
function statusIsKontrolliertBehandler(status) {
    return ((status >= enums_1.TerminStatusType.KONTROLLIERT_BEHANDLER_OK &&
        status <= enums_1.TerminStatusType.KONTROLLIERT_BEHANDLER_PRAXIS_HAT_ABGESAGT) ||
        (status >= enums_1.TerminStatusType.KONTROLLIERT_ALLE_OK &&
            status <= enums_1.TerminStatusType.KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT));
}
exports.statusIsKontrolliertBehandler = statusIsKontrolliertBehandler;
function statusIsKontrolliertAbrechnung(status) {
    return ((status >= enums_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_OK &&
        status <= enums_1.TerminStatusType.KONTROLLIERT_ABRECHNUNG_PRAXIS_HAT_ABGESAGT) ||
        (status >= enums_1.TerminStatusType.KONTROLLIERT_ALLE_OK &&
            status <= enums_1.TerminStatusType.KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT));
}
exports.statusIsKontrolliertAbrechnung = statusIsKontrolliertAbrechnung;
function statusIsKontrolliertAlle(status) {
    return (status >= enums_1.TerminStatusType.KONTROLLIERT_ALLE_OK && status <= enums_1.TerminStatusType.KONTROLLIERT_ALLE_PRAXIS_HAT_ABGESAGT);
}
exports.statusIsKontrolliertAlle = statusIsKontrolliertAlle;
function translateRecallType(rt) {
    let rs;
    switch (rt) {
        case enums_1.RecallType.KEIN_RECALL:
            rs = 'Kein Recall';
            break;
        case enums_1.RecallType.NAECHSTER_RECALLTERMIN:
            rs = 'Nächster Recalltermin';
            break;
        case enums_1.RecallType.RECALLTERMIN_ERINNERT:
            rs = 'Recalltermin erinnert';
            break;
        case enums_1.RecallType.RECALLTERMIN_VEREINBART:
            rs = 'Recalltermin vereinbart';
            break;
        case enums_1.RecallType.RECALLTERMIN_ERSCHIENEN:
            rs = 'Recalltermin erschienen';
            break;
    }
    return rs;
}
exports.translateRecallType = translateRecallType;
