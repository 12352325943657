"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.metricsPakete = exports.OnboardingProcessStatusType = exports.OnboardingStatusType = exports.MultiClientLoginType = void 0;
const features_1 = require("./features");
var MultiClientLoginType;
(function (MultiClientLoginType) {
    MultiClientLoginType["NONE"] = "NONE";
    MultiClientLoginType["PAID"] = "PAID";
    MultiClientLoginType["FREE"] = "FREE";
})(MultiClientLoginType || (exports.MultiClientLoginType = MultiClientLoginType = {}));
var OnboardingStatusType;
(function (OnboardingStatusType) {
    OnboardingStatusType["NEW"] = "NEW";
    OnboardingStatusType["PENDING"] = "PENDING";
    OnboardingStatusType["ACCEPTED"] = "ACCEPTED";
    OnboardingStatusType["ACTIVATED"] = "ACTIVATED";
    OnboardingStatusType["IGNORE"] = "IGNORE";
})(OnboardingStatusType || (exports.OnboardingStatusType = OnboardingStatusType = {}));
var OnboardingProcessStatusType;
(function (OnboardingProcessStatusType) {
    OnboardingProcessStatusType["UNBEARBEITET"] = "unbearbeitet";
    OnboardingProcessStatusType["VERSANDT"] = "versandt";
    OnboardingProcessStatusType["ANGEKOMMEN"] = "angekommen";
    OnboardingProcessStatusType["VERBINDUNG_OK"] = "verbindungok";
    OnboardingProcessStatusType["ERSTSCHULUNG"] = "erstschulung";
})(OnboardingProcessStatusType || (exports.OnboardingProcessStatusType = OnboardingProcessStatusType = {}));
exports.metricsPakete = [
    {
        text1: 'Der',
        text2: 'Einstieg.',
        name: 'White',
        id: features_1.PACKAGES.WHITE,
        preis: 69,
        zusatzLizenzPreis: 59,
        promonat: 'zzgl. MwSt. / Monat',
        class: 'rosePaketWhite',
        min: 1,
        max: 3,
    },
    {
        text1: 'Um mehr ',
        text2: 'zu entdecken.',
        name: 'Red',
        id: features_1.PACKAGES.RED,
        preis: 189,
        zusatzLizenzPreis: 59,
        promonat: 'zzgl. MwSt. / Monat',
        class: 'rosePaketRed',
        min: 3,
        max: 5,
    },
    {
        text1: 'Der 360°  ',
        text2: 'Rundumblick.',
        name: 'Black',
        id: features_1.PACKAGES.BLACK,
        preis: 299,
        zusatzLizenzPreis: 49,
        promonat: 'zzgl. MwSt. / Monat',
        class: 'rosePaketBlack',
        min: 5,
        max: 9,
    },
    {
        text1: 'Für die,',
        text2: 'die alles wollen!',
        name: 'Diamond',
        id: features_1.PACKAGES.DIAMOND,
        preis: 499,
        zusatzLizenzPreis: 0,
        promonat: 'zzgl. MwSt. / Monat',
        class: 'rosePaketDiamond',
        min: 9,
        max: 100,
    },
];
