"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatSharedTaskToForUser = void 0;
const enums_1 = require("./enums");
function formatSharedTaskToForUser(task) {
    switch (task.taskType) {
        case enums_1.TabletTaskType.ANAMNESE_TASK:
            return 'Anamnese';
        case enums_1.TabletTaskType.EWE_TASK:
            return 'EWE';
        case enums_1.TabletTaskType.DOCUMENT_TASK:
            return 'Dokument';
        case enums_1.TabletTaskType.QR_CODE_TASK:
            return 'QR Code';
        case enums_1.TabletTaskType.UPLOAD_TASK:
            return 'Upload';
        default:
            return 'Unbekannt';
    }
}
exports.formatSharedTaskToForUser = formatSharedTaskToForUser;
