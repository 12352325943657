"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertStepToKey = exports.getImportStepName = exports.ImportSteps = void 0;
const interfaces_1 = require("./interfaces");
/**
 * SYNC ORDER WITH IMPORT_STEPS ENUM AND DERIVEDDATACALC !!!!!!!!!!
 */
exports.ImportSteps = [
    { step: interfaces_1.IMPORT_STEPS.STARTED, key: convertStepToKey(interfaces_1.IMPORT_STEPS.STARTED), name: 'Gestartet' },
    {
        step: interfaces_1.IMPORT_STEPS.RAW_DATA_RETRIVAL,
        key: convertStepToKey(interfaces_1.IMPORT_STEPS.RAW_DATA_RETRIVAL),
        name: 'Rohdaten laden',
    },
    {
        step: interfaces_1.IMPORT_STEPS.DOCTOSYNC_DATA_RETRIVAL,
        key: convertStepToKey(interfaces_1.IMPORT_STEPS.DOCTOSYNC_DATA_RETRIVAL),
        name: 'Doctosync-Daten laden',
    },
    {
        step: interfaces_1.IMPORT_STEPS.RAW_DATA_INDEXING,
        key: convertStepToKey(interfaces_1.IMPORT_STEPS.RAW_DATA_INDEXING),
        name: 'Rohdaten indizieren',
    },
    {
        step: interfaces_1.IMPORT_STEPS.RAW_DATA_TRANSFORMATION,
        key: convertStepToKey(interfaces_1.IMPORT_STEPS.RAW_DATA_TRANSFORMATION),
        name: 'Rohdaten transformieren',
    },
    {
        step: interfaces_1.IMPORT_STEPS.DELETIONS,
        key: convertStepToKey(interfaces_1.IMPORT_STEPS.DELETIONS),
        name: 'Gelöschte Datensätze synchronisieren',
    },
    {
        step: interfaces_1.IMPORT_STEPS.LEISTUNGSBLOECKE,
        key: convertStepToKey(interfaces_1.IMPORT_STEPS.LEISTUNGSBLOECKE),
        name: 'Leistungsblöcke erstellen',
    },
    { step: interfaces_1.IMPORT_STEPS.KONTROLLIERT, key: convertStepToKey(interfaces_1.IMPORT_STEPS.KONTROLLIERT), name: 'Kontrolliert erstellen' },
    { step: interfaces_1.IMPORT_STEPS.PSI, key: convertStepToKey(interfaces_1.IMPORT_STEPS.PSI), name: 'PSI erstellen' },
    { step: interfaces_1.IMPORT_STEPS.TERMINE, key: convertStepToKey(interfaces_1.IMPORT_STEPS.TERMINE), name: 'Termine erstellen' },
    { step: interfaces_1.IMPORT_STEPS.HKP, key: convertStepToKey(interfaces_1.IMPORT_STEPS.HKP), name: 'HKPs erstellen' },
    { step: interfaces_1.IMPORT_STEPS.PATIENTEN, key: convertStepToKey(interfaces_1.IMPORT_STEPS.PATIENTEN), name: 'Patienten erstellen' },
    { step: interfaces_1.IMPORT_STEPS.RECHNUNGEN, key: convertStepToKey(interfaces_1.IMPORT_STEPS.RECHNUNGEN), name: 'Rechnungen erstellen' },
    { step: interfaces_1.IMPORT_STEPS.UMSATZ, key: convertStepToKey(interfaces_1.IMPORT_STEPS.UMSATZ), name: 'Umsatz erstellen' },
    {
        step: interfaces_1.IMPORT_STEPS.ABRECHNUNG,
        key: convertStepToKey(interfaces_1.IMPORT_STEPS.ABRECHNUNG),
        name: 'Abrechnungsvorschläge erstellen',
    },
    {
        step: interfaces_1.IMPORT_STEPS.KZVABRECHNUNG,
        key: convertStepToKey(interfaces_1.IMPORT_STEPS.KZVABRECHNUNG),
        name: 'KZV-Abrechnung erstellen',
    },
    { step: interfaces_1.IMPORT_STEPS.PA, key: convertStepToKey(interfaces_1.IMPORT_STEPS.PA), name: 'PAs erstellen' },
    { step: interfaces_1.IMPORT_STEPS.HEUTE, key: convertStepToKey(interfaces_1.IMPORT_STEPS.HEUTE), name: 'Heute erstellen' },
    { step: interfaces_1.IMPORT_STEPS.TERMINBUCH, key: convertStepToKey(interfaces_1.IMPORT_STEPS.TERMINBUCH), name: 'Terminbuch erstellen' },
    { step: interfaces_1.IMPORT_STEPS.BENCHMARK, key: convertStepToKey(interfaces_1.IMPORT_STEPS.BENCHMARK), name: 'Benchmark erstellen' },
    { step: interfaces_1.IMPORT_STEPS.FINISHED, key: convertStepToKey(interfaces_1.IMPORT_STEPS.FINISHED), name: 'Fertig' },
];
function getImportStepName(step) {
    let ss = exports.ImportSteps.find(s => s.step === step);
    return ss ? ss.name : step;
}
exports.getImportStepName = getImportStepName;
function convertStepToKey(step) {
    switch (step) {
        case interfaces_1.IMPORT_STEPS.NOT_RUNNING:
            return 'NOT_RUNNING';
        case interfaces_1.IMPORT_STEPS.STARTED:
            return 'STARTED';
        case interfaces_1.IMPORT_STEPS.RAW_DATA_RETRIVAL:
            return 'RAW_DATA_RETRIVAL';
        case interfaces_1.IMPORT_STEPS.DOCTOSYNC_DATA_RETRIVAL:
            return 'DOCTOSYNC_DATA_RETRIVAL';
        case interfaces_1.IMPORT_STEPS.RAW_DATA_INDEXING:
            return 'RAW_DATA_INDEXING';
        case interfaces_1.IMPORT_STEPS.RAW_DATA_TRANSFORMATION:
            return 'RAW_DATA_TRANSFORMATION';
        case interfaces_1.IMPORT_STEPS.DELETIONS:
            return 'DELETIONS';
        case interfaces_1.IMPORT_STEPS.LEISTUNGSBLOECKE:
            return 'LEISTUNGSBLOECKE';
        case interfaces_1.IMPORT_STEPS.KONTROLLIERT:
            return 'KONTROLLIERT';
        case interfaces_1.IMPORT_STEPS.PSI:
            return 'PSI';
        case interfaces_1.IMPORT_STEPS.TERMINE:
            return 'TERMINE';
        case interfaces_1.IMPORT_STEPS.HKP:
            return 'HKP';
        case interfaces_1.IMPORT_STEPS.PA:
            return 'PA';
        case interfaces_1.IMPORT_STEPS.PATIENTEN:
            return 'PATIENTEN';
        case interfaces_1.IMPORT_STEPS.RECHNUNGEN:
            return 'RECHNUNGEN';
        case interfaces_1.IMPORT_STEPS.HEUTE:
            return 'HEUTE';
        case interfaces_1.IMPORT_STEPS.UMSATZ:
            return 'UMSATZ';
        case interfaces_1.IMPORT_STEPS.ABRECHNUNG:
            return 'ABRECHNUNG';
        case interfaces_1.IMPORT_STEPS.KZVABRECHNUNG:
            return 'KZVABRECHNUNG';
        case interfaces_1.IMPORT_STEPS.TERMINBUCH:
            return 'TERMINBUCH';
        case interfaces_1.IMPORT_STEPS.BENCHMARK:
            return 'BENCHMARK';
        case interfaces_1.IMPORT_STEPS.FINISHED:
            return 'FINISHED';
    }
    return 'UNKNOWN';
}
exports.convertStepToKey = convertStepToKey;
