"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoseLoginError = void 0;
class RoseLoginError extends Error {
    constructor(message) {
        super(message);
        this.mfaTokenRequired = undefined;
        this.mfaTokenInvalid = undefined;
        this.mfaMailSent = undefined;
        this.wrongCredentials = undefined;
        this.unknownUser = undefined;
        this.externalAccessNotAllowed = undefined;
        this.name = 'RoseLoginError';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, RoseLoginError);
        }
    }
    setFlag(flag, value = true) {
        this[flag] = value;
        return this;
    }
    static isRoseLoginError(err) {
        return (err === null || err === void 0 ? void 0 : err.name) === 'RoseLoginError';
    }
}
exports.RoseLoginError = RoseLoginError;
